import React from 'react'
import { connect } from 'react-redux'
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import LayoutDefault from '../layouts/default'
import MaterialIcon from '@material/react-material-icon'
import {
  Bracket, DeckFormat,
  DeckRegister,
  PointsAttribution,
  Registration,
  TournamentState,
  Visibility,
} from '../../../constants/tournament'
import { WYSIWYG } from '../../wysiwyg'
import { LOGIN, TOURNAMENT_CREATE, tournamentEdit, tournament as tournamentPage } from '../../routes'
import { ProgressState } from '../../../constants/progress-state'
import { ContactType } from '../../../constants/client'
import { onChange } from '../../../utils/on-change'
import { Link } from 'react-router-dom'
import NewInput from '../../atoms/input'
import { FormContainer } from './../../../components/global-styles'
import ToggleButton from '../../atoms/toggle-button'
import { ImagesSelect } from './image-select'
import moment from 'moment-timezone'
import {getLocalDate} from '../../../utils/date'
import {getTournamentId} from "../../../utils/tournament";

const tz = moment.tz.guess()

const mapState = ({tournament, user}) => ({
  tournament,
  user,
})
const mapDispatch = ({tournament, user}) => ({
  load: tournament.load,
  edit: tournament.edit,
  resetEdit: tournament.resetEdit,
  remove: tournament.remove,
  publish: tournament.publish,
  loadOrganizations: user.loadOrganizations,
  reset: tournament.reset,
  search: user.search,
  resetSearch: user.resetSearch,
})

const DEFAULT_DATA = {
  id: 0,
  name: '',
  description: '',
  image: '',
  dateStart: moment().add(7, 'd').hour(12).minutes(0).seconds(0).toJSON(),
  startDate: moment().add(7, 'day').format('YYYY-MM-DD'),
  startTime: '20:00',
  contacts: [],
  bracketType: Bracket.Single,
  playersLimit: 0,
  playersReportScore: true,
  tournamentCheckIn: false,
  tournamentCheckInTime: 30,
  registration: Registration.Open,
  visibility: Visibility.Public,
  matchCheckIn: false,
  matchCheckInTime: 10,
  pickAndBans: false,
  timeLimitForBans: false,
  timeLimitForBansTime: 10,
  picksCount: 3,
  bansCount: 1,
  deckRegister: DeckRegister.LeaderAndDeck,
  deckFormat: DeckFormat.Closed,
  dateDecksDeadline: moment().add(6, 'd').hour(12).minutes(0).seconds(0).toJSON(),
  decksDeadlineDate: moment().add(6, 'day').format('YYYY-MM-DD'),
  decksDeadlineTime: '20:00',
  state: TournamentState.None,
  organizationId: 0,
  manualSeeding: false,
  manualStartRounds: false,
  replayDraws: false,
  coinFlipRandom: true,
  bracketParams: {
    matchFormat: 5,
    finalMatchFormat: 5,
    third: false,
    groupsCount: 1,
    pointsAttribution: PointsAttribution.PerMatch,
    pointsPerWin: 3,
    pointsPerLoss: 0,
    pointsPerDraw: 1,
    pointsPerBye: 3,
    roundCount: 4,
    losesCount: 0,
    finalMatchesCount: 1
  },
  admins: [],
  spectators: [],
  uuid: ""
};

class CTournamentEditPage extends React.PureComponent {
  state = {
    data: {...DEFAULT_DATA},
    error: {},
    adminName: '',
    spectatorName: '',
    updated: false,
  }

  _isInit = false
  _updatedTimer = null

  get isCreate() {
    return this.props.match.path === TOURNAMENT_CREATE
  }

  isDisabled = (disabledState = [], enabledState = []) => {
    const {tournament: {data = {}}} = this.props
    return disabledState.includes(data.state) || enabledState.length > 0 && !enabledState.includes(data.state)
  }

  init() {
    if (this._isInit) return
    const {user, history, match, tournament, load, resetEdit, loadOrganizations, reset} = this.props
    const {data} = this.state
    if (user.state !== ProgressState.Complete) return
    if (!user.identified()) {
      history.push(LOGIN)
      return
    }
    if (this.isCreate) {
      reset()
      this.setState({data: {...DEFAULT_DATA}, error: {}})
      if (data.contacts.length === 0) {
        this.setState({data: {...data, contacts: [{type: ContactType.Email, value: user.data.email}]}})
      }
    } else {
      if (tournament.state === ProgressState.InProgress) return
      if ( (!tournament.data.uuid && ( tournament.data.id !== parseInt(match.params.id))) || (!!tournament.data.uuid && (tournament.data.uuid !== match.params.id)) ) {
        load(match.params.id)
        return
      }
      if (tournament.state === ProgressState.Complete) {
        if (!tournament.data.isEditable) {
          history.push('/')
          return
        }
        this.setState({
          data: {
            ...data,
            ...tournament.data,
            bracketParams: {...data.bracketParams, ...tournament.data.bracketParams},
          },
        })
      }
    }
    loadOrganizations()
    resetEdit()
    this._isInit = true
  }

  onAfterSave() {
    this.setState({updated: true}, () => {
      clearTimeout(this._updatedTimer)
      this._updatedTimer = setTimeout(() => {
        this.setState({updated: false})
      }, 2000)
    })
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps, prevState) {
    this.init()
    const {tournament: {data, edit}, history, resetEdit, user} = this.props
    const tournamentId = getTournamentId(data);

    if (tournamentId && this.isCreate) {
      history.push(tournamentEdit(tournamentId))
      this.setState({data})
      return
    }

    if (edit.state === ProgressState.Complete) {
      let error = edit.error
      resetEdit()

      if (error === 401) {
        history.push(LOGIN)
        return
      }

      if (!data.isEditable) {
        history.push('/')
        return
      }

      if (data.state === TournamentState.Archive) {
        history.push('/')
        return
      }

      if (data.state !== TournamentState.Draft) {
        history.push(tournamentPage(tournamentId))
        window.scrollTo(0, 0)
        return
      }
    }

    if (prevProps.tournament.data.dateUpdate !== data.dateUpdate) {
      this.onAfterSave()
    }

    if (prevState.data.organizationId !== this.state.data.organizationId) {
      const organization = user.organizations.data.find(o => o.id === this.state.data.organizationId)
      if (organization) {
        let contacts = []
        if (organization.email) {
          contacts.push({
            type: ContactType.Email,
            value: organization.email,
          })
        }
        if (organization.discord) {
          contacts.push({
            type: ContactType.Discord,
            value: organization.discord,
          })
        }
        if (organization.facebook) {
          contacts.push({
            type: ContactType.Facebook,
            value: organization.facebook,
          })
        }
        if (organization.twitter) {
          contacts.push({
            type: ContactType.Twitter,
            value: organization.twitter,
          })
        }
        if (contacts.length > 0) this.setState({data: {...this.state.data, contacts}})
      }
    }
  }

  onChange = onChange(result => {
    const data = {...this.state.data, [result.name]: result.value}
    if (result.name === 'picksCount') {
      if (data.bansCount >= result.value) {
        data.bansCount = result.value - 1
      }
    }
    this.setState({data})
  })

  onChangeBracket = onChange(result => this.setState(
    {data: {...this.state.data, bracketParams: {...this.state.data.bracketParams, [result.name]: result.value}}},
  ))

  onChangeDateStart = e => {
    if (!e.target.value) return
    let dStart = getLocalDate(this.state.data.dateStart)
    let startDate = dStart.format('YYYY-MM-DD')
    let startTime = dStart.format('HH:mm')
    if (e.target.name === 'startDate') {
      startDate = e.target.value
    } else if (e.target.name === 'startTime') {
      startTime = e.target.value
    }
    dStart = moment(`${startDate} ${startTime}`)
    this.setState({data: {...this.state.data, dateStart: dStart.toJSON()}})
  }

  onChangeDateDecksStart = e => {
    // console.log(e.target.name, `value=${e.target.value}`, {...e})
    if (!e.target.value) return
    let dStart = getLocalDate(this.state.data.dateDecksDeadline)
    let startDate = dStart.format('YYYY-MM-DD')
    let startTime = dStart.format('HH:mm')
    if (e.target.name === 'startDate') {
      startDate = e.target.value
    } else if (e.target.name === 'startTime') {
      startTime = e.target.value
    }
    dStart = moment(`${startDate} ${startTime}`)
    // console.log(dStart.format('YYYY-MM-DD HH:mm'), dStart.toJSON())
    this.setState({data: {...this.state.data, dateDecksDeadline: dStart.toJSON()}})
  }

  onSubmit = (e) => {
    if (this.validate()) {
      return
    }
    const data = this.state.data
    data.contacts = data.contacts.filter(i => i.value)
    if (e.target.dataset.publish) {
      this.props.edit({...data, publish: true})
    } else {
      this.props.edit(data)
    }
  }

  validate() {
    const error = {}
    let isError = false
    const data = this.state.data;
    if (!data.name) {
      error.name = 'name is required'
      isError = true
    }
    this.setState({error})

    return isError
  }

  onRemove = () => {
    const tournamentId = getTournamentId(this.state.data);
    this.props.remove(tournamentId);
  }

  onPublish = () => {
    const tournamentId = getTournamentId(this.state.data);
    this.props.publish(tournamentId);
  }

  onAdminChange = (e) => {
    let value = e.target.value
    let SCRIPT_REGEX = /(<([^>]+)>)/gi;
    while (SCRIPT_REGEX.test(value)) {
      value = value.replace(SCRIPT_REGEX, "");
    }
    this.setState({adminName: value, spectatorName: ''})
    this.props.resetSearch()
    this.props.search(value)
  }

  onAdminSelect = (e) => {
    this.setState({
      data: {
        ...this.state.data, admins: [
          ...this.state.data.admins, {id: e.target.dataset.userId, name: e.target.dataset.userName},
        ],
      },
      adminName: '',
      spectatorName: ''
    })
    this.props.resetSearch()
  }

  onAdminRemove = (e) => {
    const admins = this.state.data.admins
    const index = parseInt(e.currentTarget.dataset.index)
    this.setState({
      data: {
        ...this.state.data, admins: [...admins.slice(0, index), ...admins.slice(index + 1)],
      },
    })
  }

  onSpectatorChange = (e) => {
    let value = e.target.value
    let SCRIPT_REGEX = /(<([^>]+)>)/gi;
    while (SCRIPT_REGEX.test(value)) {
      value = value.replace(SCRIPT_REGEX, "");
    }
    this.setState({spectatorName: value, adminName: ''})
    this.props.resetSearch()
    this.props.search(value)
  }

  onSpectatorSelect = (e) => {
    this.setState({
      data: {
        ...this.state.data, spectators: [
          ...this.state.data.spectators, {id: e.target.dataset.userId, name: e.target.dataset.userName},
        ],
      },
      spectatorName: '',
      adminName: '',
    })
    this.props.resetSearch()
  }

  onSpectatorRemove = (e) => {
    const spectators = this.state.data.spectators
    const index = parseInt(e.currentTarget.dataset.index)
    this.setState({
      data: {
        ...this.state.data, spectators: [...spectators.slice(0, index), ...spectators.slice(index + 1)],
      },
    })
  }

  onAddContact = () => {
    this.setState({
      data:{
        ...this.state.data, contacts: [...this.state.data.contacts, {type: ContactType.Email, value: ''}]
      }
    })
  }

  onRemoveContact = (e) => {
    const contacts = this.state.data.contacts
    const index = Number(e.target.dataset.index)
    this.setState({
      data:{
        ...this.state.data, contacts: [...contacts.slice(0, index), ...contacts.slice(index + 1)]
      }
    })
  }

  onChangeContactType = (e) => {
    const index = Number(e.target.dataset.index)
    const contacts = [...this.state.data.contacts]
    contacts[index].type = Number(e.target.value)
    this.setState({data:{...this.state.data, contacts}})
  }

  onChangeContactValue = (e) => {
    const index = Number(e.target.dataset.index)
    const contacts = [...this.state.data.contacts]
    let value = e.target.value
    let SCRIPT_REGEX = /(<([^>]+)>)/gi;
    while (SCRIPT_REGEX.test(value)) {
      value = value.replace(SCRIPT_REGEX, "");
    }
    contacts[index].value = value
    this.setState({data:{...this.state.data, contacts}})
  }

  onChangePlayersLimit = (e) => {
    let playersLimit = Number(e.target.value)
    if (isNaN(playersLimit)) {
      playersLimit = 0
    } else if (playersLimit > 9999) {
      playersLimit = 9999;
    }
    this.setState({data:{...this.state.data, playersLimit}})
  }

  onBlurPlayersLimit = (e) => {
    let playersLimit = Number(e.target.value)
    if (isNaN(playersLimit)) {
      playersLimit = 0
    } else if (playersLimit < 4 && playersLimit !== 0) {
      playersLimit = 4
    } else if (playersLimit > 9999) {
      playersLimit = 9999;
    }
    this.setState({data:{...this.state.data, playersLimit}})
  }

  render() {
    return (
      <LayoutDefault className='tournament-edit-page'>
        {this.renderPage()}
      </LayoutDefault>
    )
  }

  renderPage() {
    if (!this._isInit) return null
    const {
      tournamentCheckIn,
      name,
      description,
      dateStart,
      tournamentCheckInTime,
      registration,
      visibility,
      contacts,
      organizationId,
      image,
    } = this.state.data
    const error = this.state.error
    const organizations = this.props.user.organizations.data
    const user = this.props.user.data
    const dStart = getLocalDate(dateStart)
    const startDate = dStart.format('YYYY-MM-DD')
    const startTime = dStart.format('HH:mm')
    // console.log(this.state.data)
    return (
      <section className='tournament-edit'>
        <h1>{this.isCreate ? 'New tournament' : 'Edit tournament'}</h1>
        <h2>Details</h2>
        <Form>
          <FormContainer>
            <Row>
              <Col lg={12}>
                <h3>BANNER</h3>
                <ImagesSelect name='image' value={image} onChange={this.onChange.escapeString}
                              disabled={this.isDisabled([TournamentState.Archive], [])}/>
              </Col>
            </Row>
          </FormContainer>

          <FormContainer>
            <Row>
              <Col lg={6}>
                <NewInput name='name' placeholder='Name' title='Name'
                          errorText={error.name}
                          value={name}
                          onChange={this.onChange.escapeString}
                          disabled={this.isDisabled([TournamentState.Archive])}/>
              </Col>
              {organizations.length === 0 ? null : (
                <Col lg={6}>
                  <FormGroup>
                    <Label for='organization'>Organization</Label>
                    <Input type='select' id='organizationId' value={organizationId} onChange={this.onChange.int}
                           disabled={this.isDisabled([TournamentState.Archive])}>
                      {[
                        <option key={0} value={0}/>,
                        ...organizations.map(i => <option key={i.id} value={i.id}>{i.name}</option>),
                      ]}
                    </Input>
                  </FormGroup>
                </Col>
              )}
            </Row>
          </FormContainer>

          <Row form>
            <Col lg={12}>
              <FormGroup>
                <Label for='description'>Description</Label>
                <WYSIWYG id='description'
                         placeholder='Few words about your tournament, rules and prizes...'
                         value={description}
                         onChange={this.onChange.string}
                         disabled={this.isDisabled([TournamentState.Archive])}/>
              </FormGroup>
            </Col>
          </Row>

          {contacts.map((item, i) => this.renderContact(item, i, i === contacts.length - 1))}

          <FormContainer>
            <Row>
              <Col lg={4}>
                <NewInput type='date' name='startDate' title='Start date' icon='date' value={startDate}
                          placeholder=""
                          onChange={this.onChangeDateStart}
                          disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
              </Col>
              <Col lg={4}>
                <NewInput type='time' name='startTime' title='Start time' icon='time' value={startTime}
                          placeholder=""
                          onChange={this.onChangeDateStart}
                          disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
                <div className='tournament-edit__tz'>{dStart.format('z')}</div>
              </Col>
            </Row>
          </FormContainer>

          <FormContainer>
            <Row>
              <Col lg={3}>
                <FormGroup>
                  <Label>Tournament check in</Label>
                  <ToggleButton value={tournamentCheckIn} id='tournamentCheckIn'
                                onChange={this.onChange.bool} firstButton='On' lastButton='Off'
                                disabled={this.isDisabled([TournamentState.Archive], [])}/>
                </FormGroup>
              </Col>
              {tournamentCheckIn ? (
                <Col lg={4}>
                  <FormGroup>
                    <Label for='tournamentCheckInTime'>Starts before tournament in</Label>
                    <Input type='select' id='tournamentCheckInTime'
                           value={tournamentCheckInTime}
                           onChange={this.onChange.int}
                           disabled={this.isDisabled([TournamentState.Archive], [])}>
                      <option value={30}>30 minutes</option>
                      <option value={60}>60 minutes</option>
                      <option value={90}>90 minutes</option>
                      <option value={120}>120 minutes</option>
                    </Input>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
          </FormContainer>

          <FormGroup tag='fieldset' row>
            <Col lg={7}>
              <legend>Players registration</legend>
              <FormGroup check>
                <Label check>
                  <Input type='radio' name='registration'
                         value={Registration.Open}
                         checked={registration === Registration.Open}
                         onChange={this.onChange.int}
                         disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
                  <strong>&nbsp;Open.</strong> Everyone can join.
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type='radio' name='registration'
                         value={Registration.Closed}
                         checked={registration === Registration.Closed}
                         onChange={this.onChange.int}
                         disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
                  <strong>&nbsp;Closed.</strong> Only admin can add players.
                </Label>
              </FormGroup>
            </Col>
          </FormGroup>

          <h2>Settings</h2>

          {this.renderBracketSettings()}

          {this.renderPickAndBans()}

          <Row form>
            <Col lg={7}>
              <Label>Tournament visibility</Label>
              <FormGroup check>
                <Label check>
                  <Input type='radio' name='visibility'
                         value={Visibility.Public}
                         checked={visibility === Visibility.Public}
                         onChange={this.onChange.int}
                         disabled={this.isDisabled([TournamentState.Archive], [])}/>
                  <strong>&nbsp;Public.</strong> Everyone can find your tournament in search results.
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type='radio' name='visibility'
                         value={Visibility.Hidden}
                         checked={visibility === Visibility.Hidden}
                         onChange={this.onChange.int}
                         disabled={this.isDisabled([TournamentState.Archive], [])}/>
                  <strong>&nbsp;Hidden.</strong> Only players with link can view tournament page.
                </Label>
              </FormGroup>
            </Col>
          </Row>
          {this.renderAdmins()}
          {this.renderSpectators()}
          {this.renderButtons()}
        </Form>
      </section>
    )
  }

  renderContact(item, i, isLast = false) {
    let elem = null
    if (item.type === ContactType.Email) {
      elem = (
        <NewInput title='Email address' placeholder='@mail.com' icon='email' id={'contact' + i}
                  value={item.value}
                  data-index={i}
                  onChange={this.onChangeContactValue}
                  disabled={this.isDisabled([TournamentState.Archive], [])}/>
      )
    } else if (item.type === ContactType.Discord) {
      elem = (
        <NewInput title='Tag or invite link' placeholder='' icon='discord' id={'contact' + i}
                  value={item.value}
                  data-index={i}
                  onChange={this.onChangeContactValue}
                  disabled={this.isDisabled([TournamentState.Archive], [])}/>
      )
    } else if (item.type === ContactType.Facebook) {
      elem = (
        <NewInput title='Facebook name' placeholder='' icon='facebook' id={'contact' + i}
                  value={item.value}
                  data-index={i}
                  onChange={this.onChangeContactValue}
                  disabled={this.isDisabled([TournamentState.Archive], [])}/>
      )
    } else if (item.type === ContactType.Twitter) {
      elem = (
        <NewInput title='Twitter name' placeholder='' icon='twitter' id={'contact' + i}
                  value={item.value}
                  data-index={i}
                  onChange={this.onChangeContactValue}
                  disabled={this.isDisabled([TournamentState.Archive], [])}/>
      )
    }

    return (
      <Row form key={'contact' + i}>
        <Col lg={3}>
          <FormGroup>
            <Label for={'contact-type-'+i} >Contact</Label>
            <Input type='select' id={'contact-type-'+i}
                   value={item.type}
                   data-index={i}
                   onChange={this.onChangeContactType}>
              <option value={ContactType.Email}>Email</option>
              <option value={ContactType.Discord}>Discord</option>
              <option value={ContactType.Facebook}>Facebook</option>
              <option value={ContactType.Twitter}>Twitter</option>
            </Input>
          </FormGroup>
        </Col>
        <Col lg={3}>
          {elem}
        </Col>
        {i > 0 && (
          <Col lg={2}>
            <Button color='light' className='tournament-edit__contact-btn' data-index={i}
                    onClick={this.onRemoveContact}
                    disabled={this.isDisabled([TournamentState.Archive], [])}>
              Remove
            </Button>
          </Col>
        )}
        {isLast && (
          <Col lg={2}>
            <Button color='light' className='tournament-edit__contact-btn'
                    onClick={this.onAddContact}
                    disabled={this.isDisabled([TournamentState.Archive], [])}>
              Add more
            </Button>
          </Col>
        )}
      </Row>
    )
  }

  renderButtons() {
    const {updated, data: {state, dateUpdate}} = this.state
    const {edit} = this.props.tournament
    let date = moment(dateUpdate).tz(tz)
    if ([TournamentState.Delete].includes(state)) return
    return (
      <Row form className='mt-4 tournament-edit__buttons'>
        {state === TournamentState.None ? (
          <Link to='/' className='btn btn-light mr-auto'>Cancel</Link>
        ) : (
          <Button color='danger' className='mr-auto'
                  disabled={edit.state === ProgressState.InProgress}
                  onClick={this.onRemove}>
            {state === TournamentState.Draft ? `Delete` : (state === TournamentState.Archive ? `Delete permanent` : `Archive`)}
          </Button>
        )}
        {this.renderSavedState()}
        <Button className='mr-2' disabled={edit.state === ProgressState.InProgress} color='light'
                onClick={this.onSubmit}>
          {[TournamentState.None, TournamentState.Draft].includes(state) ? 'Save as draft' : 'Save'}
        </Button>
        {state === TournamentState.None && (
          <Button disabled={edit.state === ProgressState.InProgress} color='primary'
                  data-publish={true}
                  onClick={this.onSubmit}>
            Publish
          </Button>
        )}
        {state === TournamentState.Draft ? (
          <Button disabled={edit.state === ProgressState.InProgress} color='primary'
                  onClick={this.onPublish}>
            Publish
          </Button>
        ) : null}
      </Row>
    )
  }

  renderSavedState() {
    const {updated, data: {dateUpdate}, error} = this.state
    const date = moment(dateUpdate).tz(tz)
    const isErrors = Object.values(error).length > 0
    const clName = isErrors ? '_error' : (updated ? '_active' : '')
    const text = isErrors ? 'Validate errors' : (dateUpdate ? `Last saved at ${date.format('HH:mm, MMMM D')}` : null)
    return (
      <div className={`tournament-edit__date-update mr-2 ${clName}`}>
        {text}
      </div>
    )
  }

  renderPickAndBans() {
    const {
      timeLimitForBans,
      timeLimitForBansTime,
      pickAndBans,
      matchCheckIn,
      matchCheckInTime,
      picksCount,
      bansCount,
      deckRegister,
      deckFormat,
      decksDeadlineDate,
      decksDeadlineTime,
      dateDecksDeadline,
    } = this.state.data
    const dStart = getLocalDate(dateDecksDeadline)
    const startDate = dStart.format('YYYY-MM-DD')
    const startTime = dStart.format('HH:mm')
    const bansOptions = []
    for (let i = 1; i < picksCount; i++) {
      bansOptions.push(<option key={i} value={i}>{i}</option>)
    }
    return [
      <Row form key='pickAndBans'>
        <Col lg={3}>
          <FormGroup>
            <Label>Pick and bans</Label>
            <ToggleButton value={pickAndBans} id='pickAndBans'
                          onChange={this.onChange.bool} firstButton='On' lastButton='Off'
                          disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
          </FormGroup>
        </Col>
      </Row>,
      pickAndBans ? [
        <Row form key='picks'>
          <Col lg={4}>
            <FormGroup>
              <Label for='deckRegister'>Players need to register</Label>
              <Input type='select' id='deckRegister' value={deckRegister} onChange={this.onChange.int}
                     disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}>
                <option value={DeckRegister.LeaderAndDeck}>Decks links</option>
                {/*<option value={DeckRegister.Leader}>Leaders</option>*/}
              </Input>
            </FormGroup>
          </Col>
          <Col lg={3}>
            <FormGroup>
              <Label id='amountOfPicks'>Amount of decks</Label>
              <Input type='select' id='picksCount' value={picksCount} onChange={this.onChange.int}
                     disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
              </Input>
            </FormGroup>
          </Col>
          <Col lg={3}>
            <FormGroup>
              <Label id='amountOfBans'>Amount of bans</Label>
              <Input type='select' id='bansCount' value={bansCount} onChange={this.onChange.int}
                     disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}>
                {bansOptions}
              </Input>
            </FormGroup>
          </Col>
        </Row>,
        <Row form key='decksFormat'>
          <Col lg={4}>
            <FormGroup>
              <Label>Decks format</Label>
              <ToggleButton value={deckFormat} id='deckFormat'
                            onChange={this.onChange.int}
                            width={210}
                            firstButton='Closed'
                            lastButton='Open'
                            firstButtonValue={DeckFormat.Closed}
                            lastButtonValue={DeckFormat.Open}
                            disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
            </FormGroup>
          </Col>
          <Col lg={4}>
            <NewInput type='date' name='startDate' title='Decks deadline date' icon='date'
                      value={startDate} onChange={this.onChangeDateDecksStart}
                      disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
          </Col>
          <Col lg={4}>
            <NewInput type='time' name='startTime' title='Decks deadline time' icon='time'
                      value={startTime} onChange={this.onChangeDateDecksStart}
                      disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
            <div className='tournament-edit__tz'>{dStart.format('z')}</div>
          </Col>
        </Row>,
        <Row form key='timeLimitForBans'>
          <Col lg={3}>
            <FormGroup>
              <Label>Time limit for bans</Label>
              <ToggleButton value={timeLimitForBans} id='timeLimitForBans'
                            onChange={this.onChange.bool} firstButton='On' lastButton='Off'
                            disabled={this.isDisabled([TournamentState.Archive], [])}/>
            </FormGroup>
          </Col>
          {timeLimitForBans ? (
            <Col lg={3}>
              <FormGroup>
                <Label for='timeLimitForBansTime'>Bans must be made in</Label>
                <Input type='select' id='timeLimitForBansTime' value={timeLimitForBansTime}
                       onChange={this.onChange.int}
                       disabled={this.isDisabled([TournamentState.Archive], [])}>
                  <option value={10}>10 minutes</option>
                  <option value={20}>20 minutes</option>
                </Input>
              </FormGroup>
            </Col>
          ) : null}
        </Row>,
      ] : null,
    ]
  }

  renderBracketSettings() {
    const {
      bracketType,
      playersLimit,
      manualStartRounds,
      replayDraws,
      coinFlipRandom,
      bracketParams: {groupsCount, matchFormat, finalMatchFormat}
    } = this.state.data
    let bracketElem = null
    switch (bracketType) {
      case Bracket.Single:
        bracketElem = this.renderSingle()
        break
      case Bracket.Double:
        bracketElem = this.renderDouble()
        break
      case Bracket.RoundRobin:
        bracketElem = this.renderRoundRobin()
        break
      case Bracket.Swiss:
        bracketElem = this.renderSwiss()
        break
      default:
        bracketElem = null
    }

    return [
      <Row form key='playersLimit'>
        <Col lg={2}>
          <FormGroup>
            <Label for='playersLimit'>Players limit</Label>
            <Input type='text' id='playersLimit'
                   value={playersLimit || ''}
                   onChange={this.onChangePlayersLimit}
                   onBlur={this.onBlurPlayersLimit}
                   disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
          </FormGroup>
        </Col>
      </Row>,

      <Row form key='bracketType'>
        <Col lg={4}>
          <FormGroup>
            <Label for='bracketType'>Bracket format</Label>
            <Input type='select' id='bracketType' value={bracketType}
                   onChange={this.onChange.int}
                   disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}>
              <option value={Bracket.Single}>Single elimination</option>
              <option value={Bracket.Double}>Double elimination</option>
              <option value={Bracket.RoundRobin}>Round Robin</option>
              <option value={Bracket.Swiss}>Swiss</option>
            </Input>
          </FormGroup>
        </Col>
        {bracketType === Bracket.RoundRobin && (
          <Col lg={3}>
            <FormGroup>
              <Label for='groupsCount'>Amount of groups</Label>
              <Input type='select' id='groupsCount' value={groupsCount} onChange={this.onChangeBracket.int}
                     disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
              </Input>
            </FormGroup>
          </Col>
        )}
        <Col lg={3}>
          <FormGroup>
            <Label for='matchFormat'>Match format</Label>
            <Input type='select' id='matchFormat' value={matchFormat} onChange={this.onChangeBracket.int}
                   disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}>
              <option value={1}>Best of 1</option>
              <option value={3}>Best of 3</option>
              <option value={5}>Best of 5</option>
            </Input>
          </FormGroup>
        </Col>
        {bracketType === Bracket.Single || bracketType === Bracket.Double ? (
          <Col lg={3}>
            <FormGroup>
              <Label for='finalMatchFormat'>Final match format</Label>
              <Input type='select' id='finalMatchFormat' value={finalMatchFormat} onChange={this.onChangeBracket.int}
                     disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}>
                <option value={1}>Best of 1</option>
                <option value={3}>Best of 3</option>
                <option value={5}>Best of 5</option>
              </Input>
            </FormGroup>
          </Col>
        ) : null}
      </Row>,

      <Row form key='replayDraws'>
        <Col lg={7}>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input type='checkbox' checked={replayDraws} id='replayDraws'
                       onChange={this.onChange.bool}
                       disabled={this.isDisabled([TournamentState.Archive], [])}/>
                &nbsp;Replay drawn games.
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>,

      <Row form key='manualStartRounds'>
        <Col lg={7}>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input type='checkbox' checked={manualStartRounds} id='manualStartRounds'
                       onChange={this.onChange.bool}
                       disabled={this.isDisabled([TournamentState.Archive], [])}/>
                &nbsp;Starts rounds manually.
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>,

      bracketElem,

      <Row form key='coinFlipRandom'>
        <Col lg={4}>
          <FormGroup>
            <Label>Coinflip</Label>
            <ToggleButton value={coinFlipRandom} id='coinFlipRandom'
                          width={210}
                          onChange={this.onChange.bool} firstButton='Random' lastButton='Manual'
                          disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
          </FormGroup>
        </Col>
      </Row>,
    ]
  }

  renderSingle() {
    const {bracketParams: {third}, manualSeeding} = this.state.data
    return [
      <Row form key='3dPlace'>
        <Col lg={7}>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input type='checkbox' checked={third} name='third' onChange={this.onChangeBracket.bool}
                       disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
                &nbsp;Include match for 3d place.
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>,
      <Row form key='manualSeeding'>
        <Col lg={7}>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input type='checkbox'
                       checked={manualSeeding}
                       name='manualSeeding'
                       onChange={this.onChange.bool}
                       disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
                &nbsp;Enable manual seeding.
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>,
    ]
  }

  renderDouble() {
    const {bracketParams: {finalMatchesCount}, manualSeeding} = this.state.data
    return [
      <Row form key='finalMatches'>
        <Col lg={3}>
          <FormGroup>
            <Label for='finalMatchesCount'>Final match count</Label>
            <Input type='select' id='finalMatchesCount' value={finalMatchesCount} onChange={this.onChangeBracket.int}
                   disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}>
              <option value={1}>1 match</option>
              <option value={2}>1-2 matches</option>
              <option value={0}>None</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>,
      <Row form key='manualSeeding'>
        <Col lg={7}>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input type='checkbox'
                       checked={manualSeeding}
                       name='manualSeeding'
                       onChange={this.onChange.bool}
                       disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
                &nbsp;Enable manual seeding.
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>,
    ]
  }

  renderRoundRobin() {
    const {manualSeeding} = this.state.data
    return [
      <Row form key='manualSeeding'>
        <Col lg={7}>
          <FormGroup>
            <FormGroup check>
              <Label check>
                <Input type='checkbox'
                       checked={manualSeeding}
                       name='manualSeeding'
                       onChange={this.onChange.bool}
                       disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
                &nbsp;Enable manual seeding.
              </Label>
            </FormGroup>
          </FormGroup>
        </Col>
      </Row>,
      this.renderPointsAttribution(),
    ]
  }

  renderSwiss() {
    const {roundCount, losesCount} = this.state.data.bracketParams
    return [
      <Row form key='amountOfRounds'>
        <Col lg={2}>
          <NewInput placeholder='' title='Amount of rounds'
                    name='roundCount'
                    value={roundCount}
                    onChange={this.onChangeBracket.int}
                    disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
        </Col>
        <Col lg={2}>
          <NewInput placeholder='' title='Amount of loses'
                    name='losesCount'
                    value={losesCount}
                    onChange={this.onChangeBracket.int}
                    disabled={this.isDisabled([TournamentState.PreStart, TournamentState.Start, TournamentState.Archive])}/>
        </Col>
      </Row>,
      this.renderPointsAttribution(),
    ]
  }

  renderPointsAttribution() {
    const {pointsAttribution, pointsPerBye, pointsPerDraw, pointsPerLoss, pointsPerWin} = this.state.data.bracketParams
    return [
      <Row form key='pointsAttribution'>
        <Col lg={3}>
          <FormGroup>
            <Label>Points attribution</Label>
            <ToggleButton value={pointsAttribution} id='pointsAttribution'
                          onChange={this.onChangeBracket.int}
                          width={240}
                          firstButton='Per match'
                          lastButton='Per game'
                          firstButtonValue={PointsAttribution.PerMatch}
                          lastButtonValue={PointsAttribution.PerGame}
                          disabled={this.isDisabled([TournamentState.Archive], [])}/>

          </FormGroup>
        </Col>
      </Row>,

      <strong key='points'>POINTS</strong>,

      <Row form key='per'>
        <Col lg={2}>
          <FormGroup>
            <Label for='perWin'>Per win</Label>
            <Input type='text' id='pointsPerWin' value={pointsPerWin} onChange={this.onChangeBracket.int}
                   disabled={this.isDisabled([TournamentState.Archive], [])}/>
          </FormGroup>
        </Col>
        <Col lg={2}>
          <FormGroup>
            <Label for='perDraw'>Per draw</Label>
            <Input type='text' id='pointsPerDraw' value={pointsPerDraw} onChange={this.onChangeBracket.int}
                   disabled={this.isDisabled([TournamentState.Archive], [])}/>
          </FormGroup>
        </Col>
        <Col lg={2}>
          <FormGroup>
            <Label for='perLoss'>Per loss</Label>
            <Input type='text' id='pointsPerLoss' value={pointsPerLoss} onChange={this.onChangeBracket.int}
                   disabled={this.isDisabled([TournamentState.Archive], [])}/>
          </FormGroup>
        </Col>
        <Col lg={2}>
          <FormGroup>
            <Label for='perBye'>Per bye</Label>
            <Input type='text' id='pointsPerBye' value={pointsPerBye} onChange={this.onChangeBracket.int}
                   disabled={this.isDisabled([TournamentState.Archive], [])}/>
          </FormGroup>
        </Col>
      </Row>,
    ]
  }

  renderAdmins() {
    const admins = this.state.data.admins
    const adminName = this.state.adminName
    const adminsNames = admins.map(item => item.name)
    const search = this.props.user.search.data.filter(u => !adminsNames.includes(u.name))
    return (
      <div className='mt-3'>
        <h2>Admins</h2>
        <Row form className='user-search'>
          <Col lg={3}>
            <FormGroup>
              <Label for='adminName'>Username</Label>
              <Input type='text' id='adminName'
                     placeholder='Type username to add'
                     value={adminName}
                     onChange={this.onAdminChange}/>
              {search.length > 0 && adminName ? (
                <div className='user-search__list'>
                  {search.map(
                    u => <div className='user-search__list-item' key={u.id} data-user-id={u.id} data-user-name={u.name}
                              onClick={this.onAdminSelect}>
                      {u.name}
                    </div>)}
                </div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <div className='tournaments-edit__admins'>
            {admins.map((item, i) => <div className='tournaments-edit__admin-item' key={i}>
              {item.name}
              <Button color='light' onClick={this.onAdminRemove} data-index={i}><MaterialIcon icon='close'/></Button>
            </div>)}
          </div>
        </Row>
      </div>
    )
  }

  renderSpectators() {
    const spectators = this.state.data.spectators
    const spectatorName = this.state.spectatorName
    const spectatorsNames = spectators.map(item => item.name)
    const search = this.props.user.search.data.filter(u => !spectatorsNames.includes(u.name))
    return (
      <div className='mt-3'>
        <h2>Spectators</h2>
        <Row form className='user-search'>
          <Col lg={3}>
            <FormGroup>
              <Label for='adminName'>Username</Label>
              <Input type='text' id='spectatorName'
                     placeholder='Type username to add'
                     value={spectatorName}
                     onChange={this.onSpectatorChange}/>
              {search.length > 0 && spectatorName ? (
                <div className='user-search__list'>
                  {search.map(
                    u => <div className='user-search__list-item' key={u.id} data-user-id={u.id} data-user-name={u.name}
                              onClick={this.onSpectatorSelect}>
                      {u.name}
                    </div>)}
                </div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <div className='tournaments-edit__admins'>
            {spectators.map((item, i) => <div className='tournaments-edit__admin-item' key={i}>
              {item.name}
              <Button color='light' onClick={this.onSpectatorRemove} data-index={i}><MaterialIcon icon='close'/></Button>
            </div>)}
          </div>
        </Row>
      </div>
    )
  }

}

export const TournamentEditPage = connect(mapState, mapDispatch)(CTournamentEditPage)
