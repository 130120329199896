import React from 'react'
import MaterialIcon from '@material/react-material-icon'

export class MatchPlayer extends React.PureComponent {
  onPlayerClick = (e) => {
    this.props.onPlayerClick(this.props.bracket, this.props.index, parseInt(e.currentTarget.dataset.player))
  }
  onPlayerClear = (e) => {
    this.props.onPlayerClear(this.props.bracket, this.props.index, parseInt(e.currentTarget.dataset.player))
  }

  render() {
    const {
      data: {players, score},
      seeding,
      playerIndex,
    } = this.props
    let name = ''
    let scoreElem = ''
    let className = ''
    const isSeeding = !!seeding
    let onClick = null
    if (isSeeding) {
      if (seeding && seeding[playerIndex]) {
        name = seeding[playerIndex].name
        scoreElem = <MaterialIcon icon='close' data-player={playerIndex} onClick={this.onPlayerClear}/>
      }
      if (!name) {
        name = <span><MaterialIcon icon='person'/> Seed</span>
        scoreElem = <MaterialIcon icon='add'/>
        className = '_seed'
        onClick = this.onPlayerClick
      }
    } else {
      if (players && players[playerIndex]) {
        name = players[playerIndex].name || ''
        if (players[playerIndex].win) className = '_winner'
      }
      if (score && score[playerIndex] != null) scoreElem = score[playerIndex]
    }

    return (
      <div className={`bracket__player _${playerIndex + 1} ${className}`} data-player={playerIndex} onClick={onClick}>
        <div className='bracket__player-name'>{name}</div>
        <div className='bracket__player-score'>{scoreElem}</div>
      </div>
    )
  }
}

