import styled from 'styled-components';
import {Dropdown, DropdownMenu, DropdownToggle, Label as OldLabel } from "reactstrap";


export const DropdownWrapper = styled(Dropdown)``;
export const Toggle = styled(DropdownToggle)`
  background: #131313;
  border: 1px solid #949494;
  border-radius: 2px;
  width: 100%;
  display: flex;
  color: #949494;
  padding: 3px 0 4px 11px;
  align-items: center;
  position: relative;
  height: 30px;
  ${ props => props.isOpen && `
    border-bottom: 1px solid transparent;
    border-radius: 2px 2px 0 0;
  ` }
  &:before {
    content: '';
    border-right: 1px solid #949494;
    border-bottom: 1px solid #949494;
    width: 7px;
    height: 7px;
    position: absolute;
    right: 10px;
    transform: rotate(${ props => props.toggle ? '44deg' : '-136deg' });
    transition: transform 0.4s ease;
  }
}
`;
export const Menu = styled(DropdownMenu)`
      width: 100%;
      padding: 0;
      background: #131313;
      border: 1px solid #949494;
      border-top: none;
      border-radius: 0;
      margin: 0;
      top: -1px !important;
`;

export const Item = styled.div`
	color: #949494;
    margin: 0 25px 0 11px;
    border-bottom:  1px solid #424242;
    padding: 6px 0;
	&:first-child {
		border-top: 1px solid #424242;
	}
	&:last-child {
		border-bottom: 0;
	}
`;

export const Label = styled(OldLabel)`
    margin-bottom: 0;
`;

export const ContainerLabel = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
`;
