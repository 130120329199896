import React from 'react'
import { Header } from '../../header/index'
import { Footer } from '../../footer/index'

export const LayoutFullScreen = ({children, className}) => [
  <Header key={0}/>,
  <main key={1} className={'main-container ' + className}>
    {children}
  </main>,
  <Footer key={2}/>,
]