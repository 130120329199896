import { routerMiddleware, LOCATION_CHANGE } from 'connected-react-router'
import { init } from '@rematch/core'
import * as models from '../models'
import { compose } from 'redux'
import { history } from './history'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const routerReducer = (history) => {
  const initialState = {
    location: history.location,
    action: history.action,
  }
  return (state = initialState, {type, payload} = {}) => {
    if (type === LOCATION_CHANGE) {
      return {...state, ...payload}
    }

    return state
  }
}
const queryMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE) {
    store.dispatch({type: 'query/change', payload: action.payload})
  }
  return next(action)
}

export const store = init({
  models,
  redux: {
    middlewares: [
      routerMiddleware(composeEnhancers(history)),
      queryMiddleware,
    ],
    reducers: {
      router: routerReducer(history),
    },
  },

})

window.store = store
