export const MatchState = {
  STATE_NONE: 0,
  STATE_CHECK_IN: 3,
  STATE_CHECKED_IN: 4,
  STATE_PICK_AND_BAN: 5,
  STATE_PICK_AND_BAN_FINISH: 6,
  STATE_CREATED: 1,
  STATE_FINISHED: 2,
}

export const MatchPickAndBans = {
  PICK_AND_BAN_NOT_STARTED: 0,
  PICK_AND_BAN_STARTED: 1,
  PICK_AND_BAN_FINISH: 2,
  PICK_AND_BAN_DISABLED: 3,
}
