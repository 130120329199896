export const Registration = {
  Closed: 0,
  Open: 1
}

export const Visibility = {
  Public: 0,
  Hidden: 1
}

export const Bracket = {
  Single: 0,
  Double: 1,
  RoundRobin: 2,
  Swiss: 3
}

export const PointsAttribution = {
  PerMatch: 0,
  PerGame: 1
}

export const TournamentState = {
  None: 0,
  Draft: 1,
  Publish: 2,
  Start: 3,
  Archive: 4,
  Delete: 5,
  PreStart: 6,
}

export const DeckRegister = {
  LeaderAndDeck: 0,
  Leader: 1
}

export const DeckFormat = {
  Closed: 0,
  Open: 1
}

export const UserState = {
  NONE: 0,
  JOIN: 1,
  JOINED: 2,
  PLAYING: 3,
  LOSE: 4,
  WIN: 5,
  CHECK_IN: 6,
  CHECKED_IN: 7,
  DECK_UPLOAD: 8,
  DECK_APPROVED: 9,
  DECK_CHECK_IN: 10,
  DECK_CHECKED_IN: 11,
  TECHNICAL_LOSE: 12,
}

export const ParticipantStatus = {
  PLAYING: 0,
  TECHNICAL_LOSE: 1,
  LOSE: 2,
  WIN: 3,
  TECHNICAL_LOSE_ON_START: 4,
}
