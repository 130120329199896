import React from 'react';
import TooltipImg from "./icon/tooltip";
import styled from "styled-components";

const ToolTip = ({tooltip}) => {
	return (
		<Container>
			<TooltipImg/>
			<ToolTipBanner>{tooltip}</ToolTipBanner>
		</Container>
	)
}

export default ToolTip;

const Container = styled.div`
	padding-left: 9px;
	cursor: pointer;
	position: relative;
	display: flex;
	&:hover {
		> div:last-child {
			visibility: visible;
		}
	}
`;

const ToolTipBanner = styled.div`
	position: absolute;
	width: 346px;
	height: 83px;
	background: #E5A830;
	padding: 11px 38px 11px 13px;
	color: #000000;
    top: -105px;
    line-height: 20px;
    left: 14px;
    visibility: hidden;
	&:before {
		content: '';
		height: 24px;
	    width: 42px;
        border-left: 20px solid #E5A830;
    	border-top: 0 solid #E5A830;
    	border-bottom: 10px solid transparent;
    	border-right: 0 solid transparent;
	    position: absolute;
	    bottom: -10px;
	    left: 0;
	}
`;