import React from 'react'
import { Registration, TournamentState } from '../../constants/tournament'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import MaterialIcon from '@material/react-material-icon'
import { Avatar } from '../avatar'
import { OrgAvatar } from '../avatar/org-avatar'
import { tournament } from '../routes'
import memoize from 'lodash/memoize'
import { Bracket } from '../../constants/tournament'
import { getLocalDate } from '../../utils/date'
import {getTournamentId} from "../../utils/tournament";

const getStyle = memoize(img => ({backgroundImage: `url(/tournament-images/${img})`}))

const getBracketName = (bracket) => {
  switch (bracket) {
    case Bracket.Single:
      return 'Single elimination'
    case Bracket.Double:
      return 'Double elimination'
    case Bracket.RoundRobin:
      return 'Round robin '
    case Bracket.Swiss:
      return 'Swiss'
    default:
      return 'Single elimination'
  }
}

export const TournamentCard = ({data, featured, children}) => {
  let date = getLocalDate(data.dateStart)
  let content = null
  if (children) {
    content = (
      <div className='tournaments__card-bottom'>{children}</div>
    )
  } //view_module table_chart grid_on apps
  const started = data.isComplete ? 'Finished' : `${date.isAfter(moment()) ? 'Starts' : 'Started'} ${date.fromNow()}`
  const tournamentId = getTournamentId(data);
  return (
    <Col md={6} lg={featured ? 6 : 4}>
      <Link to={tournament(tournamentId)} className={`tournaments__card ${featured ? '_featured' : ''}`}>
        <div className='tournaments__card-image' style={getStyle(data.image || '000.png')}>
          <div className='tournaments__card-remained'>
            {data.state === TournamentState.Archive ? 'Archived' : started}
          </div>
          <div className='tournaments__card-image-bottom'/>
        </div>
        <div className='tournaments__card-body'>
          <div className='tournaments__card-name'>{data.name}</div>
          <div className='tournaments__card-info'>
            <MaterialIcon icon='date_range'/>
            <time>{date.format('MMMM D')}</time>
          </div>
          <div className='tournaments__card-info'>
            <MaterialIcon icon={data.registration === Registration.Closed
              ? 'highlight_off'
              : 'check_circle_outline'} className={data.registration === Registration.Closed ? '_closed' : '_open'}/>
            {data.registration === Registration.Closed ? 'Closed' : 'Open'} registration
          </div>
          <div className='tournaments__card-info'>
            <MaterialIcon icon='access_time'/>
            <time>{date.format('HH:mm z')}</time>
          </div>
          <div className='tournaments__card-info'>
            <MaterialIcon icon='people'/>{data.playersCount}
            {data.playersLimit > 0 ? `/${data.playersLimit}` : ''} players joined
          </div>
          <div className='tournaments__card-info'>
            <MaterialIcon icon='table_chart'/>{getBracketName(data.bracketType)}
          </div>
          <div className='tournaments__card-host'>
            {data.organizationId ?
              <OrgAvatar className='tournaments__card-host-avatar' src={data.host.avatar}/> :
              <Avatar className='tournaments__card-host-avatar' avatar={data.host.avatar} border={data.host.border}/>
            }
            <div>
              Hosted by <span>{data.host.name}</span>
            </div>
          </div>
          {content}
        </div>
      </Link>
    </Col>
  )
}
