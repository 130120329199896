import React from 'react'
import { MatchPlayer } from './match-player'
import { MatchSpectator } from './match-spectator'
import { Link } from 'react-router-dom'
import { tournamentMatch } from '../../../routes'
import MaterialIcon from '@material/react-material-icon'
import {Spectators} from './spectators'

export class Match extends React.PureComponent {
  state = {
    showSpectatorsBlock: false,
  }

  toggleSpectatorsBlock = () => {
    this.setState({showSpectatorsBlock: !this.state.showSpectatorsBlock})
  }

  onSpectatorAdd = (e) => {
    const {tournamentId, data: {id}, addMatchSpectator} = this.props
    addMatchSpectator({tournamentId, matchId: id, userId: e.target.dataset.spectatorId})
    this.setState({showSpectatorsBlock: !this.state.showSpectatorsBlock})
  }

  onSpectatorRemove = (e) => {
    const {tournamentId, data: {id}, removeMatchSpectator} = this.props
    const result = {tournamentId, matchId: id, userId: e.target.dataset.spectatorId}
    if (e.target.dataset.matchId) {
      result.matchId = e.target.dataset.matchId
    }
    removeMatchSpectator(result)
  }

  onMatchEditClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onMatchEditClick) {
      this.props.onMatchEditClick(e)
    }
  }

  onMatchResetClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.resetMatchHandler) {
      this.props.resetMatchHandler(e)
    }
  }

  render() {
    const {
      tournamentId,
      data: {id, spectators = []},
      seeding,
      isEdit = false,
      isEditable = false,
    } = this.props

    if (tournamentId && id && !seeding && !isEdit) {
      return (
        <Link to={tournamentMatch(tournamentId, id)} className={`bracket__match ${this.props.className}`}>
          <MatchPlayer key={0} {...this.props} playerIndex={0}/>
          <MatchPlayer key={1} {...this.props} playerIndex={1}/>
          {spectators.length > 0 && (
            <MatchSpectator spectator={spectators[0]}
                            spectatorIndex={0}
                            isEdit={isEdit}
                            matchId={id}
                            tournamentId={tournamentId}/>
          )}
          {spectators.length > 1 && (
            <MatchSpectator spectator={spectators[1]}
                            spectatorIndex={1}
                            isEdit={isEdit}
                            matchId={id}
                            tournamentId={tournamentId}/>
          )}
          {this.renderEditIco()}
        </Link>
      )
    }

    return (
      <div className={`bracket__match ${this.props.className}`}>
        <MatchPlayer key={0} {...this.props} playerIndex={0}/>
        <MatchPlayer key={1} {...this.props} playerIndex={1}/>
        {this.renderSpectators()}
        {isEdit && spectators.length < 2 && (
          <MatchSpectator spectator={null}
                          spectatorIndex={spectators.length}
                          isEdit={isEdit}
                          matchId={id}
                          tournamentId={tournamentId}
                          onAddSpectatorClick={this.toggleSpectatorsBlock}/>
        )}
        {this.renderEditIco()}
        {this.renderSpectatorsBlock()}
      </div>
    )
  }

  renderSpectators() {
    const {data: {id, spectators = []}, isEdit = false, tournamentId} = this.props
    return spectators.map((s, i) => (
      <MatchSpectator spectator={s}
                      key={i}
                      spectatorIndex={i}
                      isEdit={isEdit}
                      matchId={id}
                      tournamentId={tournamentId}
                      onRemoveSpectatorClick={this.onSpectatorRemove}/>
    ))
  }

  renderEditIco() {
    const {data: {id}, showResetButton = false, isEditable = false, isEdit = false} = this.props
    if (!isEditable) return null
    return (
      <div className='bracket__match-edit-ico'
           data-match-id={id}
           onClick={this.onMatchEditClick}>
        {isEdit ? (
          <MaterialIcon icon='close'/>
        ) : (
          <>
            <MaterialIcon icon='visibility'/>
            <MaterialIcon icon='add'/>
            {showResetButton ? <MaterialIcon icon='restore' data-match-id={id} onClick={this.onMatchResetClick} /> : '' }
          </>
        )}
      </div>
    )
  }

  renderSpectatorsBlock() {
    const {
      isEdit = false,
      availableSpectators = [],
      assignedSpectators = [],
      spectators = [],
      tournamentId,
      data: {players = []}
    } = this.props
    const {showSpectatorsBlock} = this.state
    if (!isEdit || !showSpectatorsBlock) return null
    const playersId = players.filter(p => p != null).map(p => p.id)
    const availSpectators = availableSpectators.filter(p => !playersId.includes(p.id))
    return (
      <div className='bracket__spectators'>
        <Spectators spectators={spectators} tournamentId={tournamentId}/>
        <div className='bracket__spectators-title'>Select spectators to assign</div>
        <div className='bracket__spectators-row'>
          <div className='bracket__spectators-col'>
            <div className='bracket__spectators-col-title'>Not assigned</div>
            {availSpectators.map((s, i) => (
              <div key={i} className='bracket__spectators-col-item'
                   data-spectator-id={s.id}
                   onClick={this.onSpectatorAdd}>
                {s.name}
              </div>
            ))}
          </div>
          <div className='bracket__spectators-col'>
            <div className='bracket__spectators-col-title'>Assigned</div>
            {assignedSpectators.map((item, i) => (
              <div key={i} className='bracket__spectators-col-item'
                   data-spectator-id={item.spectator.id}
                   data-match-id={item.match.id}
                   onClick={this.onSpectatorRemove}>
                {item.spectator.name}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

