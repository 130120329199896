import React from 'react'
import { connect } from 'react-redux'
import { Avatar } from '../../avatar'
import {
  Profile,
  PlayerContainer,
  PlayerText,
  PlayerBg,
  PlayerCardSelections,
  CardContainer,
  CardImg,
  CardActions,
  ImgContainer,
  WarningContainer,
  WarningText,
  WrapperUser,
  ButtonContainer,
  PlayerApprovedContainer,
  PlayerApprovedCard,
  PlayerApprovedImg,
  PlayerApprovedText,
} from './profile-card.styles'
import { Button, Col } from 'reactstrap'
import { UserState } from '../../../constants/tournament'
import Input from './../../atoms/input'
import WarningImg from './../../ico/warning'
import isEqual from 'lodash/isEqual'
import { ProgressState } from '../../../constants/progress-state'
import moment from 'moment-timezone'
import { Match } from './brackets/match'
import Modal from '../../atoms/modal'
import { ModalButtonWrapper, ModalText } from '../../atoms/modal/styles'
import {getTournamentId} from "../../../utils/tournament";

const tz = moment.tz.guess()

const mapState = ({tournament, user, tournamentUserStatus}) => ({
  tournament,
  user,
  tournamentUserStatus,
})
const mapDispatch = ({tournamentUserStatus, tournament}) => ({
  join: tournamentUserStatus.join,
  deckUpload: tournamentUserStatus.deckUpload,
  errorDeckReset: tournamentUserStatus.errorDeckReset,
  checkIn: tournament.checkIn,
  leave: tournament.leave,
})

const styleInput = {
  height: 26,
  width: '100%',
  maxWidth: '270px',
}

class CProfileCard extends React.PureComponent {
  state = {
    decks: [],
    showModalLeave: false,
  }

  init() {
    const tournament = this.props.tournament.data
    if (tournament.userStatus && tournament.userStatus.decks) {
      let decks = []
      for (let i = 0; i < tournament.picksCount; i++) {
        decks.push(tournament.userStatus.decks[i] || {})
      }
      this.setState({decks})
    }
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps) {
    const tournament = this.props.tournament.data
    const deckStatus = this.props.tournamentUserStatus.deck
    if (!isEqual(prevProps.tournament.data, tournament)) {
      this.init()
    }
    if (deckStatus.error) {
      this.props.errorDeckReset()
      this.init()
    }
  }

  render() {
    const user = this.props.user.data
    const tournament = this.props.tournament.data
    if (!user || !tournament || !tournament.userStatus) return null
    const content = this.renderContent()
    if (content == null) return null
    return (
      <Profile>
        {this.renderModalLeave()}
        {content}
      </Profile>
    )
  }

  renderContent() {
    const tournament = this.props.tournament.data
    switch (tournament.userStatus.state) {
      case UserState.JOIN:
        return this.renderPlayerJoin()
      case UserState.JOINED:
      case UserState.CHECK_IN:
        return this.renderPlayerJoined()
      case UserState.DECK_UPLOAD:
        return this.renderDecksUpload()
      case UserState.PLAYING:
        return this.renderPlaying()
      case UserState.DECK_APPROVED:
      case UserState.DECK_CHECK_IN:
      case UserState.DECK_CHECKED_IN:
        return this.renderDeckApproved()
      default:
        return null
    }
  }

  onJoinClick = () => {
    const tournamentId = getTournamentId(this.props.tournament.data);
    this.props.join(tournamentId)
  }

  onDeckUploadChange = (e) => {
    const idx = Number(e.target.dataset.deckidx)
    const decks = [...this.state.decks]
    decks[idx].link = e.target.value
    this.setState({decks})
  }

  onDeckUploadClick = (e) => {
    const link = e.target.dataset.decklink
    const deckId = e.target.dataset.deckid
    const tournamentId = getTournamentId(this.props.tournament.data);
    this.props.deckUpload({link, deckId, tournamentId: tournamentId})
  }

  onCheckInClick = () => {
    const tournamentId = getTournamentId(this.props.tournament.data);
    this.props.checkIn({tournamentId: tournamentId})
  }

  onLeaveClick = () => {
    const tournamentId = getTournamentId(this.props.tournament.data);
    this.props.leave(tournamentId)
    this.onCloseModal()
  }

  onShowModalLeave = () => {
    this.setState({showModalLeave: true})
  }

  onCloseModal = () => {
    this.setState({showModalLeave: false})
  }

  renderPlayerJoin() {
    const user = this.props.user.data
    return (
      <PlayerBg>
        <PlayerContainer>
          <div className='match__lobby-player'>
            <Avatar className='tournament-user__avatar' avatar={user.avatar} border={user.border}/>
            <div className='match__lobby-player-info'>
              <div className='match__lobby-player-name'>{user.name}</div>
            </div>
            <Button color='primary' className='ml-auto' onClick={this.onJoinClick}>Join tournament</Button>
          </div>
        </PlayerContainer>
      </PlayerBg>
    )
  };

  renderPlayerJoined() {
    const user = this.props.user.data
    const tournament = this.props.tournament.data
    let text = 'Thanks for joining!'
    if (tournament.tournamentCheckIn) {
      text += ` Come back for check-in, it will start ${moment.duration(tournament.tournamentCheckInTime, 'm')
                                                              .humanize(true)} before the tournament.`
    }
    const checkInDisabled = tournament.userStatus.state !== UserState.CHECK_IN
    return (
      <PlayerBg>
        <PlayerContainer>
          <WrapperUser>
            <div className='match__lobby-player'>
              <Avatar className='tournament-user__avatar' avatar={user.avatar} border={user.border}/>
              <div className='match__lobby-player-info'>
                <div className='match__lobby-player-name'>{user.name}</div>
                <div className='match__lobby-player-online _active'>Joined the tournament</div>
              </div>
            </div>
            <ButtonContainer>
              {tournament.tournamentCheckIn && (
                <Button color='light' disabled={checkInDisabled} onClick={this.onCheckInClick} style={{width: 147}}>
                  check-in
                </Button>
              )}
              <Button color='secondary' onClick={this.onShowModalLeave}>
                Leave tournament
              </Button>
            </ButtonContainer>
          </WrapperUser>
          <PlayerText>{text}</PlayerText>
        </PlayerContainer>
      </PlayerBg>
    )
  }

  renderDecksUpload() {
    const user = this.props.user.data
    const tournament = this.props.tournament.data
    const decksCount = tournament.picksCount
    const deckState = this.props.tournamentUserStatus.deck.state
    const date = moment(tournament.dateDecksDeadline).tz(tz)
    return (
      <PlayerBg>
        <PlayerContainer>
          <WrapperUser>
            <div className='match__lobby-player'>
              <Avatar className='tournament-user__avatar' avatar={user.avatar} border={user.border}/>
              <div className='match__lobby-player-info'>
                <div className='match__lobby-player-name'>{user.name}</div>
                <div className='match__lobby-player-online _active'>Joined the tournament</div>
              </div>
            </div>
            <ButtonContainer>
              <Button color='secondary' onClick={this.onShowModalLeave}>
                Leave tournament
              </Button>
            </ButtonContainer>
          </WrapperUser>
          <PlayerText>
            Thanks for joining! Please, submit the decklists you are going to play in the
            tournament:
          </PlayerText>
          <PlayerCardSelections>
            {this.state.decks.map((deck, idx) => (
              <CardContainer key={idx}>
                <ImgContainer>
                  <CardImg className={`leader-ability _${deck.leaderId || 0}`}/>
                </ImgContainer>
                <CardActions>
                  <Input placeholder='link to deck' style={styleInput}
                         data-deckidx={idx}
                         value={deck.link || ''}
                         disabled={deckState === ProgressState.InProgress || moment().tz(tz).isAfter(date)}
                         onChange={this.onDeckUploadChange}/>
                  <Button color='primary' style={{width: 84}}
                          disabled={!deck.link || deckState === ProgressState.InProgress || moment().tz(tz).isAfter(date)}
                          data-deckid={deck.id}
                          data-decklink={deck.link}
                          onClick={this.onDeckUploadClick}>Save</Button>
                </CardActions>
              </CardContainer>
            ))}
          </PlayerCardSelections>
          <WarningContainer>
            <WarningImg/>
            {!moment().tz(tz).isAfter(date) && <WarningText>Upload {decksCount} deckslist links from {' '}
              <a href='http://playgwent.com/decks' target='_blank' rel='noopener noreferrer'>
                playgwent.com/decks.
              </a> <br/>
              You can change and edit them any time up to {date.format('HH:mm, MMM D')}.
            </WarningText>}
            {moment().tz(tz).isAfter(date) && <WarningText>
              The deadline for editing your decks has passed ({date.format('HH:mm, MMM D')}).<br/>
              The tournament admin will verify your decks.
            </WarningText>}
          </WarningContainer>
        </PlayerContainer>
      </PlayerBg>
    )
  }

  renderDeckApproved() {
    const user = this.props.user.data
    const tournament = this.props.tournament.data
    const checkInDisabled = tournament.userStatus.state !== UserState.DECK_CHECK_IN
    return (
      <PlayerBg>
        <PlayerContainer>
          <WrapperUser>
            <div className='match__lobby-player'>
              <Avatar className='tournament-user__avatar' avatar={user.avatar} border={user.border}/>
              <div className='match__lobby-player-info'>
                <div className='match__lobby-player-name'>{user.name}</div>
                <div className='match__lobby-player-online _active'>Joined the tournament</div>
              </div>
            </div>
            <ButtonContainer>
              {tournament.tournamentCheckIn && (
                <Button color='light' disabled={checkInDisabled} onClick={this.onCheckInClick} style={{width: 147}}>
                  check-in
                </Button>
              )}
              <Button color='secondary' onClick={this.onShowModalLeave}>
                Leave tournament
              </Button>
            </ButtonContainer>
          </WrapperUser>
          <PlayerText>Good news! Your decklists were approved by the organizer.</PlayerText>
          <Col lg={6}>
            <PlayerApprovedContainer>
              {tournament.userStatus.decks.map(deck => (
                <PlayerApprovedCard key={deck.id}>
                  <PlayerApprovedImg className={`leader-ability _${deck.leaderId}`}/>
                  <PlayerApprovedText>{deck.name}</PlayerApprovedText>
                </PlayerApprovedCard>
              ))}
            </PlayerApprovedContainer>
          </Col>
          <PlayerText>
            You can’t edit decks after approving. Please, contact to the organizer if you want to change them.
          </PlayerText>
        </PlayerContainer>
      </PlayerBg>
    )
  }

  renderPlaying() {
    const user = this.props.user.data
    const tournament = this.props.tournament.data
    const {matches = {next: [], current: [], finish: []}, score = {}} = tournament.userStatus
    const tournamentId = getTournamentId(this.props.tournament.data);
    return (
      <PlayerBg>
        <PlayerContainer>
          <WrapperUser>
            <div className='match__lobby-player'>
              <Avatar className='tournament-user__avatar' avatar={user.avatar} border={user.border}/>
              <div className='match__lobby-player-info'>
                <div className='match__lobby-player-name'>{user.name}</div>
                <div className='match__lobby-player-online _active'>Playing the tournament</div>
              </div>
            </div>
            <div className='tournament-user__ranking'>
              <div className='tournament-user__ranking-wdl'>
                <div><span>W:</span>{score.win}</div>
                <div><span>D:</span>{score.draw}</div>
                <div><span>L:</span>{score.lose}</div>
              </div>
            </div>
            {!tournament.isComplete && (
              <Button color='secondary' onClick={this.onShowModalLeave}>
                Leave tournament
              </Button>
            )}
          </WrapperUser>

          <div className='tournament-user__matches'>
            {matches.current.length > 0 && (
              <div className='tournament-user__matches-group'>
                <div className='tournament-user__matches-group-name'>Current match</div>
                <div className='tournament-user__matches-group-list'>
                  <Match data={matches.current[0]} tournamentId={tournamentId} className='_round-robin'/>
                </div>
              </div>
            )}
            {matches.current.length === 0 && matches.next.length > 0 && (
              <div className='tournament-user__matches-group'>
                <div className='tournament-user__matches-group-name'>Next match</div>
                <div className='tournament-user__matches-group-list'>
                  <Match data={matches.next[0]} tournamentId={tournamentId} className='_round-robin'/>
                </div>
              </div>
            )}
            {matches.finished.length > 0 && (
              <div className='tournament-user__matches-group'>
                <div className='tournament-user__matches-group-name'>Finished matches</div>
                <div className='tournament-user__matches-group-list'>
                  {matches.finished.map((match, idx) => (
                    <Match key={idx} data={match} tournamentId={tournamentId} className='_round-robin'/>
                  ))}
                </div>
              </div>
            )}
          </div>
          <PlayerText> </PlayerText>
        </PlayerContainer>
      </PlayerBg>
    )
  }

  renderModalLeave() {
    const tournament = this.props.tournament.data
    return (
      <Modal open={this.state.showModalLeave} title={`Leave tournament "${tournament.name}"`}>
        <ModalText>Are you sure?</ModalText>
        <ModalButtonWrapper>
          <Button color='primary' onClick={this.onCloseModal}>no, i change my mind</Button>
          <Button color='light' data-force='1' onClick={this.onLeaveClick}>yes, leave tournament</Button>
        </ModalButtonWrapper>
      </Modal>
    )
  }

}

export const TournamentProfile = connect(mapState, mapDispatch)(CProfileCard)
