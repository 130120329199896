import React from 'react'
import { connect } from 'react-redux'
import { Avatar } from '../../avatar'
import { OrgAvatar } from '../../avatar/org-avatar'
import MaterialIcon from '@material/react-material-icon'
import { Tooltip } from 'reactstrap'
import { Bracket, Registration } from '../../../constants/tournament'
import moment from 'moment-timezone'
import { ProgressState } from '../../../constants/progress-state'
import { Link } from 'react-router-dom'
import { organization } from '../../routes'
import DiscordIco from '../../ico/discord'
import FacebookIco from '../../ico/fb'
import MailIco from '../../ico/mail'
import TwitterIco from '../../ico/tw'
import { ContactType } from '../../../constants/client'

const tz = moment.tz.guess()

const mapStateToProps = ({tournament}) => ({
  tournament,
})

class CTournamentPageDescription extends React.PureComponent {
  state = {
    discordTooltip: false,
  }

  getRegistration() {
    const {data} = this.props.tournament
    if (data.registration === Registration.Closed) {
      return 'closed'
    }
    return 'open till ' + moment(data.dateStart).tz(tz).format('MMMM D, HH:mm z')
  }

  getFormat() {
    const {data} = this.props.tournament
    switch (data.bracketType) {
      case Bracket.Single:
        return 'single elimination'
      case Bracket.Double:
        return 'double elimination'
      case Bracket.Swiss:
        return 'swiss'
      case Bracket.RoundRobin:
        return 'round robin'
      default:
        return ''
    }
  }

  getHint() {
    const {data} = this.props.tournament
    if (!data.pickAndBans) return null
    return (
      <div className='tournament-hint'>
        <MaterialIcon icon='error_outline'/>
        <div>Players should apply list of leaders they will be play till the check-in time</div>
      </div>
    )
  }

  getPicksAndBans() {
    const {data} = this.props.tournament
    if (!data.pickAndBans) return null
    return (
      <div>
        {data.picksCount} decks and {data.bansCount} ban
      </div>
    )
  }

  discordTooltipToggle = () => {
    this.setState({discordTooltip: !this.state.discordTooltip})
  }

  renderContacts(contacts = []) {
    return contacts.map((c, i) => {
        switch (c.type) {
          case ContactType.Email:
            return (
              <a key={i} href={'mailto:' + c.value}
                 className='tournament-host__contact'>
                <MailIco/>
              </a>
            )
          case ContactType.Facebook:
            return (
              <a key={i}
                 href={'https://www.facebook.com/' + c.value.replace('@', '')}
                 target='_blank'
                 className='tournament-host__contact'>
                <FacebookIco/>
              </a>
            )
          case ContactType.Twitter:
            return (
              <a key={i}
                 href={'https://twitter.com/' + c.value.replace('@', '')}
                 target='_blank'
                 className='tournament-host__contact'>
                <TwitterIco/>
              </a>
            )
          case ContactType.Discord:
            if (/^https?:/.test(c.value)) {
              return (
                <a key={i}
                   href={c.value}
                   target='_blank'
                   className='tournament-host__contact'>
                  <DiscordIco/>
                </a>
              )
            }
            return (
              <div className='tournament-host__contact' id='discord'>
                <DiscordIco/>
                <Tooltip placement='top'
                         isOpen={this.state.discordTooltip}
                         target='discord'
                         autohide={false}
                         toggle={this.discordTooltipToggle}>
                  {c.value}
                </Tooltip>
              </div>
            )
          default:
            return null
        }
      },
    )
  }

  renderWinners() {
    const {data:{winners = []}} = this.props.tournament
    if (winners.length === 0) return null

    if (winners.length === 1) {
      return (
        <div className='tournament-description__winner'>Winner: {winners[0].name}</div>
      )
    }

    return (
      <div className='tournament-description__winner'>Winners: {winners.map(p => p.name).join(', ')}</div>
    )
  }

  render() {
    const {data} = this.props.tournament
    if (this.props.tournament.state !== ProgressState.Complete) {
      return null
    }

    return (
      <div className='container'>
        <div className='tournament-description'>
          <div className='tournament-description__text'>
            {this.renderWinners()}
            <div dangerouslySetInnerHTML={{__html: data.description}}/>
          </div>
          <div className='tournament-info'>
            <div className='tournament-host'>
              <div className='tournament-host__title'>
                <div>
                  {data.organizationId ?
                    <OrgAvatar className='tournament-host__avatar' src={data.host.avatar}/> :
                    <Avatar className='tournament-host__avatar' avatar={data.host.avatar} border={data.host.border}/>
                  }
                </div>
                <div>
                  <div>Hosted by</div>
                  {data.organizationId ? (
                    <Link to={organization(data.host.id)} className='tournament-host__name'>{data.host.name}</Link>
                  ) : (
                    <a href='#' className='tournament-host__name'>{data.host.name}</a>
                  )}
                  {this.renderContacts(data.contacts)}
                </div>
              </div>
              {data.admins && data.admins.map(admin => (
                <div className='tournament-host__title' key={admin.id}>
                  <div>
                    <Avatar className='tournament-host__avatar' avatar={admin.avatar} border={admin.border}/>
                  </div>
                  <div>
                    <div>Admin</div>
                    <div className='tournament-host__name'>{admin.name}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className='tournament-details'>
              <div className='tournament-details__title'>Details</div>
              <div className='tournament-details__item'>
                <strong>Registration: </strong>{this.getRegistration()}
              </div>
              <div className='tournament-details__item'>
                <strong>Tournament format: </strong>{this.getFormat()}
              </div>
              <div className='tournament-details__item'>
                <strong>Games format: </strong>best of {data.bracketParams.matchFormat}
                {this.getPicksAndBans()}
              </div>
              <div className='tournament-details__item'>
                {data.playersCount} players joined
              </div>
              {this.getHint()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const TournamentPageDescription = connect(mapStateToProps)(CTournamentPageDescription)
