import React from 'react'
import {SingleElimination} from './single-elemination'
import {DoubleElimination} from './double-elemination'
import {RoundRobin} from './round-robin'
import {Swiss} from './swiss'
import { BRACKETS_RELOAD_INTERVAL } from '../../../../constants/timers'
import {getTournamentId} from "../../../../utils/tournament";

export class TournamentPageBracketsActive extends React.PureComponent {
	constructor(props) {
		super(props)
		props.load()
    this.refreshTimer = setInterval(this.refreshTimerTick, BRACKETS_RELOAD_INTERVAL)
	}

  componentWillUnmount() {
    clearInterval(this.refreshTimer)
  }

  refreshTimerTick = () => {
    this.props.load()
  }

	render() {
		const {data, games} = this.props.tournament
		const tournamentId = getTournamentId(this.props.tournament.data);
		switch (data.bracketType) {
			case 0:
				return <SingleElimination tournament={data}
								players={data.playersRealCount}
				                          data={games.data}
				                          third={data.bracketParams.third}
				                          matchFormat={data.bracketParams.matchFormat}
				                          finalMatchFormat={data.bracketParams.finalMatchFormat}
				                          tournamentId={tournamentId}
                                  manualStartRounds={data.manualStartRounds}
                                  spectators={data.spectators}
                                  roundsStarted={data.roundsStarted}
                                  isEditable={data.isEditable}
								  resetMatchHandler={this.props.resetMatchHandler}/>;
			case 1:
				return <DoubleElimination tournament={data}
                                  players={data.playersRealCount}
				                          data={games.data}
				                          matchFormat={data.bracketParams.matchFormat}
				                          finalMatchFormat={data.bracketParams.finalMatchFormat}
				                          tournamentId={tournamentId}
                                  manualStartRounds={data.manualStartRounds}
                                  spectators={data.spectators}
                                  roundsStarted={data.roundsStarted}
                                  isEditable={data.isEditable}
								  resetMatchHandler={this.props.resetMatchHandler}
								  />
			case 2:
				return <RoundRobin tournament={data}
                           players={data.playersCount}
				                   data={games.data}
				                   groups={data.bracketParams.groupsCount}
				                   playersInGroup={data.playersCountInGroup}
				                   matchFormat={data.bracketParams.matchFormat}
				                   tournamentId={tournamentId}
                           manualStartRounds={data.manualStartRounds}
                           spectators={data.spectators}
                           roundsStarted={data.roundsStarted}
                           isEditable={data.isEditable}/>
			case 3:
				return <Swiss tournament={data}
                      players={data.playersCount ? data.playersCount : 0}
				              data={games.data}
				              matchFormat={data.bracketParams.matchFormat}
				              tournamentId={tournamentId}
                      manualStartRounds={data.manualStartRounds}
                      spectators={data.spectators}
                      roundsStarted={data.roundsStarted}
                      isEditable={data.isEditable}/>
			default:
				return null
		}
	}
}
