import React from 'react'
import { LEADERS } from '../../../../constants/cards'
import Modal from '../../../atoms/modal'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { FilterButton } from '../../../tournament-search/styles'
import isEqual from 'lodash/isEqual'
import { DeckRegister } from '../../../../constants/tournament'

export class CoinFlipModal extends React.PureComponent {
  state = {
    playerGoingFirst: null,
  }

  constructor(props) {
    super(props)
    this.state.playerGoingFirst = props.playerGoingFirst
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.playerGoingFirst, this.props.playerGoingFirst)) {
      this.setState({playerGoingFirst: this.props.playerGoingFirst})
    }
  }

  onApply = () => {
    if (this.state.playerGoingFirst && this.props.onApply) {
      this.props.onApply(this.state.playerGoingFirst)
    }
    this.setState({playerGoingFirst: null})
  }

  onPlayerClick = (e) => {
    this.setState({playerGoingFirst: e.target.dataset.playerId})
  }

  render() {
    const {playerGoingFirst} = this.state
    const {players} = this.props
    if (!players || players.length === 0) return null
    return (
      <Modal open={true}
             height={215}
             width={308}
             top='100%'
             left='0%'
             title={`Edit coinflip`}
             opacity='0.87'>
        <strong>Who goes first in the first round?</strong>
        {players && players.map((player, key) => (
          <PlayerName data-player-id={player.id} key={key}
                      onClick={this.onPlayerClick}
                      active={player.id === playerGoingFirst}>
            {player.name}
          </PlayerName>
        ))}
        <ButtonWrapper>
          <Button color='primary' onClick={this.onApply}>apply</Button>
        </ButtonWrapper>
      </Modal>
    )
  }
}

const PlayerName = styled.div`
  border-radius: 14px;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  cursor: pointer;
	text-align: center;
	background: ${ props => props.active ? '#D7BE5E' : '#454545' };
	color: ${ props => props.active ? '#000000' : '#FFFFFF' };
`
const ButtonWrapper = styled.div`
	margin-top: 24px;
	> button {
		width: 100%;
	}
`
