import React from 'react'
import { connect } from 'react-redux'
import { Avatar } from '../../avatar'

import nochelead from '../../../assets/images/X.png'
import checkead from '../../../assets/Members-image/chekead.png'
import borderoneblock from '../../../assets/Members-image/block1border.png'
import X from '../../../assets/Members-image/Vector (14).png'
import { Button } from 'reactstrap'

class CMembers extends React.PureComponent {
  state = {
    adminSearch: '',
    ownerSearch: '',
  }

  onAdminChange = (e) => {
    const value = e.target.value
    this.setState({adminSearch: value})
    this.props.resetSearch()
    this.props.search(value)
  }

  onAdminSelect = (e) => {
    this.setState({adminSearch: ''})
    this.props.resetSearch()
    const {data} = this.props.organization
    if (data.id && e.target.dataset.userId) {
      this.props.adminAdd({id: data.id, userId: e.target.dataset.userId})
    }
  }

  onAdminRemove = (e) => {
    const {data} = this.props.organization
    this.props.adminRemove({id: data.id, userId: e.target.dataset.userId})
  }

  onAdminRemoveAll = () => {
    const {data} = this.props.organization
    this.props.adminRemoveAll({id: data.id})
  }

  onOwnerChange = (e) => {
    const value = e.target.value
    this.setState({ownerSearch: value})
    this.props.resetSearch()
    this.props.search(value)
  }

  onOwnerSelect = (e) => {
    this.setState({ownerSearch: ''})
    this.props.resetSearch()
    const {data} = this.props.organization
    if (data.id && e.target.dataset.userId) {
      this.props.ownerChange({id: data.id, userId: e.target.dataset.userId})
    }
  }

  render() {
    const {data: {members = [], isEditable = false, owner = {}}} = this.props.organization
    return isEditable ? this.renderOwner(members) : this.renderOther(members, owner)
  }

  renderOther(members, owner) {
    return (
      <div className='organization-page__members'>
        <div className='organization-page__members-list'>
          <div className='organization-page__members-head'>Admins</div>
          {members.map((user, idx) => (
            <div className='organization__member-item' key={idx}>
              <Avatar className='organization__member-avatar' avatar={user.avatar} border={user.border}/>
              {user.name}
            </div>
          ))}
        </div>
        <div className='organization-page__members-owner'>
          <div className='organization-page__members-head'>Owner</div>
          <div className='organization__member-item'>
            <Avatar className='organization__member-avatar' avatar={owner.avatar} border={owner.border}/>
            {owner.name}
          </div>
        </div>
      </div>
    )
  }

  renderOwner(members) {
    const {adminSearch, ownerSearch} = this.state
    const search = this.props.user.search.data
    return (
      <div className='organization__members-main'>
        <div className='organization__members-block'>
          <div className='organization__members-block-head'>
            <div className='organization__members-block-header'>
              <h3>Admins</h3>
              <p>Can create, edit and manage tournaments
                under Organization’s name. They cannot edit or delete
                organization, cannot add or delete other admins.</p>
            </div>
            <div className='create__user_daa'>
              <p>Username</p>
              <input type='text' placeholder='Type username to add' value={adminSearch}
                     onChange={this.onAdminChange}/>
              {search.length > 0 && adminSearch && (
                <div className='user-search__list'>
                  {search.map(
                    u => <div className='user-search__list-item' key={u.id} data-user-id={u.id}
                              data-user-name={u.name}
                              onClick={this.onAdminSelect}>
                      {u.name}
                    </div>)}
                </div>
              )}
            </div>
          </div>
          {members.map((user, idx) => (
            <div className='user_avatar_blo' key={idx}>
              <div><Avatar className='organization__member-avatar' avatar={user.avatar} border={user.border}/></div>
              <p>{user.name}</p>
              <img src={X} alt='' className='organization__member-remove'
                   data-user-id={user.id}
                   onClick={this.onAdminRemove}/>
            </div>
          ))}
          {members.length > 1 && (
            <div className='organization__members-block-footer_button'>
              <Button color='light' onClick={this.onAdminRemoveAll}>DELETE ALL ADMINS</Button>
            </div>
          )}
        </div>
        <div className='owner__block'>
          <div className='owner__block_title'>
            <h3>Owner</h3>
            <p>That’s you. Owner created the organization,
              can edit it and delete, can add and delete
              admins. Owner can organize tournaments under
              Organization’s name.<br/><br/>
              There can be only one owner per organization, so
              if you want to pass this role to someone
              else, think twice, this is irreversible operation.</p>
          </div>

          <div className='onwer__input_block'>
            <p>Username</p>
            <input type='text' placeholder='Type username to add' value={ownerSearch} onChange={this.onOwnerChange}/>
            {search.length > 0 && ownerSearch && (
              <div className='user-search__list'>
                {search.map(
                  u => <div className='user-search__list-item' key={u.id} data-user-id={u.id}
                            data-user-name={u.name}
                            onClick={this.onOwnerSelect}>
                    {u.name}
                  </div>)}
              </div>
            )}
          </div>
        </div>
        <div className='about__block'>
          <div className='about__title'>
            <h2>ABOUT ROLES</h2>
          </div>
          <div className='about__table_titles'>
            <h3>what can do</h3>
            <div>
              <h3>Owners</h3>
              <h3>Admins</h3>
            </div>
          </div>
          <div className='what__can_block'>
            <div className='wat__can_text'>
              <p>
                create organization edit organization delete organization <br/><br/>
                add admins and owners delete admins block users <br/><br/>
                create tournaments edit tournaments delete tournaments manage players
              </p>
            </div>
            <div className='chekit__or_not'>
              <div className='wat__can_checkead'>
                <div>
                  <img src={checkead} alt=''/>
                  <img src={checkead} alt=''/>
                  <img src={checkead} alt=''/>
                </div>
                <div>
                  <img src={checkead} alt=''/>
                  <img src={checkead} alt=''/>
                  <img src={checkead} alt=''/>
                </div>
                <div>
                  <img src={checkead} alt=''/>
                  <img src={checkead} alt=''/>
                  <img src={checkead} alt=''/>
                  <img src={checkead} alt=''/>
                </div>
              </div>
              <div className='wat__can_no_checkead'>
                <div>
                  <img className='no_chek' src={nochelead} alt=''/>
                  <img className='no_chek' src={nochelead} alt=''/>
                  <img className='no_chek' src={nochelead} alt=''/>
                </div>
                <div>
                  <img className='no_chek' src={nochelead} alt=''/>
                  <img className='no_chek' src={nochelead} alt=''/>
                  <img className='no_chek' src={checkead} alt=''/>

                </div>
                <div>
                  <img className='no_chek' src={checkead} alt=''/>
                  <img className='no_chek' src={checkead} alt=''/>
                  <img className='no_chek' src={checkead} alt=''/>
                  <img className='no_chek' src={checkead} alt=''/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = ({organization, user}) => ({
  organization,
  user,
})
const mapDispatch = ({organization, user}) => ({
    adminAdd: organization.adminAdd,
    adminRemove: organization.adminRemove,
    adminRemoveAll: organization.adminRemoveAll,
    ownerChange: organization.ownerChange,
    search: user.search,
    resetSearch: user.resetSearch,
  }
)

export const Members = connect(mapState, mapDispatch)(CMembers)
