import React, { Component } from 'react'
import styled from 'styled-components'
import { Input as OldInput, Label as OldLabel } from 'reactstrap'
import DiscordIco from '../../ico/discord'
import FacebookIco from '../../ico/fb'
import MailIco from '../../ico/mail'
import TwitterIco from '../../ico/tw'
import Time from '../../ico/time'
import Date from '../../ico/date'
import Search from '../../ico/search'

const iconLib = {
  discord: <DiscordIco/>,
  twitter: <TwitterIco/>,
  facebook: <FacebookIco/>,
  email: <MailIco/>,
  time: <Time/>,
  date: <Date/>,
  search: <Search/>,
}

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focus: !!(this.props.value && this.props.value.length !== 0),
      error: true,
    }
  }

  render() {
    // const { focus, error } = this.state;
    const {
      name,
      placeholder = false,
      value,
      disabled,
      type = 'input',
      style,
      title,
      descriptor,
      styleDescriptor,
      styleTitle,
      errorText,
      autoComplete = 'off',
      icon,
      // className
      ...otherProps
    } = this.props

    return (
      <Label htmlFor={name}>
        {title && <Title style={styleTitle}>{title}</Title>}
        <ContainerInput>
          <StyledInput type={type}
                       autoComplete={autoComplete}
                       style={style}
                       icon={icon}
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onChange={this.onChange}
                       placeholder={placeholder}
                       value={value}
                       name={name}
                       id={name}
                       disabled={disabled}
                       {...otherProps}/>
          {icon && <Icon icon={icon}>{iconLib[icon]}</Icon>}
        </ContainerInput>
        {descriptor && <Descriptor style={styleDescriptor}>{descriptor}</Descriptor>}
        {errorText && <InputError>{errorText}</InputError> }
      </Label>
    )
  }

  onFocus = (event) => {
    this.setState({focus: true, error: true})
    if (this.props.onFocus) {
      this.props.onFocus(event, this.props.name)
    }
  }

  onBlur = (event) => {
    let value = event.target.value

    if (value === '') {
      this.setState({focus: false})
    }

    if (this.props.validate && value !== '') {
      this.setState({error: this.props.validate(value)})
    }

    if (this.props.onBlur) {
      this.props.onBlur(event, this.props.name)
    }
  }

  onChange = (event) => {
    let value = event.target.value

    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }
}

export default Index

const StyledInput = styled(OldInput)`
  font-size: 14px;
  background: #131313;
	border: 1px solid #949494;
	height: 30px;
	box-sizing: border-box;
	border-radius: 2px;
  color: #FFFFFF;
  width: 100%;
  -webkit-appearance: none;
	position: relative;
	z-index: 2;
	outline: none;
	padding-left: ${ props => props.icon ? '41px' : '11px' };
	padding-right: ${ props => props.icon === 'date' && 0 };

	&:focus {
		border-color: #949494;
		color: #FFFFFF;
	}
	::-webkit-input-placeholder {
    	color: #555555;
  }
  ::-moz-placeholder {
      color: #555555;
  }
  :-ms-input-placeholder {
      color: #555555;
  }
  :-moz-placeholder {
      color: #555555;
  }
  ${props => props.style}
`

const Label = styled.label`
	position: relative;
	display: block;
	width: 100%;
`

const Title = styled(OldLabel)`
	margin-bottom: 5px;
`

const Icon = styled.div`
  position: absolute;
  top: 50%;
	transform: translateY(-50%);
	left: 8px;
	z-index: 2;
  display: flex;
`;

const Descriptor = styled.div`
	font-size: 12px;
	color: #645e80;
	@media(max-width: 1366px), (max-height: 900px) {
		font-size: 10px;
	}
`

const ContainerInput = styled.div`
	position: relative;
`

const InputError = styled.span`
	position: absolute;
	right: 0;
	font-size: 12px;
	top: 0px;
	color: #FC0000;
`;
