import styled from "styled-components";

export const TitleWrapper = styled.div`
	margin-bottom: 11px;
`;

export const TextDropZone = styled.div`
	font-size: 12px;
	letter-spacing: 0.06em;
	text-transform: uppercase;
	margin-bottom: 17px;
`