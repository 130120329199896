import React, { PureComponent } from 'react'
import '../assets/bootstrap/bootstrap.scss'
import { Route, Switch, withRouter } from 'react-router-dom'
import { store } from '../store'
import { history } from '../store/history'
import { Provider, connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import * as ROUTES from './routes'
import { MainPage } from './pages/main'
import { NotFoundPage } from './pages/not-found'
import { AboutPage } from './pages/about'
import { LoginPage } from './pages/login'
import { TournamentPage } from './pages/tournament'
import { TournamentEditPage } from './pages/tournament-edit'
import { OrganizationEditPage } from './pages/organization-edit'
import { OrganizationPage } from './pages/organization'
import ProfileSettings from './pages/profile-settings'
import Organizing from './pages/organizing'
import { PlayingPage } from './pages/organizing/playing'
import { SuperAdmin } from './pages/super-admin'
import { Organizations } from './pages/my-organizations'
import '../assets/index.css'

const mapDispatch = ({user}) => ({
  init: () => user.init(),
  resetSearch: user.resetSearch,
})

class App extends PureComponent {
  componentDidMount() {
    this.props.init()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.resetSearch()
    }
  }

  render() {
    return (
      <Switch>
        <Route exact path='/' component={MainPage}/>
        <Route path={ROUTES.ABOUT} component={AboutPage}/>
        <Route path={ROUTES.LOGIN} component={LoginPage}/>
        <Route path={ROUTES.TOURNAMENT_CREATE} component={TournamentEditPage}/>
        <Route path={ROUTES.tournamentEdit()} component={TournamentEditPage}/>
        <Route path={ROUTES.tournament()} component={TournamentPage}/>
        <Route path={ROUTES.ORGANIZATION_CREATE} component={OrganizationEditPage}/>
        <Route path={ROUTES.organizationEdit()} component={OrganizationEditPage}/>
        <Route path={ROUTES.organization()} component={OrganizationPage}/>
        <Route path={ROUTES.PROFILE_SETTINGS} component={ProfileSettings}/>
        <Route path={ROUTES.MY_TOURNAMENTS} exact component={PlayingPage}/>
        <Route path={ROUTES.MY_TOURNAMENTS_ORGANIZING} component={Organizing}/>
        <Route path={ROUTES.MY_ORGANISATION} component={Organizations}/>
        <Route path={ROUTES.SUPER_ADMIN} component={SuperAdmin}/>
        <Route path='*' component={NotFoundPage}/>
      </Switch>
    )
  }
}

export const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {React.createElement(withRouter(connect(null, mapDispatch)(App)))}
    </ConnectedRouter>
  </Provider>
)
