import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Button, Col, Row, Table } from 'reactstrap'
import { Avatar } from '../../avatar'
import MaterialIcon from '@material/react-material-icon'
import { Hint } from '../../hint'
import EditImg from './../../ico/edit'
import { MatchPickAndBans, MatchState } from '../../../constants/match'
import { ProgressState } from '../../../constants/progress-state'
import { BANS_RELOAD_INTERVAL, MATCH_RELOAD_INTERVAL } from '../../../constants/timers'
import { GameModal } from './match/GameModal'
import { ProblemModal } from './match/ProblemModal'
import isEqual from 'lodash/isEqual'
import { CoinFlipModal } from './match/CoinFlipModal'
import moment from 'moment-timezone'
import {getTournamentId} from "../../../utils/tournament";

const tz = moment.tz.guess()

const mapStateToProps = ({tournament, pickAndBan, user, tMatch}) => ({
  currentMatch: tMatch.current,
  tournament: tournament.data,
  pickAndBan,
  user,
})
const mapDispatchToProps = ({tournament, pickAndBan, tMatch}) => ({
  loadMatch: tMatch.load,
  resetMatch: tMatch.reset,
  reportProblem: tMatch.reportProblem,
  setPlayerGoingFirst: tMatch.setPlayerGoingFirst,
  gameEdit: tMatch.gameEdit,
  getBan: pickAndBan.get,
  ban: pickAndBan.ban,
})

class CTournamentPageMatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      pickAndBanCycle: false,
      selectedDeck: '',
      game: null,
      problemModalOpen: false,
      coinFlipModalOpen: false,
    }
    props.loadMatch({id: props.match.params.id, matchId: props.match.params.matchId})
    this.pickAndBanTimer = null
    this.refreshTimer = setInterval(this.refreshTimerTick, MATCH_RELOAD_INTERVAL)
  }

  componentDidUpdate(prevProps) {
    this.tryStopPickAndBan()
    this.tryStartPickAndBan()
    if (prevProps.match.params.matchId !== this.props.match.params.matchId) {
      this.props.loadMatch({id: this.props.match.params.id, matchId: this.props.match.params.matchId})
    }
  }

  componentWillUnmount() {
    clearInterval(this.pickAndBanTimer)
    clearInterval(this.refreshTimer)
  }

  refreshTimerTick = () => {
    const {pickAndBanCycle} = this.state
    if (pickAndBanCycle) return
    this.props.loadMatch({id: this.props.match.params.id, matchId: this.props.match.params.matchId, reload: true})
  }

  tryStartPickAndBan() {
    const {pickAndBanCycle} = this.state
    if (pickAndBanCycle) return

    const {data: user} = this.props.user
    if (!user.id) return

    const {data: {players = [], state: matchState}} = this.props.currentMatch
    if (players.length < 2 || matchState === MatchState.STATE_CREATED || matchState === MatchState.STATE_FINISHED) {
      return
    }

    if (players[0].id !== user.id && players[1].id !== user.id) {
      return
    }

    const {data: {status}} = this.props.pickAndBan
    if (status === MatchPickAndBans.PICK_AND_BAN_FINISH || status === MatchPickAndBans.PICK_AND_BAN_DISABLED) {
      return
    }

    this.setState({pickAndBanCycle: true}, () => {
      // console.log('start')
      this.props.getBan(this.props.currentMatch.data.id)
      this.pickAndBanTimer = setInterval(this.onPickAndBanTick, BANS_RELOAD_INTERVAL)
    })
  }

  tryStopPickAndBan() {
    const {pickAndBanCycle} = this.state
    if (!pickAndBanCycle) return
    const {data: {status}} = this.props.pickAndBan
    if (status === MatchPickAndBans.PICK_AND_BAN_FINISH || status === MatchPickAndBans.PICK_AND_BAN_DISABLED) {
      clearInterval(this.pickAndBanTimer)
      this.setState({pickAndBanCycle: false})
    }
  }

  onPickAndBanTick = () => {
    const {stateLoad} = this.props.pickAndBan
    if (stateLoad === ProgressState.InProgress) return
    // console.log('tick')
    this.props.getBan(this.props.currentMatch.data.id)
  }

  handlerResultOpen = (event) => {
    const elem = event.currentTarget
    const box = elem.getBoundingClientRect()
    const top = box.top - 270
    const gameId = Number(event.currentTarget.dataset.gameid)
    const game = this.props.currentMatch.data.games.find(g => g.id === gameId)
    if (!game) return
    this.setState({topElement: top, game})
  }

  onApply = (game) => {
    this.props.gameEdit({
      tournamentId: getTournamentId(this.props.tournament),
      matchId: this.props.currentMatch.data.id,
      game,
    })
    this.setState({game: null})
  }

  onProblemClick = () => {
    this.setState({problemModalOpen: true})
  }

  onProblemClose = () => {
    this.setState({problemModalOpen: false})
  }

  onProblemApply = (message) => {
    if (message) {
      this.props.reportProblem({
        tournamentId: getTournamentId(this.props.tournament),
        matchId: this.props.currentMatch.data.id,
        message,
      })
    }
    this.setState({problemModalOpen: false})
  }

  onCoinFlipClick = () => {
    this.setState({coinFlipModalOpen: true})
  }

  onCoinFlipApply = (playerId) => {
    this.props.setPlayerGoingFirst({
      tournamentId: getTournamentId(this.props.tournament),
      matchId: this.props.currentMatch.data.id,
      playerId,
    })
    this.setState({coinFlipModalOpen: false})
  }

  onDeckClick = (e) => {
    const {pickAndBanCycle} = this.state
    if (!pickAndBanCycle) return
    const {data: user} = this.props.user
    if (!user.id) return
    const {data: {players: pickAndBanPlayers = []}} = this.props.pickAndBan
    const {bansCount} = this.props.tournament

    const pickAndBanPlayer = pickAndBanPlayers.find(p => p.id === user.id)
    if (!pickAndBanPlayer || pickAndBanPlayer.ban.length === bansCount) return null

    const deckId = Number(e.currentTarget.dataset.deckid)
    const {data: {players = []}} = this.props.currentMatch
    const player = players.find(p => p.decks.some(d => d.id === deckId))
    if (!player || player.id === user.id) return

    this.setState({selectedDeck: deckId})
  }

  onBanClick = () => {
    const {selectedDeck, pickAndBanCycle} = this.state
    if (!selectedDeck || !pickAndBanCycle) return
    this.props.ban({deckId: selectedDeck, matchId: this.props.currentMatch.data.id})
    this.setState({selectedDeck: ''})
  }

  render() {
    return (
      <div className='container'>
        <Row className='tournament-match'>
          <Col lg={8}>
            {this.renderLobby()}
          </Col>
          <Col lg={4}>
            {this.renderSidebar()}
          </Col>
        </Row>
      </div>
    )
  }

  renderPlayerActions(player, otherPlayer) {
    if (!player || !otherPlayer) return null
    const {data: user} = this.props.user
    if (!user.id) return null
    if (player.id !== user.id && otherPlayer.id !== user.id) return null
    const {pickAndBans, bansCount} = this.props.tournament
    if (!pickAndBans) return null
    const {pickAndBanCycle} = this.state
    const {state, data: {players = [], dateEnd}} = this.props.pickAndBan
    if (player.id === user.id) {
      if (pickAndBanCycle) {
        return null
      }
      return (
        <div>Your decks to play</div>
      )
    } else {
      if (pickAndBanCycle) {
        const pickAndBanPlayer = players.find(p => p.id === user.id)
        if (!pickAndBanPlayer || pickAndBanPlayer.ban.length === bansCount) return null
        let timer = null
        if (dateEnd) {
          let date = moment(dateEnd).tz(tz)
          let now = moment()
          let minutes = Math.abs(now.diff(date, 'minutes'))
          let seconds = Math.abs(now.diff(date, 'seconds')) - minutes * 60
          if (seconds < 10) seconds = '0' + seconds
          timer = (
            <span className='match__lobby-actions-timer'>
              <MaterialIcon icon='schedule'/> {minutes}:{seconds}
            </span>
          )
        }
        return (
          <Button color='primary' onClick={this.onBanClick} disabled={state === ProgressState.InProgress}>
            {timer} BAN {bansCount} OPPONENT'S LEADER{`${bansCount > 1 ? 'S' : ''}`}
          </Button>
        )
      }
      return (
        <div>Opponent's decks to play</div>
      )
    }
  }

  isBanned(deckId, playerId) {
    const {data: {players: matchPlayers = []}} = this.props.currentMatch
    const {data: {players = []}} = this.props.pickAndBan
    const result = matchPlayers.concat(players)
                               .filter(p => p.id === playerId)
                               .some(p => p.ban.includes(deckId + '') || p.ban.includes(deckId))
    return result || deckId === this.state.selectedDeck
  }

  renderLobby() {
    const {data} = this.props.currentMatch
    const {pickAndBans, bansCount, isEditable} = this.props.tournament
    const {pickAndBanCycle} = this.state
    if (!data) {
      return (
        <div className='match__lobby'/>
      )
    }
    const player1 = data.players && data.players.length > 0 ? data.players[0] : {}
    const player2 = data.players && data.players.length > 1 ? data.players[1] : {}
    let score1 = 0
    let score2 = 0
    if (data.score && data.score.length > 0 && data.score[0]) {
      score1 = data.score[0]
    }
    if (data.score && data.score.length > 1 && data.score[1]) {
      score2 = data.score[1]
    }
    return (
      <div className='match__lobby'>
        {this.renderModal()}
        {this.renderProblemModal()}

        <div className='match__lobby-players'>
          <div className='match__lobby-player'>
            <Avatar className='match__lobby-avatar' avatar={player1.avatar} border={player1.border}/>
            <div className='match__lobby-player-info'>
              <div className='match__lobby-player-name'>{player1.name}</div>
              <div className='match__lobby-player-online _active'/>
            </div>
          </div>


          <div className='match__lobby-player'>
            <div className='match__lobby-player-info'>
              <div className='match__lobby-player-name'>{player2.name}</div>
              <div className='match__lobby-player-online'/>
            </div>
            <Avatar className='match__lobby-avatar' avatar={player2.avatar} border={player2.border}/>
          </div>
        </div>

        <div className='match__lobby-decks'>
          <div className='match__lobby-leaders-wrap'>
            <div className='match__lobby-leaders'>
              {player1.decks && player1.decks.map(deck => (
                <div title={deck.name} key={deck.id}
                     data-deckid={deck.id}
                     onClick={this.onDeckClick}
                     className={`match__lobby-leader leader-ability _${deck.leaderId} ${this.isBanned(deck.id,
                       player2.id) ? '_ban' : ''}`}>
                  <MaterialIcon icon='close'/>
                  <div className='match__lobby-ban'>Banned</div>
                </div>
              ))}
            </div>
            <div className='match__lobby-actions'>
              {this.renderPlayerActions(player1, player2)}
            </div>
          </div>

          <div className='match__lobby-match-score'>
            <div>Match score</div>
            <div className='match__lobby-match-score-numbers'>
              <div className={`match__lobby-match-score-number ${score1 > score2 ? '_win' : ''}`}>
                {score1}
              </div>
              <div className={`match__lobby-match-score-number ${score1 < score2 ? '_win' : ''}`}>
                {score2}
              </div>
            </div>
          </div>

          <div className='match__lobby-leaders-wrap'>
            <div className='match__lobby-leaders _second'>
              {player2.decks && player2.decks.map(deck => (
                <div title={deck.name} key={deck.id}
                     data-deckid={deck.id}
                     onClick={this.onDeckClick}
                     className={`match__lobby-leader leader-ability _${deck.leaderId} ${this.isBanned(deck.id,
                       player1.id) ? '_ban' : ''}`}>
                  <MaterialIcon icon='close'/>
                  <div className='match__lobby-ban'>Banned</div>
                </div>
              ))}
            </div>
            <div className='match__lobby-actions _second'>
              {this.renderPlayerActions(player2, player1)}
            </div>
          </div>
        </div>
        {pickAndBans && pickAndBanCycle && (
          <Hint className='mb-4'>
            This is the tournament with picks and bans. Please, ban {bansCount} opponent’s leader you don’t want to play
            against.
            Bans will be revealed at once when both players will make their choice. If one of the players
            doesn’t make a
            ban in the set amount of time, this player will get a technical loss.
          </Hint>
        )}
        <h3 className='text-center mb-2'>Match details</h3>

        {isEditable && data.state === MatchState.STATE_NONE && (
          <div className='match__lobby-coinflip-wrapper'>
            <Button color='light' onClick={this.onCoinFlipClick}>Edit coinflip</Button>
            {this.renderCoinFlipModal()}
          </div>
        )}
        {this.renderDetails(data.games || [], player1, player2, score1, score2, isEditable)}
      </div>
    )
  }

  renderModal() {
    const {data} = this.props.currentMatch
    const {pickAndBans, isEditable, deckRegister} = this.props.tournament
    const {game} = this.state
    if (!isEditable) return null

    return <GameModal game={game}
                      players={data.players}
                      onApply={this.onApply}
                      pickAndBans={pickAndBans}
                      deckRegister={deckRegister}/>
  }

  renderCoinFlipModal() {
    if (!this.state.coinFlipModalOpen) return null
    const {data} = this.props.currentMatch
    return <CoinFlipModal onApply={this.onCoinFlipApply}
                          players={data.players}
                          playerGoingFirst={data.playerGoingFirst}/>
  }

  renderProblemModal() {
    if (!this.state.problemModalOpen) return null
    return <ProblemModal onApply={this.onProblemApply} onClose={this.onProblemClose}/>
  }

  renderDetails(games, player1, player2, score1, score2, isEditable) {
    return (
      <Table className='match__details' responsive>
        <thead>
        <tr>
          <th colSpan={4} className={`match__detail-name ${score1 > score2 ? '_win' : ''} _1`}>{player1.name}</th>
          <th colSpan={4} className={`match__detail-name ${score1 < score2 ? '_win' : ''} _2`}>{player2.name}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td className='match__detail-col'>Score</td>
          <td colSpan={3} className={`match__detail-score _1 ${score1 > score2 ? '_win' : ''} _1}`}>{score1}</td>
          <td colSpan={3} className={`match__detail-score _2 ${score1 < score2 ? '_win' : ''} _1}`}>{score2}</td>
          <td className='match__detail-col'/>
        </tr>
        {games && games.map(game => this.renderGame(game, isEditable))}
        </tbody>
      </Table>
    )
  }

  renderGame(game, isEditable) {
    const {data} = this.props.currentMatch
    let result1 = game.result === 1 ? 'win' : (game.result === -1 ? 'lose' : (game.result === 0 ? 'draw' : ''))
    let result2 = game.result === -1 ? 'win' : (game.result === 1 ? 'lose' : (game.result === 0 ? 'draw' : ''))
    let leader1 = `match__detail-leader-avatar leader-ability ` +
      `_${game.deck1 ? game.deck1.leaderId : game.leader1} _${result1}`
    let leader2 = `match__detail-leader-avatar leader-ability ` +
      `_${game.deck2 ? game.deck2.leaderId : game.leader2} _${result2}`
    const showCoin = data.players.length > 1 && data.playerGoingFirst &&
      (isEditable || [MatchState.STATE_CREATED, MatchState.STATE_FINISHED].includes(data.state))
    let coin1 = [data.players.length > 1 && data.playerGoingFirst === data.players[0].id]
    coin1[1] = !coin1[0]
    let coin2 = [!coin1[0], !coin1[1]]

    return (
      <tr key={game.ordinal}>
        <td className='match__detail-col'>
          <ScoreWrapper>
            Game {game.ordinal + 1}
            {isEditable && (
              <ResultOpen onClick={this.handlerResultOpen} data-gameid={game.id}>
                <EditImg/>
              </ResultOpen>
            )}
          </ScoreWrapper>
        </td>
        <td className='match__detail-coin _1'>
          {showCoin && (
            <div className={`match__detail-coin-item _${coin1[game.ordinal % 2] ? 'c1' : 'c2'}`}>
              {coin1[game.ordinal % 2] ? '1' : '2'}
            </div>
          )}
        </td>
        <td className={`match__detail-result _1 _${result1}`}>{result1}</td>
        <td className='match__detail-leader'>
          <div className={leader1}/>
        </td>
        <td className='match__detail-leader'>
          <div className={leader2}/>
        </td>
        <td className='match__detail-result _2'>{result2}</td>
        <td className='match__detail-coin _2'>
          {showCoin && (
            <div className={`match__detail-coin-item _${coin2[game.ordinal % 2] ? 'c1' : 'c2'}`}>
              {coin2[game.ordinal % 2] ? '1' : '2'}
            </div>
          )}
        </td>
        <td className='match__detail-col'/>
      </tr>
    )
  }

  renderSidebar() {
    return (
      <div className='match__sidebar'>
        <p className='mb-3'>
          In case of any problems, game bugs or disputs with opponent, please, report to admins.
        </p>
        <Button color='primary' className='mb-3' onClick={this.onProblemClick}>Report a problem</Button>
        {/*<h3>Match logs</h3>
        <div className='match__logs'>
          <div className='match__logs-time'>1 minute ago</div>
          <div className='match__logs-item'>
            <a href='#'>Twentysymbolsusername</a> has joined the lobby
          </div>
        </div>
        <div className='match__logs'>
          <div className='match__logs-time'>2 minute ago</div>
          <div className='match__logs-item'>
            <a href='#'>Twentysymbolsusername</a> has joined the lobby
          </div>
        </div>*/}
      </div>
    )
  }

}

export const TournamentPageMatch = connect(mapStateToProps, mapDispatchToProps)(CTournamentPageMatch)

const ResultOpen = styled.div`
   margin-left: 15px;
   cursor: pointer;
`

const ScoreWrapper = styled.div`
  display: flex;
`


