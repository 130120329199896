import { ProgressState } from '../constants/progress-state'
import { MY_ORGANISATION } from '../components/routes'
import { get, post } from '../utils/fetch-api'

export const organization = {
  state: {
    data: {},
    state: ProgressState.NotStarted,
    error: 0,
    my: [],
    edit: {
      state: ProgressState.NotStarted,
      error: 0,
    },
  },
  reducers: {
    startLoad(state) {
      return {...state, data: {}, state: ProgressState.InProgress, error: 0}
    },
    errorLoad(state, error) {
      return {...state, state: ProgressState.Complete, error: error}
    },
    successLoad(state, data) {
      return {...state, data, state: ProgressState.Complete, error: 0}
    },

    startLoadMy(state) {
      return {...state, my: [], state: ProgressState.InProgress, error: 0}
    },
    errorLoadMy(state, error) {
      return {...state, state: ProgressState.Complete, error: error}
    },
    successLoadMy(state, my) {
      return {...state, my, state: ProgressState.Complete, error: 0}
    },

    startEdit(state) {
      return {
        ...state,
        edit: {state: ProgressState.InProgress, error: 0},
      }
    },
    errorEdit(state, error) {
      return {
        ...state,
        edit: {state: ProgressState.Complete, error: error},
      }
    },
    successEdit(state, data) {
      return {
        ...state,
        data,
        edit: {state: ProgressState.Complete, error: 0},
      }
    },
    resetEdit(state) {
      return {
        ...state,
        edit: {state: ProgressState.NotStarted, error: 0},
      }
    },
  },
  effects: {
    async load(id) {
      this.startLoad();
      const response = await get('/api/organization/' + id)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async loadMy() {
      this.startLoad();
      const response = await get('/api/organization/my')
      if (response.ok) {
        this.successLoadMy(response.body)
      } else {
        this.errorLoadMy(response.status)
      }
    },

    /**
     * @param {{data:{},newAvatar:File|0}} payload
     */
    async edit(payload) {
      this.startEdit()
      let url = payload.data.id ? `/api/organization/${payload.data.id}/edit` : '/api/organization/new'
      const formData = new FormData();
      if (payload.newAvatar) {
        formData.append('avatar', payload.newAvatar)
      }
      for (let [key, val] of Object.entries(payload.data)) {
        if (payload.newAvatar && key === 'avatar') continue;
        formData.append(key, val)
      }
      const response = await post(url, formData);
      if (response.ok) {
        this.successEdit(response.body)
      } else {
        this.errorEdit(response.status)
      }
    },

    async remove(id) {
      this.startEdit()
      const response = await get(`/api/organization/${id}/remove`)
      if (response.ok) {
        document.location.href = MY_ORGANISATION
      } else {
        this.errorEdit(response.status)
      }
    },


    async adminAdd({id, userId}) {
      const response = await get(`/api/organization/${id}/admin-add/${userId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },


    async adminRemove({id, userId}) {
      const response = await get(`/api/organization/${id}/admin-remove/${userId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },


    async adminRemoveAll({id}) {
      const response = await get(`/api/organization/${id}/admin-remove-all`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async leave(id) {
      this.startEdit()
      const response = await get(`/api/organization/${id}/leave`)
      if (response.ok) {
        document.location.reload()
      } else {
        this.errorEdit(response.status)
      }
    },


    async ownerChange({id, userId}) {
      const response = await get(`/api/organization/${id}/owner-change/${userId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },
  },
}
