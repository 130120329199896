export function getRoundSpectators(spectators, matches = []) {
  let assigned = []
  let assignedSpectatorsId = []
  for (let match of matches) {
    if (!match || !match.spectators) continue
    for (let spectator of match.spectators) {
      assigned.push({spectator, match: {id: match.id}})
      assignedSpectatorsId.push(spectator.id)
    }
  }
  let available = spectators.filter(s => !assignedSpectatorsId.includes(s.id))

  return {assigned, available}
}
