import React from 'react';
import {ModalContainer, ModalTitle} from "./styles";

const Modal = (props) => {
	const { children, title } = props;
	return (
		<ModalContainer {...props}>
			{ title && <ModalTitle>{title}</ModalTitle> }
			{children}
		</ModalContainer>
	)
};

export default Modal;