

const defaultHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};



function createHeaders(headers) {
  let head = {...defaultHeaders};
  if (headers) {
    head = {...head, ...headers}
  }

  return head
}

async function createResponse(response) {
  const body = await response.json();

  return {
    ok: response.ok,
    status: response.status,
    body,
    originalResponse: response,
  }
}

export async function get(url, headers = null) {
  const response = await fetch(url, {headers: createHeaders(headers)});

  return await createResponse(response)
}

export async function post(url, body, headers = null) {
  const head = createHeaders(headers);
  if (body instanceof FormData) {
    delete head['Content-Type']
  }
  const response = await fetch(url, {
    method: 'post',
    headers: head,
    body: head['Content-Type'] === 'application/json' ? JSON.stringify(body) : body,
  })

  return await createResponse(response)
}

export async function del(url, headers = null) {
  const response = await fetch(url, {
    headers: createHeaders(headers),
    method: 'delete'
  });

  return await createResponse(response)
}
