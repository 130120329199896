import React from 'react'
import memoize from 'lodash/memoize'

const IMAGES = [
  '000.png',
  '001.jpg',
  '002.jpg',
  '003.jpg',
  '004.jpg',
  '005.jpg',
  '006.jpg',
  '007.jpg',
  '008.jpg',
  '009.jpg',
  '010.jpg',
]

export class ImagesSelect extends React.PureComponent {
  state = {
    show: false,
  }

  onChange = (e) => {
    const {onChange, name, id, disabled = false} = this.props
    if (!onChange || disabled) return
    onChange({target: {value: e.target.dataset.img, name, id}})
    this.setState({show: false})
  }

  onToggle = () => {
    if (this.props.disabled) return
    this.setState({show: !this.state.show})
  }

  getStyle = memoize(img => ({backgroundImage: `url(/tournament-images/${img})`}))

  render() {
    const {value} = this.props
    return (
      <div className='t-img-select'>
        <div className='t-img-select__current' style={this.getStyle(value)} onClick={this.onToggle}>
          {!value && (
            <svg width='53' height='53' viewBox='0 0 53 53' fill='none'>
              <path
                d='M44.6316 13.9474V22.2879C44.6316 22.2879 39.0805 22.3158 39.0526 22.2879V13.9474H30.6842C30.6842 13.9474 30.7121 8.39632 30.6842 8.36842H39.0526V0H44.6316V8.36842H53V13.9474H44.6316ZM36.2632 25.1053V16.7368H27.8947V8.36842H5.57895C2.51053 8.36842 0 10.8789 0 13.9474V47.4211C0 50.4895 2.51053 53 5.57895 53H39.0526C42.1211 53 44.6316 50.4895 44.6316 47.4211V25.1053H36.2632ZM5.57895 47.4211L13.9474 36.2632L19.5263 44.6316L27.8947 33.4737L39.0526 47.4211H5.57895Z'
                fill='url(#paint0_linear)'/>
              <defs>
                <linearGradient id='paint0_linear' x1='26.5' y1='0' x2='26.5' y2='53' gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#FBFBFB'/>
                  <stop offset='1' stopColor='#CACACA'/>
                </linearGradient>
              </defs>
            </svg>
          )}
        </div>
        {this.state.show && (
          <div className='t-img-select__list'>
            {IMAGES.map(img => (
              <img key={img} className='t-img-select__img'
                   src={`/tournament-images/${img}`}
                   data-img={img}
                   onClick={this.onChange}/>
            ))}
          </div>
        )}
      </div>
    )
  }
}
