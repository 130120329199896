import styled from "styled-components";
import double from '../../assets/border/double.png';

export const ContainerFilter = styled.div`
	background: #000000;
	margin-bottom: 24px;
	padding: 3px 10px;
	background-size: contain;
	${ props => props.bg && `
		border: 22px solid;
        border-image: url(${double}) 22 round round;
	` };
`;

export const FilterButton = styled.div`
	background: ${ props => props.active ? '#D7BE5E' : '#454545' };
	border-radius: 14px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: ${ props => props.active ? '#000000' : '#FFFFFF' };
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
	width: 100%;
	cursor: pointer;
	&:last-child {
		margin-right: 0;
	}
`;

export const FilterWrapper = styled.div`
	display: flex;
`