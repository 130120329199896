import React from 'react'
import LayoutDefault from '../layouts/default'
import { TOURNAMENT_CREATE } from '../../routes'
import './about-page.scss'
import {
  Container,
  Wrapper,
  Text,
  Card,
  Img,
  Border,
  ImgContainer,
  TextContainer,
  VideoContainer,
  Video,
  TextVideo,
  Button,
  ButtonWrapper,
  Strong,
  ImgCenter,
  Row,
} from './styles'
import Title from '../../atoms/title'
import { Col } from 'reactstrap'

import cardFirst from './../../../assets/abouts/card-first.png'
import cardLast from './../../../assets/abouts/crad-last.png'
import video from './../../../assets/abouts/video.png'
import img1 from './../../../assets/abouts/1.png'
import img2 from './../../../assets/abouts/2.png'
import img3 from './../../../assets/abouts/3.png'
import img4 from './../../../assets/abouts/4_.png'
import img5 from './../../../assets/abouts/5_.png'
import img6 from './../../../assets/abouts/6.png'
import img7 from './../../../assets/abouts/7.png'
import img8 from './../../../assets/abouts/8.png'
import img9 from './../../../assets/abouts/9.png'
import img10 from './../../../assets/abouts/10.png'
import img11 from './../../../assets/abouts/11.png'
import img12 from './../../../assets/abouts/12.png'
import img13 from './../../../assets/abouts/13.png'
import img14 from './../../../assets/abouts/14.png'
import img15 from './../../../assets/abouts/15.png'

export const AboutPage = () => (
  <React.Fragment>
    <LayoutDefault className='about-page'>
      <Container>
        <Wrapper>
          <Title align='center' size='30'>ABOUT</Title>
          <Text>
            Welcome to the Tournament Platform - your main destination to organize a community tournament or participate
            in one. This website was specifically created to provide the best experience to everyone interested in
            competitive GWENT. On this page you will find helpful tips and guides on how to use the Tournament Platform
            to get the most out of it.
          </Text>
          <Text>
            Please note that the Tournament Platform is launched as an Open Beta feature, so please bear with us while
            we fix bugs and polish. You can always submit your feedback, bug reports and new feature requests on the
            official forum using <a href='https://forums.cdprojektred.com/index.php?threads/11035598/'>this link</a>.
            GL HF!
          </Text>

          <Border/>

          <Title align='center' size='30'>ACCESSING THE PLATFORM</Title>
          <Text>
            Anyone can access the website using <a href='/'>the link</a>, but most of the features, such as creating,
            administrating and joining tournaments, are available only to logged-in users. To log in, you need to click
            the LOGIN button in the upper right corner and enter the website using the credentials of your GWENT
            account. You can always log out by clicking on your nickname and then on the LOGOUT button at the bottom of
            the menu.
          </Text>

          <Border/>

          <Title align='center' size='30'>YOUR PROFILE</Title>
          <Text>
            Your profile is accessible by clicking on your nickname in the upper right corner of the page. You can choose your time zone and notification preferences, and also add your contact information.
          </Text>

          <Border/>

          <Title align='center' size='30'>YOUR ORGANIZATION</Title>
          <Text>
            For communities that are interested in holding regular tournaments under the name of a certain organization, it’s possible to create one here. You will need to select a name, provide a short description, and supply at least one way to contact the named organization.
          </Text>
          <Text>
            By default, the person who created the organization becomes its Owner. This role can be passed to another GWENT player in the organization submenu (available by clicking on the name of your organization) - be aware that passing the ownership to another player will make you lose all owner privileges and can’t be revoked.
          </Text>
          <Text>
            You can invite any number of Admins to your organization - they will be able to create and manage tournaments and add or delete players, but won’t be able to add other Admins to the organization or change any settings.
          </Text>

          <Border/>

          <Title align='center' size='30'>ORGANIZING TOURNAMENT</Title>
          <Text>
            Any logged-in player can organize a tournament. You can do it by clicking on the yellow <Strong>ORGANIZE TOURNAMENT</Strong> button at the top of the page. After that, you will be asked to provide the following information:
            <ul>
              <li>
                Select one of the predetermined banners to display on the main page.
              </li>
              <li>
                <Strong>Name</Strong> your tournament.
              </li>
              <li>
                (Optional) Select the <Strong>organization</Strong> by which this tournament is organized. You need to be part of this organization to do this (either as Owner or as Admin).
              </li>
              <li>
                <Strong>Provide a description</Strong> of the tournament. Make sure to include all the important details about the rules, prizes, restrictions and/or special conditions.
              </li>
              <li>
                <Strong>Contact information</Strong>.
              </li>
              <li>
                <Strong>Start date and time</Strong>. By default, your current time zone will be selected. Participants will see the date and time according to their own time zone automatically.
              </li>
              <li>
                <Strong>Check-in settings</Strong>. Select the time period for the players to confirm their participation in the upcoming tournament.
              </li>
              <li>
                <Strong>Players registration</Strong>. Choose between allowing anyone to join the tournament or restricting access only to players you will add manually.
              </li>
              <li>
                <Strong>Players limit</Strong> - max number of players that can participate. This setting is especially important for tournaments with Open registration.
              </li>
              <li>
                <Strong>Bracket</Strong> format. This setting is very important and defines how your tournament will be played:
                <ul>
                  <li>
                    <Strong>Single elimination</Strong> is a straightforward format in which once a player loses their match, he or she is out of the tournament. It’s commonly used for high-stakes tournaments (including GWENT Opens) or as the playoff stage in multi-stage tournaments. In this format, it is possible to enable or disable a match for the third place (between the losers of both semi-final matches).
                  </li>
                  <li>
                    <Strong>Double elimination</Strong> is another popular format for competitive tournaments with a limited number of participants. In the double elimination bracket, after losing the match for the first time, a player moves to the losers bracket and can continue to play until he or she is defeated again. In this format, the final match is played between the finalists of both the winners and losers brackets.
                  </li>
                  <li>
                    <Strong>Round Robin</Strong> is a format commonly used when all participants need to face each other once. Typically, Round Robin tournaments utilize multiple groups to make tournaments with a big number of players manageable. Remember that if you have an odd number of players in the Round Robin group, this will result in one player receiving an auto-win (bye) every round.
                  </li>
                  <li>
                    <Strong>Swiss</Strong> format is similar to a Round Robin tournament, as players are not eliminated after losing once or twice. Unlike a Round Robin tournament, players won’t necessarily play everyone else in the competition, as they will be randomly paired each round based on their point score. Swiss format is great for tournaments with a large number of participants and is often used as the first stage in a multi-stage competition (followed by Single or Double elimination playoffs).
                    <div style={{paddingLeft: 30}}>
                      It’s important to set a correct number of Swiss rounds based on the number of participants. Here is our recommendation:<br/>
                      5-8 Players = 3 Rounds<br/>
                      9-16 Players = 4 Rounds<br/>
                      17-32 Players = 5 Rounds<br/>
                      33- 64 Players = 6 Rounds<br/>
                      65-128 Players = 7 Rounds<br/>
                      129-212 Players = 8 Rounds<br/>
                      213- 385 Players = 9 Rounds<br/>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <Strong>Seeding</Strong>. You can choose between manual and randomized seeding of players based on your tournament rules. Swiss seeding is always random.
              </li>
              <li>
                <Strong>Manual/auto start of rounds</Strong>. You can choose between these 2 options to determine the level of control admins have on the tournament flow. It’s recommended to select “Manual” if you’re running the tournament with spectators - this will allow you to assign spectators before every new round.
              </li>
              <li>
                <Strong>Coinflip</Strong> setting. With the “Random” coinflip setting in every match, a random player will go first in games 1, 3, and 5. With the “Manual” coinflip setting, admins will be able to define the player for every match (by clicking on the pair in the bracket).
              </li>
              <li>
                <Strong>Picks & bans</Strong>. If you’re running the tournament with decks/leader abilities submissions and bans, select the “ON” option. You will be asked to determine if leader abilities or full decklists will be gathered before the tournament, how many of these submissions are required, and the number of bans (typically just 1). Deck format option determines if submitted decklists should be visible to all players or admins only. Additionally, you will need to define a deadline for providing the decks or abilities. By enabling the “Time limit” option for bans you will be able to limit the time for both players to make their decisions before going into the match. Failing to ban your opponents’ decks/abilities before the deadline will result in match loss.
              </li>
              <li>
                <Strong>Tournament visibility</Strong>. This setting determines if the tournament will be on the main page or only accessible via the direct link.
              </li>
              <li>
                <Strong>Admins</Strong>. You can add any number of GOG users that will receive administrator’s rights and will be able to manage the tournament. If you’re a creator of the tournament, you will be assigned as its administrator by default. If the tournament is created by an organization, all its members will be admins for this tournament.
              </li>
              <li>
                <Strong>Spectators</Strong>. You can add any number of GOG users that can later be added as spectators for selected matches. Admins can be spectators. You can also add additional spectators later in the bracket sub-menu.
              </li>
            </ul>
          </Text>

          <Border/>

          <Title align='center' size='30'>TOURNAMENT PAGE</Title>
          <Card>
            <Row>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img1}/>
                </ImgContainer>
              </Col>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    Once the tournament is created, players will be able to find it on the main page (if Tournament visibility is set to “Public”) and join it (if registration is open).
                    This preview contains the following information:
                    <ul>
                      <li>
                        Status (upcoming, in progress, finished)
                      </li>
                      <li>
                        Tournament name
                      </li>
                      <li>
                        Date and time of start
                      </li>
                      <li>
                        Format
                      </li>
                      <li>
                        Type of registration (Open/Closed)
                      </li>
                      <li>
                        Max and current number of players joined
                      </li>
                      <li>
                        Organizer
                      </li>
                    </ul>
                  </Text>
                </TextContainer>
              </Col>
            </Row>
          </Card>
          <Text>
            By clicking on the preview, you can learn more about the tournament via the “<Strong>Overview</Strong>” section.
          </Text>
          <Text>
            “<Strong>Players</Strong>” section provides information on the participants and their status in the tournament.<br/>
            Admins are able to contact players, add/remove them from the tournament or even block them from joining.<br/>
            For tournaments with decks/abilities submissions, they will be displayed here and admins can verify them to allow their use in the tournament.<br/>
            For tournaments with check-in, the check-in status of players will be displayed here. Admins can also check-in players by themselves if needed.
          </Text>
          <Text>
            “<Strong>Brackets</Strong>” section provides up-to-date information on the current state of the tournament. By activating the brackets, admins make them visible for the public.<br/>
            In case of the manual start of a round, the “START THIS ROUND” button will be visible for each round - pressing it will generate tournament invitations in-client. Note: if the tournaments require players to submit bans, players would need to perform this action before receiving the invitations.
          </Text>
          <Text>
            “<Strong>Logs</Strong>” section is visible only to admins. It contains information on all key milestones in the tournament. In case of matchmaking and/or other errors, they will be displayed here.
          </Text>

          <Border/>
          <Title align='center' size='30'>MY TOURNAMENTS</Title>
          <Text>
            This section provides an overview of the tournaments you’re participating in as either a player or admin. It is also used to access the “My Organizations” section.
          </Text>

          <Border/>
          <Title align='center' size='30'>NOTIFICATIONS</Title>
          <Text>
            The bell icon on the page’s header represents the notifications tab. Based on your notifications preferences, you will be able to receive different alerts. Clicking on these alerts will allow you to go directly to the page where your action or attention is needed.
          </Text>

          <Border/>
          <Title align='center' size='30'>JOINING TOURNAMENT </Title>
          <Text>
            You can join any tournament as long as it:
            <ul>
              <li>Has open registration</li>
              <li>Has free slots available</li>
            </ul>
          </Text>
          <Text>To find such tournaments, you can use the filter at the top of the page:</Text>
          <Card>
            <Row>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    If the tournament has the “Closed registration” setting on, only admins can add players, so you may need to get in touch with them using the contact information provided in the description.
                  </Text>
                </TextContainer>
              </Col>
              <Col lg={6}>
                <ImgContainer>
                 <Img src={img2}/>
                </ImgContainer>
              </Col>
            </Row>
          </Card>

          <Border/>

          <Title align='center' size='30'>CHECKING IN</Title>
          <Card>
            <Row>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img3}/>
                </ImgContainer>
              </Col>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    Many tournaments use the “Check-in” option to avoid issues with no-shows in round one. Based on the tournament settings, you may need to confirm your participation by clicking the CHECK-IN button. It will appear once the check-in phase begins.
                  </Text>
                </TextContainer>
              </Col>
            </Row>
          </Card>

          <Border/>

          <Title align='center' size='30'>DECK SUBMISSIONS</Title>
          <Card group={true}>
            <Row>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    Another popular feature in many GWENT tournaments is Deck submissions & bans. You can see if the decks/abilities submission is required in the Details section on the tournament page.
                  </Text>
                </TextContainer>
              </Col>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img4}/>
                </ImgContainer>
              </Col>
            </Row>
          </Card>
          <Card group={true}>
            <Row>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img5}/>
                </ImgContainer>
              </Col>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    Before the start of the check-in phase in a tournament using this feature, players will be required to provide the links to their decks from  <a href='https://www.playgwent.com/decks'>https://www.playgwent.com/decks</a>. Please note that you don’t need to make your deck public in order to generate a shareable link. In order to do that, enter Deck Builder in GWENT, right-click on your deck and select the SHARE DECK option.
                  </Text>
                </TextContainer>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    Based on the tournament settings, decklists can be either “Open” or “Closed”. In case of “Open” decklists, all players will be able to review all decklists submitted by the participants. If the decklists are “Closed”, only leader abilities will be visible to the public. Once all the required decks/abilities are submitted, admins will be able to verify players’ submissions to allow them into the tournament.
                  </Text>
                </TextContainer>
              </Col>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img6}/>
                </ImgContainer>
              </Col>
            </Row>
          </Card>

          <Border/>

          <Title align='center' size='30'>BANS</Title>
          <Text>
            In the tournaments with bans, players will be required to ban a certain number (typically one) of opponents’ decks before the start of each tournament round. There are 2 ways to access the menu to perform this action:
          </Text>
          <Text>
            Clicking on the respective notification:
            <ImgCenter><Img src={img7}/></ImgCenter>
          </Text>
          <Text>
            Clicking on your tournament match in the brackets:
            <ImgCenter><Img src={img8}/></ImgCenter>
          </Text>
          <Text>
            Once you’re in the bans menu you can review your opponent’s decks and select the decks you would like to ban. After the bans are collected from both players, they will be displayed on the match page:
            <ImgCenter><Img src={img9}/></ImgCenter>
          </Text>


          <Border/>

          <Title align='center' size='30'>JOINING THE TOURNAMENT MATCH</Title>
          <Card group={true}>
            <Row>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img10}/>
                </ImgContainer>
              </Col>
              <Col lg={6}>
                <TextContainer style={{paddingLeft: 35}}>
                  <Text>
                    Once the tournament round begins (and after bans were submitted in a tournament with bans), players (and spectators) will receive in-client invitations to join the tournament match.
                  </Text>
                </TextContainer>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    <Strong>Important</Strong>: players who decline the invitation will receive a loss in the whole “Best of” series.<br/>
                    After accepting the slide-in invitations, players will be redirected to the tournament lobby in which they can select the deck to be used in the upcoming game, and join the matchmaking (by Clicking on the “PLAY” card) once they are ready. Both players need to do this within a 10-minute limit. After the end of each game in the ongoing series, players always return to this screen to select the next deck and join the game when they are ready.
                  </Text>
                </TextContainer>
              </Col>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img11}/>
                </ImgContainer>
              </Col>
            </Row>
          </Card>
          <Text>
            <Strong>Important</Strong>: players who click on the “LEAVE” button will receive a loss in the whole “Best of” series.
          </Text>

          <Border/>

          <Title align='center' size='30'>SPECTATING TOURNAMENT MATCHES</Title>
          <Text>
            Tournament admins can assign spectators to any tournament match by clicking on the “eye” icon in the bracket. Any match can be spectated by the maximum number of 2 spectators.
          </Text>
          <Card>
            <Row>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img12}/>
                </ImgContainer>
              </Col>
              <Col lg={6}>
                <TextContainer style={{paddingLeft: 35}}>
                  <Text>
                    If no spectators were added to the available pool at the moment of creating the tournament, it’s possible to add these in this menu by clicking the “edit” button. This menu is used to add and remove spectators to the particular match. After successfully adding spectators, they will be displayed in the brackets under the respective pair of players with a golden “eye” icon.
                  </Text>
                </TextContainer>
              </Col>
            </Row>
          </Card>
          <Text>
            Assigned spectators will receive an invitation to join the match at the same time as the players. If a spectator is assigned to a match, it won’t start until both players and all spectators accept the invitations and enter the matchmaking (“rotating card screen”). <br/>
            Admin can remove the spectator from the match at any moment, however, it won’t affect the game in progress (i.e. the spectator will continue to spectate the game to the end, but won’t receive an invitation to the next game of the series). If the spectator quits the game in progress (or loses connection to it), he or she won’t be able to return to it.
          </Text>
          <Text>
            Spectators can switch a camera perspective (top&larr;&rarr;bottom) in the game they are observing by pressing the F4 hotkey. This action won’t affect players.
          </Text>

          <Border/>

          <Title align='center' size='30'>ADDITIONAL ADMIN ACTIONS</Title>
          <div><Strong>Regulating coinflip</Strong></div>
          <Text>
            If the “Manual” option is selected in the tournament settings for coinflip, admins can regulate coinflip in every tournament match by clicking on it in the brackets and accessing the match menu.
          </Text>
          <Card>
            <Row>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    After clicking on the “EDIT COINFLIP” button, a new menu will appear - you can select which player will go first in game 1 of the series. This choice will automatically determine the coinflip in all subsequent games of this series (i.e. if player A was selected to go first in game 1 of 5, he or she will also go first in games 3 and 5).
                  </Text>
                </TextContainer>
              </Col>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img13}/>
                </ImgContainer>
              </Col>
            </Row>
          </Card>

          <div><Strong>Editing match results</Strong></div>
          <Card>
            <Row>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img14}/>
                </ImgContainer>
              </Col>
              <Col lg={6}>
                <TextContainer style={{paddingLeft: 35}}>
                  <Text>
                    While all tournament matches results are automatically displayed on the Tournament Platform, sometimes it’s necessary to overwrite these or add missing information. In order to do so, admins need to access the match menu (from the Brackets section) and click on the “pencil” icon alongside the game in question. You will be able to select the winning player and the ability icons (optional) to highlight the decks that were used in a game.
                  </Text>
                </TextContainer>
              </Col>
            </Row>
          </Card>

          <div><Strong>Removing and blocking players</Strong></div>
          <Card>
            <Row>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    Admins can remove any player from the tournament by clicking on the “X” icon in the “Players” menu. The player’s status will change from “participate” to “technical loss” and he or she won’t be able to join the next matches within this tournament. Note that this action can be undone by pressing “Thumbs up” icon.
                  </Text>
                </TextContainer>
              </Col>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={img15}/>
                </ImgContainer>
              </Col>
            </Row>
          </Card>
          <Text>
            Blocking players will prevent them from joining (can be useful in case a particular player is banned from participating in the tournament with open registration).
          </Text>

          <Border/>
          <Title align='center' size='30'>TOURNAMENT ORGANIZATION TIPS</Title>
          <Text>
          <ol>
            <li>
              <Strong>Human > Machine</Strong>. While the Tournament Platform provides many useful features to make the process of tournament organization easier, it still heavily relies on the leading role of the tournament admin to oversee the process, help players to participate, solve potential disputes, and enforce the rules. Make sure that you have enough time and manpower to deliver the best possible experience to all participants. With admins having full control over the tournament flow, you may want to work only with people you trust and can rely on.
            </li>
            <li>
              <Strong>RTFM and rehearse</Strong>. Before organizing any tournament, make sure that you understand how the Tournament Platform works. It doesn’t hurt to run a few small test tournaments with your friends to learn the basics before proceeding with organizing large tournaments.
            </li>
            <li>
              <Strong>Plan ahead</Strong>. In most cases, running a tournament would mean scheduling it in advance and properly advertising it. If your tournament will be spectated, make sure to make all necessary arrangements with casters.
            </li>
            <li>
              <Strong>Have a backup plan</Strong>. Things can’t always go as expected, so it’s better to be prepared for any scenario. Consider having players duke it out in friend matches, in case they can’t face each other via the Tournament Platform, and edit the results manually afterwards. For streamed matches, think of a backup plan in case spectators won’t be able to join properly. Investing effort in plan B is never a waste of time.
            </li>
            <li>
              <Strong>Be prepared to communicate</Strong>. Consider having a Discord channel or any other way to coordinate the tournament flow with admins, players and spectators. Players should be able to ask questions and receive help with the issues they might be facing in a timely manner. The ability to effectively communicate is what usually separates the best tournament organizers from everyone else.
            </li>
            <li>
              <Strong>Don’t hesitate to ask for help</Strong>. While we can’t guarantee that we will be able to help you with issues in real-time, you can avoid many issues by asking questions and getting help before your tournament starts. You can do this on the official forum in this <a href='https://forums.cdprojektred.com/index.php?threads/11035598/'>thread</a>. We’d be happy to hear from you!
            </li>
            <li>
              <Strong>Don’t forget to have fun</Strong>. Organizing a tournament (or playing in one) can be very exhausting. Try to plan your schedule in a way that leaves some time for both admins and participants to rest.
            </li>
          </ol>
          </Text>

          {/*
          <Card>
            <Row>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={cardFirst}/>
                </ImgContainer>
              </Col>
              <Col lg={6}>
                <TextContainer style={{paddingLeft: '35px'}}>
                  <Text>
                    Мы учли опыт разработки других киберспортивных платформ,
                    в процессе сделав ваше время пребывание проще, быстрее и приятнее. Взяв на себя
                    всю работу, мы предоставили вам возможность наслаждаться игрой и не отвлекаться
                    на остальное.
                  </Text>
                </TextContainer>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row>
              <Col lg={6}>
                <TextContainer>
                  <Text>
                    Теперь играть в Gwent: TWCG стало ещё проще благодаря интеграции платформы с
                    внутриигровым функционалом. Подтверждайте участие в турнирах, находите
                    оппонентов, следите за своим прогрессом - всё это и многое другое получится
                    сделать буквально в за считанные минуты.
                  </Text>
                </TextContainer>
              </Col>
              <Col lg={6}>
                <ImgContainer>
                  <Img src={cardLast}/>
                </ImgContainer>
              </Col>
            </Row>
          </Card>
          <VideoContainer>
            <Video controls={false} poster={video}/>
            <TextVideo>Платформа призвана объединить организаторов турниров, киберспортивных участников и
              зрителей, позволяя находить всю актуальную информацию в рамках единого информационного
              ресурса.
            </TextVideo>
          </VideoContainer>*/}
          <Border/>
        </Wrapper>
        <Title size='52' align='center' lineHeight='52'>How about another round of Gwent?</Title>
        <ButtonWrapper>
          <Button href='/' className='btn btn-kings'>join tournament</Button>
          <Button href={TOURNAMENT_CREATE} className='btn btn-kings'>organize tournament</Button>
        </ButtonWrapper>
      </Container>
    </LayoutDefault>
  </React.Fragment>
)
