import React from 'react'
import { connect } from 'react-redux'
import { LOGIN, ORGANIZATION_CREATE, organization } from '../../routes'
import { ProgressState } from '../../../constants/progress-state'
import { onChange, handleInputFiles } from '../../../utils/on-change'
import LayoutDefault from '../layouts/default'
import {
  Button,
  Col,
  Input as OldInput,
  Label,
  Row,
} from 'reactstrap'
import { TitleWrapper, TextDropZone } from './styles'
import Title from '../../atoms/title'
import DropZone from '../../atoms/dropZone'
import Input from '../../atoms/input'
import { FormContainer } from '../../global-styles'
import MaterialIcon from '@material/react-material-icon'

const mapState = ({organization, user}) => ({
  organization,
  user,
})
const mapDispatch = ({organization}) => ({
  load: organization.load,
  edit: (data, newAvatar) => organization.edit({data, newAvatar}),
  remove: organization.remove,
  resetEdit: organization.resetEdit,
})

class COrganizationEditPage extends React.PureComponent {
  state = {
    data: {
      name: '',
      description: '',
      avatar: '',
      email: '',
      discord: '',
      facebook: '',
      twitter: '',
    },
    newAvatar: null,
  }

  _isInit = false
  fileInput = React.createRef()

  get isCreate() {
    return this.props.match.path === ORGANIZATION_CREATE
  }

  init() {
    if (this._isInit) return
    const {user, history, match, organization, load, resetEdit} = this.props
    const {data} = this.state
    if (user.state !== ProgressState.Complete) return
    if (!user.identified()) {
      history.push(LOGIN)
      return
    }
    if (!this.isCreate) {
      if (organization.state === ProgressState.InProgress) return
      if (organization.data.id !== parseInt(match.params.id)) {
        load(match.params.id)
        return
      }
      if (organization.state === ProgressState.Complete) {
        if (!organization.data.isEditable) {
          history.push('/')
          return
        }
        this.setState({
          data: {...data, ...organization.data},
        })
      }
    }
    resetEdit()
    this._isInit = true
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate() {
    this.init()
    const {organization: {data, edit}, history, resetEdit} = this.props

    if (edit.state === ProgressState.Complete) {
      resetEdit()
      history.push(organization(data.id))
      return
    }
  }

  onChange = onChange(result => this.setState({data: {...this.state.data, [result.name]: result.value}}))

  onChangeAvatar = (e) => {
    const result = handleInputFiles(e)
    if (result.value.length > 0) {
      this.setState({newAvatar: result.value[0]})
      const reader = new FileReader()
      reader.onloadend = () => this.setState({data: {...this.state.data, avatar: reader.result}})
      reader.readAsDataURL(result.value[0])
    }
  }

  onSubmit = () => {
    this.props.edit(this.state.data, this.state.newAvatar)
  }

  onFileClick = () => this.fileInput.current.click()

  handlerInput = (name, value) => {
    this.setState({[name]: value})
  }

  render() {
    return (
      <LayoutDefault className='organization-edit-page'>
        {this.renderPage()}
      </LayoutDefault>
    )
  }

  renderPage() {
    if (!this._isInit) return null
    const {name, description, avatar, email, discord, facebook, twitter} = this.state.data

    return (
      <section className='organization-edit'>
        <TitleWrapper>
          <Title text={this.isCreate ? 'New organization' : 'Edit organization'}/>
        </TitleWrapper>
        {/*<FormContainer>
          <Row form>
            <div className='organization-edit__avatar-wrapper' onClick={this.onFileClick}>
              <div className='organization-edit__avatar'>
                {avatar ? <img src={avatar} alt=''/> : <MaterialIcon icon='add_photo_alternate'/>}
              </div>
              <div>
                <div className='organization-edit__avatar-title'>Add your logo</div>
                <div className='organization-edit__avatar-hint'>256 x 256 px recommended</div>
              </div>
              <input type='file' id='newAvatar'
                     className='organization-edit__avatar-file'
                     onChange={this.onChangeAvatar}
                     ref={this.fileInput}
                     accept='.png, .jpg, .jpeg'/>
            </div>
          </Row>
        </FormContainer>*/}
        <FormContainer>
          <Row>
            <Col lg={6}>
              <Input title='Name' id='name' value={name} onChange={this.onChange.string} maxLength={30}/>
            </Col>
          </Row>
        </FormContainer>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <Label for='description' className='organization-edit-label'>Description</Label>
              <OldInput type='textarea' id='description'
                        className='organization-edit-textarea'
                        disabled={false}
                        placeholder='Few words about your organization'
                        value={description}
                        onChange={this.onChange.string}/>
            </Col>
          </Row>
        </FormContainer>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <h3>CONTACTS</h3>
              <p style={{lineHeight: '26px'}}>
                Tell everyone what way you’d like to communicate. <br/>
                Fill at least one field, so the players could reach you.
              </p>
            </Col>
          </Row>
        </FormContainer>
        <FormContainer>
          <Row>
            <Col lg={6} style={{marginBottom: 9}}>
              <Input {...{
                title: 'EMAIL',
                placeholder: '@mail.com',
                icon: 'email',
                name: 'email',
                value: email,
                style: {fontStyle: 'italic'},
                onChange: this.onChange.string,
              }}/>
            </Col>
            <Col lg={6} style={{marginBottom: 9}}>
              <Input {...{
                title: 'TWITTER',
                placeholder: '@twittername',
                icon: 'twitter',
                name: 'twitter',
                value: twitter,
                style: {fontStyle: 'italic'},
                onChange: this.onChange.string,
              }}/>
            </Col>
          </Row>
          <Row>
            <Col lg={6} style={{marginBottom: 9}}>
              <Input {...{
                title: 'FACEBOOK',
                placeholder: '@facebookname',
                icon: 'facebook',
                name: 'facebook',
                value: facebook,
                style: {fontStyle: 'italic'},
                onChange: this.onChange.string,
              }}/>
            </Col>
            <Col lg={6} style={{marginBottom: 9}}>
              <Input {...{
                title: 'DISCORD',
                placeholder: 'name#0000 or invite link',
                icon: 'discord',
                name: 'discord',
                value: discord,
                style: {fontStyle: 'italic'},
                onChange: this.onChange.string,
              }}/>
            </Col>
          </Row>
        </FormContainer>
        <FormContainer>
          <Row>
            <Col lg={{size: 3, offset: 9}}>
              {/*{this.isCreate ? <Link to='/' className='btn btn-light ml-auto'>Cancel</Link> : null}*/}
              <Button color='primary' onClick={this.onSubmit} className={this.isCreate ? 'ml-2' : 'ml-auto'}>
                {this.isCreate ? 'Create' : 'Save'}
              </Button>
            </Col>
          </Row>
        </FormContainer>
      </section>
    )
  }
}

export const OrganizationEditPage = connect(mapState, mapDispatch)(COrganizationEditPage)
