import React from 'react'
import { Header } from '../../header/index'
import { Footer } from '../../footer/index'
import { Background, Border, Logo } from './styles'
import { withRouter } from 'react-router-dom'
import logo from '../../../assets/logo.png'

const LayoutDefault = ({children, className, location: {pathname}}) => {
  return (
    <React.Fragment>
      <Header/>
      <main className={'main-container ' + className}>
        <div className='container'>
          {children}
        </div>
      </main>
      <Footer/>
    </React.Fragment>
  )
}

export default withRouter(LayoutDefault)
