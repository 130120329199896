import React from 'react'

const {hostname, protocol} = document.location;

const redirectUrl = `${protocol}//${hostname}/login_success`;
const loginUrl = `https://login.gog.com/auth?client_id=52037972405116502&redirect_uri=${redirectUrl}&response_type=code&layout=branded&brand=gwent&start_from=login`;

export class LoginPage extends React.PureComponent {
  componentDidMount() {
    document.location.href = loginUrl
  }

  render() {
    return null
  }
}

