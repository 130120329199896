import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import closed from '../../assets/increase/closead.png'
import button from '../../assets/increase/vector.png'
import setting from '../../assets/increase/setting.png'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import {PROFILE_SETTINGS, tournament, tournamentMatch} from '../routes'
import { getLocalDate } from '../../utils/date'

const tz = moment.tz.guess()

const mapState = ({user}) => ({
  user,
})
const mapDispatch = ({user: {openModal, acceptInvitation, loadNotifications}}) => ({
  openModal,
  acceptInvitation,
  loadNotifications
})

class Increase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  handelClick = () => {
    this.props.openModal()
  }

  onNotificationClick = (e) => {
    if (e.target.classList.contains('invitation')) {
      e.stopPropagation();
      e.preventDefault();
      this.props.acceptInvitation(e.currentTarget.dataset.tournamentId)
      setTimeout(() => this.props.loadNotifications(), 500)
    } else if (e.currentTarget.dataset.tournamentId) {
      if (e.currentTarget.dataset.matchId) {
        document.location.href = tournamentMatch(e.currentTarget.dataset.tournamentId, e.currentTarget.dataset.matchId)
        return
      }
      document.location.href = tournament(e.currentTarget.dataset.tournamentId)
    }
  }

  render() {
    const {data} = this.props
    return (
      <div className='increase__block'>
        <div className='increase__header1'>
          <h2 className='increase__title'>NOTIFICATIONS</h2>
          <div>
            <button onClick={this.handelClick}><img width='100%' src={closed} alt=''/></button>
          </div>
        </div>
        <div className='scroll_b'>
          {data.map((i, v) => (
            <div className='coment__blovk' key={v} data-tournament-uuid={i.tournament} data-tournament-id={i.tournament} data-match-id={i.match} onClick={this.onNotificationClick}>
							<span className='line'>

							</span>
              <div className='info__block'>
                <div className='ball__block'>
                  {!i.delivered && <div className='red_ball'/>}
                </div>
                <div>
                  <p className='info__text' dangerouslySetInnerHTML={{__html: i.message}}>
                   </p>
                  <p className='tex'>
                    {getLocalDate(i.dateCreate).fromNow()}
                  </p>
                </div>

              </div>
              <span className='line'/>
            </div>
          ))}
        </div>
        <div className='increase__block_footer'>
          <div className='increase__footer_bootn'>
            {/*<div className='button__block'>
              <button className='buttn_config'><img width='100%' src={button} alt=''/></button>
              <p className='button__block_text'>Sounds</p>
            </div>
            <div className='button__block'>
              <button className='buttn_config'><img width='100%' src={button} alt=''/></button>
              <p className='button__block_text'>Pop-ups</p>
            </div>*/}

          </div>
          <div className='increase__footer_setting'>
            <Link to={PROFILE_SETTINGS}><img src={setting} alt=''/></Link>
          </div>
        </div>
      </div>
    )
  }
}

const Container = connect(mapState, mapDispatch)(Increase)
export default Container

