import React from 'react'
import MaterialIcon from '@material/react-material-icon'

export class MatchSpectator extends React.PureComponent {
  onSpectatorClear = (e) => {

  }

  render() {
    const {
      spectator,
      spectatorIndex,
      isEdit,
      onAddSpectatorClick,
      onRemoveSpectatorClick
    } = this.props
    let name = ''
    let scoreElem = ''
    let className = ''
    let onClick = null
    if (isEdit) {
      if (spectator) {
        name = spectator.name
        scoreElem = <MaterialIcon icon='close' data-spectator-id={spectator.id} onClick={onRemoveSpectatorClick}/>
      }
      if (!name) {
        name = <span>Add spectator</span>
        scoreElem = <MaterialIcon icon='add'/>
        className = '_seed'
        onClick = onAddSpectatorClick
      }
    } else {
      if (spectator) {
        name = spectator.name
        scoreElem = <MaterialIcon icon='visibility' className='bracket__player-spectator-ico'/>
      }
    }

    return (
      <div className={`bracket__player _${spectatorIndex + 1} ${className} _spectator`} onClick={onClick}>
        <div className='bracket__player-name'>{name}</div>
        <div className='bracket__player-score'>{scoreElem}</div>
      </div>
    )
  }
}

