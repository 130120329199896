import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ABOUT, TOURNAMENT_CREATE, PROFILE_SETTINGS, MY_TOURNAMENTS } from '../routes'
import logo from '../../assets/logotype-masters.png'

export const Footer = () => (
  <footer className='main-footer'>
    <div className='container'>
      <Row>
        <Col lg={2} md={4} className='mb-3 justify-content-center d-flex'>
          <Link to='/' className='main-footer__logo'><img src={logo} alt='Gwent Logo'/></Link>
        </Col>
        <Col lg={2} md={4} xs={6} className='mb-3'>
          <Link to='/'>Search tournaments</Link>
          <Link to={MY_TOURNAMENTS}>My tournaments</Link>
          <Link to={PROFILE_SETTINGS}>My profile</Link>
          <Link to={TOURNAMENT_CREATE}>Organize tournament</Link>
          <Link to={ABOUT}>About site</Link>
        </Col>
        <Col lg={2} md={4} xs={6} className='mb-3'>
          <a className='_active' href='https://forums.cdprojektred.com/index.php?threads/11035598/'>GIVE FEEDBACK</a>
          <a href='https://www.playgwent.com/user-agreement'>User agreement</a>
          <a href='https://support.gog.com/hc/en-us/articles/212632109-Privacy-Policy'>Privacy policy</a>
          <a href='https://support.gog.com/hc/en-us/articles/115000498685-Cookie-Policy'>Cookie policy</a>
        </Col>
        <Col lg={6} md={6}>
          <div className='main-footer__cdpr-logo-wrap'>
            <a href='https://playgwent.com' className='main-footer__cdpr-logo __gwent'/>
            <a href='https://gog.com' className='main-footer__cdpr-logo __gog'/>
            <a href='https://cdprojektred.com' className='main-footer__cdpr-logo __cdpr'/>
          </div>
        </Col>
      </Row>
      <div className='main-footer__copy'>
        CD PROJEKT®, The Witcher®, GWENT® are registered trademarks of CD PROJEKT Capital Group. GWENT game © CD
        PROJEKT S.A. All rights reserved. Developed by CD PROJEKT S.A. GWENT game is set in the universe created by
        Andrzej Sapkowski. All other copyrights and trademarks are the property of their respective owners.
      </div>
    </div>
  </footer>
)
