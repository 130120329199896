import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { Avatar } from '../../../avatar'
import MaterialIcon from '@material/react-material-icon'

const mapState = ({user}) => ({
  user,
})

const mapDispatch = ({tournament, user}) => ({
  search: user.search,
  resetSearch: user.resetSearch,
  addSpectator: tournament.addSpectator,
  removeSpectator: tournament.removeSpectator,
})

class CSpectators extends React.PureComponent {
  static propTypes = {
    spectators: PropTypes.array,
    tournamentId: PropTypes.string,
    isPopup: PropTypes.bool,
  }

  static defaultProps = {
    spectators: [],
    isPopup: false
  }

  state = {
    isOpen: false,
    spectatorSearch: '',
  }

  onToggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }

  onSpectatorChange = (e) => {
    const value = e.target.value
    this.setState({spectatorSearch: value})
    this.props.resetSearch()
    this.props.search(value)
  }

  onSpectatorSelect = (e) => {
    this.setState({spectatorSearch: ''})
    this.props.resetSearch()
    if (this.props.tournamentId && e.target.dataset.userId) {
      this.props.addSpectator({id: this.props.tournamentId, userId: e.target.dataset.userId})
    }
  }

  onSpectatorRemove = (e) => {
    if (this.props.tournamentId && e.target.dataset.userId) {
      this.props.removeSpectator({id: this.props.tournamentId, userId: e.target.dataset.userId})
    }
  }

  render() {
    const {spectators = [], isPopup} = this.props
    const {isOpen} = this.state
    return (
      <div className={`spectators ${isPopup ? '' : '_border'}`}>
        {this.renderHeader()}
        {!isPopup && isOpen && (
          <>
            {this.renderForm()}
            {this.renderList()}
          </>
        )}
        {isPopup && isOpen && (
          <div className='spectators-popup'>
            {this.renderHeader()}
            {this.renderForm()}
            {this.renderList()}
          </div>
        )}
      </div>
    );
  }

  renderHeader() {
    const {spectators = []} = this.props
    const {isOpen} = this.state

    return (
      <div className='spectators__head'>
        <div className='spectators__title'>Spectators - {spectators.length}</div>
        <div className='spectators__toggle' onClick={this.onToggle}>{isOpen ? 'close' : 'edit'}</div>
      </div>
    )
  }

  renderForm() {
    const {spectatorSearch} = this.state
    const search = this.props.user.search.data

    return (
      <div className='spectators__form'>
        <label htmlFor='spectators__add'>Add spectator</label>
        <Input type='text' id='spectators__add' placeholder='Username'
               value={spectatorSearch}
               onChange={this.onSpectatorChange}/>
        {search.length > 0 && spectatorSearch && (
          <div className='user-search__list'>
            {search.map(
              u => <div className='user-search__list-item' key={u.id} data-user-id={u.id}
                        data-user-name={u.name}
                        onClick={this.onSpectatorSelect}>
                {u.name}
              </div>)}
          </div>
        )}
      </div>
    )
  }

  renderList() {
    const {spectators = []} = this.props
    return (
      <div className='spectators__list'>
        {spectators.map((spectator, idx) => (
          <div key={idx} className='spectators__list-item'>
            <Avatar className='spectators__list-avatar' avatar={spectator.avatar}/>
            {spectator.name}
            <MaterialIcon icon='close' className='spectators__list-item-remove' data-user-id={spectator.id} onClick={this.onSpectatorRemove}/>
          </div>
        ))}
      </div>
    )
  }
}

export const Spectators = connect(mapState, mapDispatch)(CSpectators)
