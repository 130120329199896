import React from 'react'
import MaterialIcon from '@material/react-material-icon'

export const Hint = ({className = '', children}) => (
  <div className={`tournament-hint ${className}`}>
    <MaterialIcon icon='error_outline'/>
    <div>{children}</div>
  </div>
)

