import styled from "styled-components";
import bg from '../../../assets/border/double.png';

export const Section = styled.div`
	width: 100%;
	max-width: 468px;
	border: 22px solid;
  border-image: url(${bg}) 22 round round;
  background-color: #000;
	padding: 14px;
	&:first-child {
		margin-right: 12px;
		@media (max-width: 1200px) {
		  margin-right: 0;
		  margin-bottom: 30px;
		}
	}
`;

export const TitleWrapper = styled.div`
	margin-bottom: 6px;
`;

export const CheckBoxWrapper = styled.div`
	margin-top: 12px;
`;

export const ProfileSettingsWrapper = styled.div`
	display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
