import React from 'react'
import { DropdownWrapper, Menu, Toggle, Item, Label, ContainerLabel } from './styles'
import ToolTip from '../ToolTip'

class DropDownContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen})
  }

  render() {
    const {isOpen} = this.state
    const {toggleText, label, tooltip} = this.props
    return (
      <React.Fragment>
        <ContainerLabel>
          <Label>{label}</Label>
          {tooltip && <ToolTip tooltip={tooltip}/>}
        </ContainerLabel>
        <DropdownWrapper isOpen={isOpen} toggle={this.toggle} className='DropDown'>
          <Toggle tag='span'
                  toggle={toString(isOpen)}
                  onClick={this.toggle}
                  data-toggle='dropdown'
                  aria-expanded={isOpen}>
            {toggleText}
          </Toggle>
          <Menu
            modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: 'auto',
                      maxHeight: 100,
                    },
                  }
                },
              },
            }}
          >
            <Item>International Worlds Open Quallifier</Item>
          </Menu>
        </DropdownWrapper>
      </React.Fragment>
    )
  }
}

export default DropDownContent
