import styled from "styled-components";
import bg from '../../../assets/back/bg-about.png';

export const Background = styled.div`
	background: url(${bg}) no-repeat;
    background-size: cover;
    padding: 25%;
    flex: 1 1;
    position: relative;
`;

export const Border = styled.div`
	border-top: 1px solid #FFB800;
	padding: 1px;
	border-bottom: 1px solid #FFB800;
	position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
	&:before {
		content: '';
		border: 1px solid #FF7A00;	
		position: absolute;
		left: 0;		
    	top: 0;
    	width: 100%;		
	}
`;

export const Logo = styled.img`
	width: 100%;
	max-width: 396px;
	height: 233px;
	position: absolute;
    bottom: 0;
    z-index: 2;
    left: 50%;
    transform: translate(-50%);
    margin-bottom: -116px;
`;

