import styled from 'styled-components'
import border from '../../../assets/border/double.png'
import { Button as OldButton } from 'reactstrap'
import pensel from '../../ico/pensel.svg'

export const PlayerContainer = styled.div`
`

export const PlayerText = styled.div`
	margin-top: 16px;
	padding-left: 13px;
`

export const BlockTitle = styled.div`
	margin-bottom: 13px;
`

export const Profile = styled.div`
  position: relative;
`
export const PlayerBg = styled.div`
	border: 22px solid;
  border-image: url(${border}) 22 round round;
  background: #000000;
  margin-bottom: 13px;
  padding: 0.6rem;
  position: relative;
`

export const PlayerCardSelections = styled.div`
	margin-top: 24px;
	margin-bottom: 24px;
	display: flex;
	flex-wrap: wrap;
`

export const CardContainer = styled.div`
	display: flex;
	margin-bottom: 24px;
	max-width: 336px;
	width: 100%;
	margin-right: 36px;
`

export const CardImg = styled.div`
	border: 1px solid #949494;
	width: 60px;
	height: 60px;
	background-color: #000000;
	margin-right: 6px;
`
export const CardActions = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const ImgContainer = styled.div`
	
`

export const Button = styled(OldButton)`
`

export const WarningContainer = styled.div`
	display: flex;
	margin-top: 25px;
`

export const WarningText = styled.div`
	margin-left: 21px;
`

export const WrapperUser = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`

export const ButtonContainer = styled.div`
	max-width: 303px;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	> button:first-child {
		margin-right: 10px;
	}
`

export const PickAndBansContainer = styled.div`
	margin-top: 24px;
	display: flex;
    flex-wrap: wrap;
`

export const PickAndBansAdd = styled.img`
	border: ${ props => props.paddingActive ? '' : '1px solid #949494'};
	width: 100%;
	height: 100%;
	padding: ${ props => props.paddingActive ? '0' : '17px' };
	cursor: pointer;
`

export const ContainerImgAdd = styled.div`
	position: relative;
	width: 60px;
    height: 60px;
    margin-right: 12px;
    cursor: pointer;
    ${props => props.hover && `
	    &:hover {
	        &:after {
	            content: '';
	            position: absolute;
	            width: 28px;
	            height: 24px;
	            top: 50%;
			    left: 50%;
	            transform: translate(-50%, -50%);
	            background: url(${pensel}) no-repeat;
	        }
	    }
    `};
	&:before{
		${ props => props.active ? `content: ''` : '' };
		position: absolute;
		width: 62px;
    	height: 62px;
		border: 3px solid #D7BE5E;
		top: 0;
		left: 0;
	}
`

export const Card = styled.img`
	width: 60px;
	height: 60px;
    cursor: pointer;
`

export const ContainerImgCard = styled(ContainerImgAdd)`
	&:nth-child(-n+5) {
    	margin-top: 0;
    }
    &:nth-child(5n) {
    	margin-right: 0;
    }
     margin: 6px;
`

export const PlayerApprovedContainer = styled.div`
	display: flex;
	margin-top: 8px;
	margin-bottom: 13px;
`

export const PlayerApprovedCard = styled.div`
	margin-right: 12px;
`
export const PlayerApprovedImg = styled.div`
	width: 84px;
	height: 112px;
  margin: 0 auto;
`
export const PlayerApprovedText = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: #D7BE5E;
`
