import { ProgressState } from '../constants/progress-state'
import { get } from '../utils/fetch-api'

let searchTimeout = null

export const tournamentsList = {
  state: {
    data: [],
    state: ProgressState.NotStarted,
    error: 0,
    openNo: false,
    filterData: [],
    featured: {
      data: [],
      state: ProgressState.NotStarted,
      error: 0,
    },
  },
  reducers: {
    start: (state) => ({...state, state: ProgressState.InProgress, error: 0}),
    error: (state, payload) => ({...state, state: ProgressState.Complete, error: payload}),
    success: (state, payload) => ({...state, data: payload, state: ProgressState.Complete, error: 0}),
    reset: (state) => ({...state, data: [], state: ProgressState.NotStarted, error: 0}),

    getData: (state, payload) => ({...state, data: state.filterData = payload}),

    startFeatured: (state) => ({...state, featured: {...state.featured, state: ProgressState.InProgress, error: 0}}),
    errorFeatured: (state, payload) => ({
      ...state,
      featured: {...state.featured, state: ProgressState.Complete, error: payload},
    }),
    successFeatured: (state, payload) => ({
      ...state,
      featured: {...state.featured, data: payload, state: ProgressState.Complete, error: 0},
    }),
  },
  effects: {
    async load(payload = {}) {
      if (payload.name && payload.name.length < 2) {
        return
      }
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(async () => {
        this.start()
        const response = await get('/api/tournaments?' + Object.entries(payload).map((i) => i.join('=')).join('&'))
        if (response.ok) {
          this.success(response.body)
        } else {
          this.error(response.status)
        }
      }, 500)
    },

    async getFeatured() {
      this.startFeatured()
      const response = await get(`/api/tournaments/featured`)
      if (response.ok) {
        this.successFeatured(response.body)
      } else {
        this.errorFeatured(response.status)
      }
    },

    async setFeatured({slot, tournamentId}) {
      this.startFeatured()
      const response = await get(`/api/tournaments/featured/${slot}/${tournamentId}`)
      if (response.ok) {
        this.successFeatured(response.body)
      } else {
        this.errorFeatured(response.status)
      }
    },
  },
}
