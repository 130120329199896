import { ProgressState } from '../constants/progress-state'
import { get, post } from '../utils/fetch-api'

let searchTimeout = null

export const user = {
  state: {
    data: {},
    state: ProgressState.NotStarted,
    error: 0,
    organizations: {
      data: [],
      state: ProgressState.NotStarted,
      error: 0,
    },
    search: {
      data: [],
      state: ProgressState.NotStarted,
      error: 0,
    },
    edit: {
      state: ProgressState.NotStarted,
      error: 0,
    },
    open: false,
    tournamentsPlaying: {
      data: [],
      state: ProgressState.NotStarted,
      error: 0,
    },
    tournamentsOrganizing: {
      data: [],
      state: ProgressState.NotStarted,
      error: 0,
    },
    notifications: {
      data: [],
      state: ProgressState.NotStarted,
      error: 0,
    },
    superAdmin: {
      data: [],
      state: ProgressState.NotStarted,
      error: 0,
    },

    identified() {
      return this.state === ProgressState.Complete && this.error === 0 && this.data.id
    },
  },

  reducers: {
    startInit: (state) => ({...state, data: {}, state: ProgressState.InProgress, error: 0}),
    errorInit: (state, payload) => ({...state, data: {}, state: ProgressState.Complete, error: payload}),
    successInit: (state, payload) => ({...state, data: payload, state: ProgressState.Complete, error: 0}),
    startEdit: (state) => ({...state, edit: {state: ProgressState.InProgress, error: 0}}),
    errorEdit: (state, payload) => ({...state, edit: {state: ProgressState.Complete, error: payload}}),
    successEdit: (state, payload) => ({...state, data: payload, edit: {state: ProgressState.Complete, error: 0}}),

    openModal: (state) => ({
      ...state, open: !state.open,
    }),
    goToNewTurnoments: (state) => ({
      ...state, go: true,
    }),

    startOrganizations: (state) => ({
      ...state,
      organizations: {data: [], state: ProgressState.InProgress, error: 0},
    }),
    errorOrganizations: (state, payload) => ({
      ...state,
      organizations: {data: [], state: ProgressState.Complete, error: payload},
    }),
    successOrganizations: (state, payload) => ({
      ...state,
      organizations: {data: payload, state: ProgressState.Complete, error: 0},
    }),

    startSearch: (state) => ({
      ...state,
      search: {data: [], state: ProgressState.InProgress, error: 0},
    }),
    errorSearch: (state, payload) => ({
      ...state,
      search: {data: [], state: ProgressState.Complete, error: payload},
    }),
    successSearch: (state, payload) => ({
      ...state,
      search: {data: payload, state: ProgressState.Complete, error: 0},
    }),
    resetSearch: (state) => ({
      ...state,
      search: {data: [], state: ProgressState.NotStarted, error: 0},
    }),

    startTournamentsPlaying: (state) => ({
      ...state,
      tournamentsPlaying: {data: [], state: ProgressState.InProgress, error: 0},
    }),
    errorTournamentsPlaying: (state, payload) => ({
      ...state,
      tournamentsPlaying: {data: [], state: ProgressState.Complete, error: payload},
    }),
    successTournamentsPlaying: (state, payload) => ({
      ...state,
      tournamentsPlaying: {data: payload, state: ProgressState.Complete, error: 0},
    }),
    resetTournamentsPlaying: (state) => ({
      ...state,
      tournamentsPlaying: {data: [], state: ProgressState.NotStarted, error: 0},
    }),

    startTournamentsOrganizing: (state) => ({
      ...state,
      tournamentsOrganizing: {data: [], state: ProgressState.InProgress, error: 0},
    }),
    errorTournamentsOrganizing: (state, payload) => ({
      ...state,
      tournamentsOrganizing: {data: [], state: ProgressState.Complete, error: payload},
    }),
    successTournamentsOrganizing: (state, payload) => ({
      ...state,
      tournamentsOrganizing: {data: payload, state: ProgressState.Complete, error: 0},
    }),
    resetTournamentsOrganizing: (state) => ({
      ...state,
      tournamentsOrganizing: {data: [], state: ProgressState.NotStarted, error: 0},
    }),

    startNotifications: (state) => ({
      ...state,
      notifications: {data: state.notifications.data, state: ProgressState.InProgress, error: 0},
    }),
    errorNotifications: (state, payload) => ({
      ...state,
      notifications: {data: state.notifications.data, state: ProgressState.Complete, error: payload},
    }),
    successNotifications: (state, payload) => ({
      ...state,
      notifications: {data: payload, state: ProgressState.Complete, error: 0},
    }),

    startSuperAdmin: (state) => ({
      ...state,
      superAdmin: {data: state.superAdmin.data, state: ProgressState.InProgress, error: 0},
    }),
    errorSuperAdmin: (state, payload) => ({
      ...state,
      superAdmin: {data: state.superAdmin.data, state: ProgressState.Complete, error: payload},
    }),
    successSuperAdmin: (state, payload) => ({
      ...state,
      superAdmin: {data: payload, state: ProgressState.Complete, error: 0},
    }),

  },

  effects: {

    async init() {
      this.startInit()
      const response = await get('/api/user')
      if (response.ok) {
        this.successInit(response.body)
        this.loadNotifications()
      } else {
        this.errorInit(response.status)
      }
    },

    async edit(payload) {
      this.startEdit()
      const response = await post(`/api/user/edit`, payload)
      if (response.ok) {
        this.successEdit(response.body)
      } else {
        this.errorEdit(response.status)
      }
    },

    async loadOrganizations(payload, rootState) {
      if (!rootState.user.data.id || rootState.user.organizations.data.length > 0) return
      this.startOrganizations()
      const response = await get(`/api/organization/my`)
      if (response.ok) {
        this.successOrganizations(response.body)
      } else {
        this.errorOrganizations(response.status)
      }
    },

    async search(name) {
      if (name.left < 2) return
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(async () => {
        this.startSearch()
        const response = await get(`/api/user/search/${name}`)
        if (response.ok) {
          this.successSearch(response.body)
        } else {
          this.errorSearch(response.status)
        }
      }, 1000)
    },

    async loadTournamentPlaying(payload = {}) {
      this.startTournamentsPlaying()
      const response = await get(`/api/user/playing-tournaments?` +
        Object.entries(payload).map((i) => i.join('=')).join('&'))
      if (response.ok) {
        this.successTournamentsPlaying(response.body)
      } else {
        this.errorTournamentsPlaying(response.status)
      }
    },

    async loadTournamentOrganizing() {
      this.startTournamentsOrganizing()
      const response = await get(`/api/user/organizing-tournaments`)
      if (response.ok) {
        this.successTournamentsOrganizing(response.body)
      } else {
        this.errorTournamentsOrganizing(response.status)
      }
    },

    async logout() {
      const response = await get(`/api/user/logout`)
      if (response.ok) {
        document.location.pathname = '/'
      } else {

      }
    },

    async loadNotifications(payload, rootState) {
      if (!rootState.user.data.id) return
      if (rootState.user.notifications.state === ProgressState.InProgress) return
      this.startNotifications()
      const response = await get(`/api/user/notifications`)
      if (response.ok) {
        this.successNotifications(response.body)
      } else {
        this.errorNotifications(response.status)
      }
    },

    async readNotifications(payload, rootState) {
      if (!rootState.user.data.id) return
      const data = rootState.user.notifications.data.filter(n => !n.delivered).map(n => n.id)
      if (data.length === 0) return
      this.startNotifications()
      const response = await post(`/api/user/notifications/read`, data)
      if (response.ok) {
        this.successNotifications(response.body)
      } else {
        this.errorNotifications(response.status)
      }
    },

    async loadSuperAdmin() {
      this.startSuperAdmin()
      const response = await get(`/api/user/super-admin`)
      if (response.ok) {
        this.successSuperAdmin(response.body)
      } else {
        this.errorSuperAdmin(response.status)
      }
    },

    async setSuperAdmin(userId) {
      this.startSuperAdmin()
      const response = await get(`/api/user/super-admin/${userId}`)
      if (response.ok) {
        this.successSuperAdmin(response.body)
      } else {
        this.errorSuperAdmin(response.status)
      }
    },

    async acceptInvitation(tournamentId, rootState) {
      const response = await get(`/api/tournament/${tournamentId}/add-player/accept`)
    },
  },
}

