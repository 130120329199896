import styled from 'styled-components'
import border from '../../../assets/border/gold_line.png'
import borderCard from '../../../assets/abouts/border-card.png'
import { Button as OldButton } from 'reactstrap'
import { Link } from 'react-router-dom'

export const Container = styled.div`
	margin-top: 0;
`

export const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`

export const Wrapper = styled.div`
	position: relative;
	margin-top: 33px;
	margin-bottom: 35px;
`

export const Text = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: #FFFFFF;
	margin-bottom: 10px;
`

export const Card = styled.div`
	position: relative;
	height: 447px;
  display: flex;
  align-items: center;
  margin-bottom: ${ props => props.group ? '-84px' : '0' };
	&:before {
		content: '';
		background: url(${borderCard}) no-repeat;
		height: 447px;
    width: 420px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}
  @media (max-width: 1000px) {
    height: auto;
    margin-bottom: 20px;
    &:before {
      display: none;
    }
  }
`

export const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
`

export const ImgCenter = styled.div`
    max-width: 500px;
    margin: 10px auto 20px auto;
`

export const Border = styled.div`
	background: url(${border}) no-repeat;
	display: flex;
	flex: 1 1;
	height: 46px;
  background-size: contain;
  margin: 20px 0;
`

export const ImgContainer = styled.div`
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const TextContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	max-width: 425px;
	width: 100%;
	margin: 0 auto;
`

export const VideoContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`

export const Video = styled.video`
	width: 100%;
	max-width: 948px;
	height: 531px;
	margin-bottom: 43px;
	cursor: pointer;
`

export const TextVideo = styled.div`
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #FFFFFF;
	max-width: 859px;
	margin: 0 auto 35px;
`

export const Button = styled.a`
	max-width: 480px;
	width: 100%;
	height: 116px;
	font-size: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  &:hover {
    color: #000;
  }
`

export const ButtonWrapper = styled.div`
	margin-top: 35px;
	margin-bottom: 100px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`

export const Strong = styled.strong`
  font-weight: bold;
  color: #d7be5e;
`
