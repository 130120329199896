import React, { Component } from 'react'
import MaterialIcon from '@material/react-material-icon'
import { NavLink } from 'react-router-dom'
import { LOGIN, PROFILE_SETTINGS } from '../routes'
import { Avatar } from '../avatar/index'
import Increase from '../increase/Increase'
import { connect } from 'react-redux'
import { NOTIFICATION_RELOAD_INTERVAL } from '../../constants/timers'

const mapState = ({user}) => ({
  user,
})
const mapDispatch = ({user: {openModal, successInit, loadNotifications, readNotifications}}) => ({
  openModal,
  successInit,
  loadNotifications,
  readNotifications,
})

class User extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sOpen: 1,
      showBadge: false,
      data: {
        data: [],
      },
      lastData: {
        data: [],
      },
      socketData: [],
    }
    this.notificationTick()
    this.notificationTimer = setInterval(this.notificationTick, NOTIFICATION_RELOAD_INTERVAL)
  }

  componentWillUnmount() {
    clearInterval(this.notificationTimer)
  }

  notificationTick = () => {
    this.props.loadNotifications();
  }

  handelClick = async () => {
    const {open} = this.props.user
    await this.setState({open, sOpen: this.state.sOpen + 1})
    await this.props.openModal()
    this.props.readNotifications()
  }

  render() {
    const {userData} = this.props
    const {open, notifications: {data}} = this.props.user
    const showBadge = data.some(n => !n.delivered)

    return (
      <div className='main-header__user'>
        {userData.id ? [
          <button onClick={this.handelClick} key={userData.id} className='bt'>
            <>
              <MaterialIcon key='icon' icon='notifications_active'
                            className='main-header__user-notification'/>
              <span className={showBadge ? 'unread' : 'nounread'}/>
            </>
          </button>,
          <NavLink to={PROFILE_SETTINGS} key='name' className='main-header__item'>{userData.name}</NavLink>,
          <NavLink to={PROFILE_SETTINGS} key='avatar' className='main-header__item _avatar'>
            <Avatar className='main-header__user-avatar' avatar={userData.avatar} border={userData.border}/>
          </NavLink>,
          open ? <Increase key={this.state.sOpen} name={'hello'} data={data}/> : null,
        ] : [
          <NavLink key='login' to={LOGIN} className='main-header__item _login'>Login</NavLink>,
          <div key='avatar' className='main-header__item _avatar'>
            <Avatar className='main-header__user-avatar'/>
          </div>
        ]}
      </div>
    )
  }
}

const Container = connect(mapState, mapDispatch)(User)
export default Container
