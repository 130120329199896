import React from 'react'
import { LayoutFullScreen } from '../layouts/full-screen'
import { Link, NavLink } from 'react-router-dom'
import { tournament, tournamentEdit, tournamentMatch } from '../../routes'
import { Route, Switch } from 'react-router-dom'
import { TournamentPageDescription } from './description-page'
import { TournamentPageParticipants } from './participants-page'
import { TournamentPageBrackets } from './brackets'
import { TournamentPageLogs } from './logs'
import { TournamentPageMatch } from './match-page'
import ProfileCard from './profile-card'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { Button } from 'reactstrap'
import { Bracket, TournamentState } from '../../../constants/tournament'
import { ProgressState } from '../../../constants/progress-state'
import { TOURNAMENT_RELOAD_INTERVAL } from '../../../constants/timers'
import { TournamentProfile } from './profile'
import memoize from 'lodash/memoize'
import Modal from '../../atoms/modal'
import { ModalText, ModalButtonWrapper } from '../../atoms/modal/styles'
import {getTournamentId} from "../../../utils/tournament";

const tz = moment.tz.guess()

const mapState = ({tournament}, ownProps) => ({
  ...ownProps,
  tournament,
})
const mapDispatch = ({tournament}) => ({
  load: tournament.load,
  reLoad: tournament.reLoad,
  start: tournament.start,
  addPlayers: tournament.addPlayers,
  resetError: tournament.resetErrorLoad,
})

class CTournamentPage extends React.PureComponent {
  constructor(props) {
    super(props)
    props.load(props.match.params.id)
    this.refreshTimer = null
  }

  componentDidMount() {
    this.refreshTimer = setInterval(this.refreshTimerTick, TOURNAMENT_RELOAD_INTERVAL)
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer)
  }

  refreshTimerTick = () => {
    const {data: {isEditable, state}} = this.props.tournament
    if (/brackets$/.test(document.location.pathname) && isEditable && state === TournamentState.Publish) {
      return
    }
    this.props.reLoad(this.props.match.params.id)
  }

  onStart = (e) => {
    this.props.start({id: this.props.match.params.id, force: !!e.target.dataset.force})
  }

  onAddPlayers = () => {
    this.props.addPlayers(this.props.match.params.id)
  }
  getStyle = memoize(img => ({backgroundImage: `url(/tournament-images/${img})`}))

  renderPage() {
    const {data} = this.props.tournament
    if (!data.id) return null
    const {pathname} = this.props.location
    const getPageUrl = pathname.split('/')[pathname.split('/').length - 1]
    const isPageProfileCard = getPageUrl === 'profilecard'
    let date = moment(data.dateStart).tz(tz)
    const started = data.isComplete ? 'Finished' : `${date.isAfter(moment()) ? 'Starts' : 'Started'} ${date.fromNow()}`
    const tournamentId = getTournamentId(data);
    return (
      <section className='tournament'>
        <div className='container'>
          <header className={`tournament-header ${data.featured < 100 ? '_featured' : ''}`} style={this.getStyle(data.image || '000.png')}>
            <div className='tournaments-header__remained'>
              {data.state === TournamentState.Archive ? 'Archived' : started}
            </div>
            <div className='tournament-header__date'>
              <div className='tournament-header__date-month'>{date.format('MMM')}</div>
              <div className='tournament-header__date-day'>{date.format('D')}</div>
            </div>
            <div>
              <div className='tournament-header__name'>{data.name}</div>
              <div className='tournament-header__time'>{date.format('dddd, HH:mm z')}</div>
            </div>
          </header>

          <TournamentProfile/>

          <nav className='tournament-nav'>
            <NavLink exact to={tournament(tournamentId)} className='tournament-nav__item'>Overview</NavLink>
            <NavLink to={tournament(tournamentId, 'participants')} className='tournament-nav__item'>Players</NavLink>
            <NavLink to={tournament(tournamentId, 'brackets')} className='tournament-nav__item'>Brackets</NavLink>
            {data.isEditable && (
              <NavLink to={tournament(tournamentId, 'logs')} className='tournament-nav__item mr-auto'>Logs</NavLink>
            )}
            {this.renderAdminButtons()}
          </nav>
        </div>

        <Switch>
          <Route exact path={tournament()} component={TournamentPageDescription}/>
          <Route path={tournament(null, 'participants')} component={TournamentPageParticipants}/>
          <Route path={tournament(null, 'brackets')} component={TournamentPageBrackets}/>
          <Route path={tournament(null, 'logs')} component={TournamentPageLogs}/>
          {/*<Route path={tournament(null, 'profilecard')} component={ProfileCard}/>*/}
          <Route path={tournamentMatch()} component={TournamentPageMatch}/>
        </Switch>
        {(data.state === TournamentState.Publish || data.state === TournamentState.PreStart) && getPageUrl === 'brackets' && (
          <div className='container'>
            <nav className='tournament-nav'>
              <div className='ml-auto'>
                {this.renderAdminButtons()}
              </div>
            </nav>
          </div>
        )}
        {this.renderErrorModal()}
      </section>
    )
  }

  renderAdminButtons() {
    const {data, seeding} = this.props.tournament
    if (!data.isEditable) return null

    const tournamentId = getTournamentId(data);
    const result = [
      <Link key='editBtn' to={tournamentEdit(tournamentId)} className='btn btn-light'>Edit tournament</Link>,
    ]


    if (data.state === TournamentState.Publish || data.state === TournamentState.PreStart) {
      let text = 'Start tournament'
      if (data.state === TournamentState.Publish &&
        (data.bracketType === Bracket.Single || data.bracketType === Bracket.Double)) {
        text = 'Activate bracket'
      }
      if (/brackets$/.test(document.location.pathname)) {
        let disabled = !data.isReadyToStart
        if (data.manualSeeding) {
          let seeded = []
          for (let bracket of seeding) {
            for (let i = 0; i < bracket.length; i++) {
              let match = bracket[i]
              if (match[0]) seeded.push(match[0].id)
              if (match[1]) seeded.push(match[1].id)
            }
          }
          disabled = seeded.length === 0 || seeded.length !== data.participants.length
          if (data.state === TournamentState.PreStart) {
            disabled = false
          }
        }
        result.push(
          <Button key='startBtn' color='primary' disabled={disabled} onClick={this.onStart}>
            {text}
          </Button>,
        )
        if (data.playersCount === 0) {
          /*result.push(
            <Button key='addPLayersBtn' color='warning' onClick={this.onAddPlayers}>
              Add test players
            </Button>,
          )*/
        }
      } else {
        result.push(
          <Link key='startLink' to={tournament(tournamentId, 'brackets')} className='btn btn-primary'>
            Start tournament
          </Link>,
        )
      }
    }

    return result
  }

  renderErrorModal = () => {
    const {error, errorMessage, data} = this.props.tournament
    const {resetError} = this.props
    let message = ''
    let buttons = []
    let title = `Tournament "${data.name}"`
    if (error === 410) {
      message = errorMessage.map(item => (<div>{item.player.name} - {item.message}</div>))
      message.push(<div>Are you sure?</div>)
      buttons = [
        <Button key={0} color='primary' onClick={resetError}>no, i change my mind</Button>,
        <Button key={1} color='light' data-force='1' onClick={this.onStart}>yes, start</Button>,
      ]
    } else if (error === 405) {
      message = 'Minimum 4 players'
      buttons.push(
        <Button key={0} color='primary' onClick={resetError}>Ok</Button>,
      )
    } else if (error === 4031) {
      message = `Maximum ${data.playersLimit} players`
      buttons.push(
        <Button key={0} color='primary' onClick={resetError}>Ok</Button>,
      )
    } else if (error === 401) {
      message = `${errorMessage}. Please reload the page and login again`
      buttons.push(
          <Button key={0} color='primary' onClick={resetError}>Ok</Button>,
      )
    }

    return (
      <Modal open={message.length > 0} title={title}>
        <ModalText>{message}</ModalText>
        <ModalButtonWrapper>{buttons}</ModalButtonWrapper>
      </Modal>
    )
  }

  render() {
    const {data} = this.props.tournament
    return (
      <LayoutFullScreen className='tournament-page'>
        {data ? this.renderPage() : null}
      </LayoutFullScreen>
    )
  }
}

export const TournamentPage = connect(mapState, mapDispatch)(CTournamentPage)
