import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { ABOUT, TOURNAMENT_CREATE, MY_TOURNAMENTS, SUPER_ADMIN } from '../routes'
import { connect } from 'react-redux'
import User from './User'
import logo from '../../assets/header/title-bar-logo-en.png'
import {BETA_VERSION} from '../../constants/beta'

const mapState = ({user}) => ({
  user,
})

class CHeader extends Component {
  state = {
    showMobile: false,
  }

  onMobileToggle = () => this.setState({showMobile: !this.state.showMobile})

  mainHeader() {
    const user = this.props.user.data || {}

    return (
      <header className='main-header'>
        <div className='container'>
          {BETA_VERSION && <div className='main-header__beta'>BETA v{BETA_VERSION}</div>}
        <nav>
          <NavLink exact to='/' className='main-header__logo'>&nbsp;</NavLink>
          <a href={TOURNAMENT_CREATE} className='btn btn-fancy mr-auto'>
            Organize tournament
          </a>
          <NavLink to='/' className='main-header__item'>Tournaments</NavLink>
          <NavLink to={ABOUT} className='main-header__item'>About</NavLink>
          {user.isSuperAdministrator && (
            <NavLink to={SUPER_ADMIN} className='main-header__item'>Admins</NavLink>
          )}
          {user.id && (
            <NavLink key='tournaments' to={MY_TOURNAMENTS} className='main-header__item'>
              My tournaments
            </NavLink>
          )}
          <User userId={user.id} userData={user}/>
        </nav>
        </div>
      </header>
    )
  }

  mobileHeader() {
    const user = this.props.user.data || {}

    return (
      <header className='header-mobile'>
        <div className='header-mobile__wrap'>
          <div className='header-mobile__toggle' onClick={this.onMobileToggle}>
            <div className='header-mobile__toggle-bar'/>
            <div className='header-mobile__toggle-bar'/>
            <div className='header-mobile__toggle-bar'/>
          </div>
          <User userId={user.id} userData={user}/>
        </div>
        <div className={`header-mobile__menu ${this.state.showMobile ? '_active' : ''}`}>
          <div className='header-mobile__close' onClick={this.onMobileToggle}>
            <div className='header-mobile__close-bar'/>
            <div className='header-mobile__close-bar'/>
          </div>
          <a href='/' className='header-mobile__logo'><img src={logo} alt='logo'/></a>
          <a href={TOURNAMENT_CREATE} className='header-mobile__menu-link'>
            Organize tournament
          </a>
          <Link to='/' className='header-mobile__menu-link'>Tournaments</Link>
          <Link to={ABOUT} className='header-mobile__menu-link'>About</Link>
          {user.isSuperAdministrator && (
            <Link to={SUPER_ADMIN} className='header-mobile__menu-link'>Admins</Link>
          )}
          {user.id && (
            <Link key='tournaments' to={MY_TOURNAMENTS} className='header-mobile__menu-link'>
              My tournaments
            </Link>
          )}
        </div>
      </header>
    )
  }

  render() {
    return (
      <>
        {this.mainHeader()}
        {this.mobileHeader()}
      </>
    )
  }
}

export const Header = connect(mapState)(CHeader)
