import React from 'react'
import styled from 'styled-components'
import { Label } from '../dropDown/styles'

export default class ToggleButton extends React.PureComponent {
  handlerClick = (event) => {
    const {
      onChange,
      value,
      firstButtonValue = true,
      lastButtonValue = false,
      disabled = false,
    } = this.props
    event.preventDefault()
    if (disabled) return

    let result = {
      target: {
        id: event.currentTarget.id,
        value: value === firstButtonValue ? lastButtonValue : firstButtonValue,
      },
    }
    onChange(result)
  }

  render() {
    const {
      value,
      firstButton,
      lastButton,
      id,
      width = 100,
      label,
      labelStyle,
      firstButtonStyle,
      lastButtonStyle,
      firstButtonValue = true,
      lastButtonValue = false,
    } = this.props
    const widthToggle = width / 2
    return (
      <React.Fragment>
        {label && <Label style={labelStyle}>{label}</Label>}
        <ContainerToggleButton onClick={this.handlerClick} id={id} width={width}>
          <ToggleSwitch toggle={value === firstButtonValue} widthToggle={widthToggle}/>
          <Button toggle={value === firstButtonValue} id={id} firstButtonStyle={firstButtonStyle}>
            {firstButton}
          </Button>
          <Button toggle={value === lastButtonValue} id={id} lastButtonStyle={lastButtonStyle}>
            {lastButton}
          </Button>
        </ContainerToggleButton>
      </React.Fragment>
    )
  }
}

const ContainerToggleButton = styled.div`
	display: flex;
	border: 1px solid #949494;
	border-radius: 2px;
	width: ${ props => props.width ? `${props.width}px` : '100px' };
	justify-content: center;
	align-items: center;
	position: relative;
`

const ToggleSwitch = styled.div`
	background: #949494;
  border-radius: ${ props => props.toggle ? '2px 0 0 2px' : '0 2px 2px 0' };
  height: 30px;
	width: ${props => props.widthToggle ? `${props.widthToggle}px` : '50px'};
	position: absolute;
	left: 0;
	transition: transform 0.4s ease;
	transform: translateX(${ props => props.toggle ? '0' : '100%' });
	z-index: 1;
`

const Button = styled.button`
	height: 30px;
	outline: none;
	background: transparent;
	position: relative;
	z-index: 2;
	width: 50%;
  font-size: 18px;
	line-height: 16px;
	text-align: center;
	color: ${ props => props.toggle ? '#131313' : '#949494'};
	min-width: auto;
	cursor: pointer;
	border: none;
	-webkit-appearance: none;
	text-transform: uppercase;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
	&:focus {
		outline: none;
	}
`
