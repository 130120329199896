import React from 'react'
import { LEADERS } from '../../../../constants/cards'
import Modal from '../../../atoms/modal'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { WYSIWYG } from '../../../wysiwyg'
import { onChange } from '../../../../utils/on-change'
import MaterialIcon from '@material/react-material-icon'

export class ProblemModal extends React.PureComponent {
  state = {
    text: '',
  }

  onApply = () => {
    if (this.props.onApply) {
      this.props.onApply(this.state.text)
    }
    this.setState({text: ''})
  }

  onChange = onChange(result => this.setState({[result.name]: result.value}))

  render() {
    const {text} = this.state

    return (
      <Modal open={true}
             height={320}
             width={500}
             title={`Report problem`}
             opacity='0.87'>
        <IconWrapper>
          <MaterialIcon icon='close' title='Close' onClick={this.props.onClose}/>
        </IconWrapper>
        <WYSIWYG id='text'
                 placeholder='your problem'
                 value={text}
                 onChange={this.onChange.string}/>

        <ButtonWrapper>
          <Button color='primary' onClick={this.onApply}>apply</Button>
        </ButtonWrapper>
      </Modal>
    )
  }
}

const ButtonWrapper = styled.div`
	margin-top: 24px;
	> button {
		width: 100%;
	}
`

const IconWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	> .material-icons {
    cursor: pointer;
    color: #949494;
	}
`
