import React from 'react'
import {
  DropDowImgContainer,
  DropDownActionsContainer,
  DropDownContentActions,
  ButtonContainer,
  ImgContainerButton,
  TextButton,
} from './styles'
import DropDowImg from '../../ico/dropDown'
import { Button } from 'reactstrap'
import CloseButton from '../../ico/close-button'
import RevertButton from '../../ico/revert-button'
import EditButton from '../../ico/edit'

const DropDownActions = (props) => {
  const {
    dropDown,
    onClick,
    idDropDown,
    id,
    onDraft,
    onDelete,
    onEdit,
    showDelete = false,
    showArchive = false,
    showDraft = false,
    showEdit = false,
  } = props
  return (
    <DropDownActionsContainer dropDown={dropDown}>
      <DropDowImgContainer onClick={onClick}>
        <DropDowImg/>
      </DropDowImgContainer>
      {id === idDropDown && dropDown &&
      <DropDownContentActions>
        {showEdit && (
          <ButtonContainer>
            <Button color='dark' onClick={onEdit} data-tournamentid={id}>
              <ImgContainerButton>
                <EditButton/>
              </ImgContainerButton>
              <TextButton>
                edit
              </TextButton>
            </Button>
          </ButtonContainer>
        )}

        {showDelete && (
          <ButtonContainer>
            <Button color='dark' onClick={onDelete} data-tournamentid={id}>
              <ImgContainerButton>
                <CloseButton/>
              </ImgContainerButton>
              <TextButton>
                Delete
              </TextButton>
            </Button>
          </ButtonContainer>
        )}

        {showArchive && (
          <ButtonContainer>
            <Button color='dark' onClick={onDelete} data-tournamentid={id}>
              <ImgContainerButton>
                <RevertButton/>
              </ImgContainerButton>
              <TextButton>
                Archive
              </TextButton>
            </Button>
          </ButtonContainer>
        )}

        {showDraft && (
          <ButtonContainer>
            <Button color='dark' onClick={onDraft} data-tournamentid={id}>
              <ImgContainerButton>
                <RevertButton/>
              </ImgContainerButton>
              <TextButton>
                convert to draft
              </TextButton>
            </Button>
          </ButtonContainer>
        )}


      </DropDownContentActions>
      }
    </DropDownActionsContainer>
  )
}

export default DropDownActions
