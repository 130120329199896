import React from 'react'
import { LEADERS } from '../../../../constants/cards'
import Modal from '../../../atoms/modal'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { FilterButton } from '../../../tournament-search/styles'
import isEqual from 'lodash/isEqual'
import { DeckRegister } from '../../../../constants/tournament'

export class GameModal extends React.PureComponent {
  state = {
    game: null,
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.game, this.props.game)) {
      this.setState({game: this.props.game})
    }
  }

  onResultClick = (e) => {
    const result = Number(e.currentTarget.dataset.result)
    this.setState({game: {...this.state.game, result}})
  }

  onApply = () => {
    if (this.state.game && this.props.onApply) {
      this.props.onApply(this.state.game)
    }
    this.setState({game: null})
  }

  getResult(result, isRevert) {
    return result * (isRevert ? -1 : 1)
  }

  onLeaderClick = e => {
    const {players} = this.props
    const playerId = e.target.dataset.playerid
    const leaderId = Number(e.target.dataset.leaderid)
    const idx = players.findIndex(p => p.id === playerId) + 1
    this.setState({game: {...this.state.game, ['leader' + idx]: leaderId}})
  }

  onDeckClick = e => {
    const {players} = this.props
    const playerId = e.target.dataset.playerid
    const deckId = Number(e.target.dataset.deckid)
    const leaderId = Number(e.target.dataset.leaderid)
    const idx = players.findIndex(p => p.id === playerId) + 1
    this.setState({game: {...this.state.game, ['deck' + idx]: {id: deckId, leaderId}}})
  }

  render() {
    const {game, pickAndBans} = this.state
    const {players} = this.props
    if (!game || !players || players.length === 0) return null
    return (
      <Modal open={true}
             height={535}
             width={308}
             title={`Game ${game.ordinal + 1} results`}
             opacity='0.87'>
        {players && players.map((player, key) => {
          let isRevert = key === 1
          let width = false;
          if (pickAndBans) {
            width = player.decks.length > 0 ?
              (player.decks.length * 60)
              : (player.leaders.length > 0 ? (player.leaders.length * 60) : false)
          }
          return (
            <ContainerCard key={key} revert={key === 1}>
              <UserName>{player.name}</UserName>
              <Cards revert={key === 1}>
                <CardsWrapper width={width}>
                  {this.renderLeaders(player, game['deck' + (key + 1)], game['leader' + (key + 1)])}
                </CardsWrapper>
              </Cards>
              <ButtonContainer revert={isRevert}>
                <FilterButton active={game.result === this.getResult(1, isRevert)}
                              data-result={this.getResult(1, isRevert)}
                              onClick={this.onResultClick}>
                  WIN
                </FilterButton>
                <FilterButton active={game.result === this.getResult(-1, isRevert)}
                              data-result={this.getResult(-1, isRevert)}
                              onClick={this.onResultClick}>
                  LOSS
                </FilterButton>
                {/*<FilterButton active={game.result === this.getResult(0, isRevert)}
                              data-result={this.getResult(0, isRevert)}
                              onClick={this.onResultClick}>
                  DRAW
                </FilterButton>*/}
              </ButtonContainer>
              <Border hide={key === 1}/>
            </ContainerCard>
          )
        })}
        <ButtonWrapper>
          <Button color='primary' onClick={this.onApply}>apply</Button>
        </ButtonWrapper>
      </Modal>
    )
  }

  renderLeaders(player, selectedDeck, leaderId) {
    const {pickAndBans, deckRegister} = this.props
    if (!pickAndBans) {
      return LEADERS.map(id => (
        <Img key={id} className={`leader-ability _${id}`}
             active={id === leaderId}
             data-playerid={player.id}
             data-leaderid={id}
             onClick={this.onLeaderClick}/>
      ))
    }

    if (deckRegister === DeckRegister.LeaderAndDeck) {
      return player.decks.map(deck => (
        <Img key={deck.id} className={`leader-ability _${deck.leaderId}`}
             active={selectedDeck && selectedDeck.id === deck.id}
             data-playerid={player.id}
             data-deckid={deck.id}
             data-leaderid={deck.leaderId}
             onClick={this.onDeckClick}/>
      ))
    }
  }
}

const UserName = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #F2F2F2;
`

const ContainerCard = styled.div`
  display: flex;
  flex-direction: ${ props => props.revert ? 'column-reverse' : 'column' };
  > div:first-child {
  	margin-bottom: ${ props => props.revert ? '0' : '8px' };
  }
`

const CardsWrapper = styled.div`
  	width: ${ props => props.width ? `${props.width}px` : '2150px' };
`
const Cards = styled.div`
  	overflow-x: auto;
  	margin-top: ${ props => props.revert && '24px' };
   	margin-bottom: ${ props => props.revert ? '8px' : '24px' };
`

const Img = styled.div`
    width: 48px;
    height: 64px;
    margin-right: 12px;
    display: inline-block;
    cursor: pointer;
    border: ${props => props.active && '1px solid #D7BE5E'};
    &:last-child {
      margin-right: 0;
    }
`

const ButtonContainer = styled.div`
	display: flex;
	margin-bottom: ${ props => props.revert ? '8px' : '24px' };
`

const Border = styled.div`
	border: ${ props => props.hide ? 'none' : '1px solid #292618'};
	margin-bottom: ${ props => props.hide && '24px'};
`

const ButtonWrapper = styled.div`
	margin-top: 24px;
	> button {
		width: 100%;
	}
`
