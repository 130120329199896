import React from 'react';
import styled from 'styled-components';
import { Label as OldLabel, Input as OldInput } from 'reactstrap';
import CheckImg from '../../ico/checkbox';

const CheckBox = (props) => {
	const { text, onChange, name, id, checked } = props;
	return (
		<Label htmlFor={id}>
			<CheckBoxButton checked={checked}>
				{ checked && <CheckImg/> }
			</CheckBoxButton>
			<Input type='checkbox' name={name} id={id} onChange={onChange} checked={checked}/>
			<Text>{ text }</Text>
		</Label>
	);
};

export default CheckBox;

const Label = styled.label`
	position: relative;
`;

const CheckBoxButton = styled.div`
    width: 16px;
    height: 16px;
	position: absolute;
	left: 0;
    border: 1px solid #CBCBCB;
    border-radius: 2px;
    top: 3px;
    > svg {
    	position: absolute;
    	left: 50%;
    	top: 50%;
    	transform: translate(-50%, -50%);
    }
`;

const Input = styled(OldInput)`
	visibility: hidden;
	left: 19px;
`;

const Text = styled.div`
	padding-left: 23px;
	font-weight: normal;
`;
