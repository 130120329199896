import React from 'react';

export default () => {
	return (
		<svg width="13" height="13" viewBox="0 0 13 13" fill="none">
			<path d="M5.85 10.4H7.15V9.1H5.85V10.4ZM6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM6.5 11.7C3.6335 11.7 1.3 9.3665 1.3 6.5C1.3 3.6335 3.6335 1.3 6.5 1.3C9.3665 1.3 11.7 3.6335 11.7 6.5C11.7 9.3665 9.3665 11.7 6.5 11.7ZM6.5 2.6C5.0635 2.6 3.9 3.7635 3.9 5.2H5.2C5.2 4.485 5.785 3.9 6.5 3.9C7.215 3.9 7.8 4.485 7.8 5.2C7.8 6.5 5.85 6.3375 5.85 8.45H7.15C7.15 6.9875 9.1 6.825 9.1 5.2C9.1 3.7635 7.9365 2.6 6.5 2.6Z" fill="#C4C4C4"/>
		</svg>
	)
}

