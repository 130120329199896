import React from 'react'
import { connect } from 'react-redux'
import LayoutDefault from '../layouts/default'
import { ProgressState } from '../../../constants/progress-state'
import { OrgAvatar } from '../../avatar/org-avatar'
import { Button, Row, Tooltip } from 'reactstrap'
import { Link, Route, Switch, NavLink } from 'react-router-dom'
import { organizationEdit, organizationMembers, organization, tournamentMatch, tournament } from '../../routes'
import { Tournaments } from './tournaments'
import { Members } from './members'
import tviter from '../../../assets/edit-organisation/tvitter.png'
import fb from '../../../assets/edit-organisation/Fb.png'
import mail from '../../../assets/edit-organisation/mail.png'
import messag from '../../../assets/edit-organisation/message.png'
import Modal from '../../atoms/modal'
import { ModalButtonWrapper, ModalText } from '../../atoms/modal/styles'

const mapState = ({organization, tournamentsList}) => ({
  organization,
  tournamentsList,
})
const mapDispatch = ({organization, tournamentsList}) => ({
  load: organization.load,
  remove: organization.remove,
  tournamentsLoad: tournamentsList.load,
})

class COrganizationPage extends React.PureComponent {
  state = {
    discordTooltip: false,
    showModalRemove: false,
  }

  constructor(props) {
    super(props)
    props.load(props.match.params.id)
  }

  componentDidUpdate(prevProps) {

  }

  onRemoveClick = () => {
    this.props.remove(this.props.organization.data.id)
    this.onCloseModalRemove()
  }

  onShowModalRemove = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({showModalRemove: true})
  }

  onCloseModalRemove = () => {
    this.setState({showModalRemove: false})
  }

  render() {
    return (
      <LayoutDefault>
        {this.renderPage()}
      </LayoutDefault>
    )
  }

  discordTooltipToggle = () => {
    this.setState({discordTooltip: !this.state.discordTooltip})
  }

  renderPage() {
    const {organization: {state, data}} = this.props
    if (state !== ProgressState.Complete || !data.id) {
      return null
    }
    let discord = null
    if (data.discord) {
      if (/^https?:/.test(data.discord)) {
        discord = <a href={data.discord}><img src={messag} alt=''/></a>
      } else {
        discord = (
          <div>
            <img id='discord' src={messag} alt=''/>
            <Tooltip placement='top'
                     isOpen={this.state.discordTooltip}
                     target='discord'
                     autohide={false}
                     toggle={this.discordTooltipToggle}>

              {data.discord}
            </Tooltip>
          </div>
        )
      }
    }

    return (
      <div className='organization-page'>
        <div className='organization-page__head'>
          <OrgAvatar className='organization-page__avatar' src={data.avatar}/>
          <div>
            <h3 className='organization-page__name'>{data.name}</h3>
            <div className='organization-page__contacts'>
              {data.email ? (
                <a href={'mailto:' + data.email}><img src={mail} alt=''/></a>
              ) : null}
              {data.facebook ? (
                <a href={'https://www.facebook.com/' + data.facebook.replace('@', '')}>
                  <img src={fb} alt=''/>
                </a>
              ) : null}
              {data.twitter ? (
                <a href={'https://twitter.com/' + data.twitter.replace('@', '')}>
                  <img src={tviter} alt=''/>
                </a>
              ) : null}
              {discord}
            </div>
          </div>
          <div className='organization-page__description-wrapper'>
            <div className='organization-page__description'>{data.description}</div>
          </div>
        </div>

        <div className='organization-page__nav'>
          <NavLink to={organization(data.id)} className='organization-page__nav-item' activeClassName='active' exact>
            Tournaments
          </NavLink>
          <NavLink to={organizationMembers(data.id)} className='organization-page__nav-item' activeClassName='active'>
            Members
          </NavLink>
          {/*<a href='#' className='organization-page__nav-item'>Blocked players</a>*/}
          {data.isEditable && (
            <Link to={organizationEdit(data.id)} className='btn btn-primary organization-page__nav-button'>
              Edit
            </Link>
          )}
          {data.isEditable && (
            <Button color='danger' onClick={this.onShowModalRemove}>Remove</Button>
          )}
        </div>

        <Switch>
          <Route exact path={organization()} component={Tournaments}/>
          <Route path={organizationMembers()} component={Members}/>
        </Switch>
        {this.renderModalRemove()}
      </div>
    )
  }

  renderModalRemove() {
    const {organization: {state, data}} = this.props
    return (
      <Modal open={this.state.showModalRemove} title={`Remove organization "${data ? data.name : ''}"`} fixed={true}>
        <ModalText>Are you sure?</ModalText>
        <ModalButtonWrapper>
          <Button color='primary' onClick={this.onCloseModalRemove}>no, i change my mind</Button>
          <Button color='light' data-force='1' onClick={this.onRemoveClick}>yes, remove organization</Button>
        </ModalButtonWrapper>
      </Modal>
    )
  }
}

export const OrganizationPage = connect(mapState, mapDispatch)(COrganizationPage)
