import React from 'react'
import { PROFILE_SETTINGS, MY_ORGANISATION } from '../../routes'
import { NavLink, Nav } from '../organizing/styles'

export const ProfileMenu = () => (
    <Nav>
        <NavLink exact to={PROFILE_SETTINGS}>profile</NavLink>
        <NavLink to={MY_ORGANISATION}>my organizations</NavLink>
    </Nav>
)
