import React from 'react'
import LayoutDefault from '../layouts/default'
import { Row, Container, Col, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { connect } from 'react-redux'
import { TournamentCard } from '../../tournament-card'
import { Registration } from '../../../constants/tournament'
import { onChange } from '../../../utils/on-change'
import isEqual from 'lodash/isEqual'
import Search from '../../ico/search'
import NewInput from '../../atoms/input'

const mapState = ({tournamentsList}) => ({
  tournamentsList,
})
const mapDispatch = ({tournamentsList, tournament}) => ({
  load: tournamentsList.load,
  resetAll: tournament.resetAll,
})

class CMainPage extends React.PureComponent {
  state = {
    search: {
      time: 0,
      registration: -1,
      name: '',
    },
  }

  constructor(props) {
    super(props)
    props.load(this.state.search)
    props.resetAll()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.search, this.state.search)) {
      this.props.load(this.state.search)
    }
  }

  onChange = onChange(result => this.setState({search: {...this.state.search, [result.name]: result.value}}))

  render() {
    const data = this.props.tournamentsList.data
    const {time, registration, name} = this.state.search
    const result = data.sort((a, b) => a.featured - b.featured)
    return (
      <LayoutDefault className='main-page'>
        <Container className='tournament-search'>
          <Row>
            <Col lg={2} md={4}>
              <FormGroup>
                <Label for='time'>Time</Label>
                <Input type='select' id='time'
                       value={time}
                       onChange={this.onChange.int}>
                  <option value={-1}>Any</option>
                  <option value={0}>Upcoming</option>
                  <option value={1}>Started</option>
                </Input>
              </FormGroup>
            </Col>

            <Col lg={2} md={4}>
              <FormGroup>
                <Label for='registration'>Registration</Label>
                <Input type='select' id='registration'
                       value={registration}
                       onChange={this.onChange.int}>
                  <option value={-1}>Any</option>
                  <option value={Registration.Open}>Open</option>
                  <option value={Registration.Closed}>Closed</option>
                </Input>
              </FormGroup>
            </Col>

            <Col md={4} className='ml-auto'>
              <NewInput type='text' name='name'
                        title='Search'
                        icon='search'
                        placeholder='Search tournament by name'
                        value={name}
                        onChange={this.onChange.string}/>
            </Col>


          </Row>
        </Container>
        <Row tag='section' className='tournaments'>
          {result.map(t => <TournamentCard data={t} featured={t.featured < 100} key={t.id}/>)}
        </Row>
      </LayoutDefault>
    )
  }
}

export const MainPage = connect(mapState, mapDispatch)(CMainPage)
