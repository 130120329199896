import React from 'react'
import moment from 'moment-timezone'
import { Registration } from '../../../constants/tournament'
import { Link } from 'react-router-dom'
import { tournament } from '../../routes'
import { getTournamentId } from "../../../utils/tournament";

const tz = moment.tz.guess()

export const columns = [
	{
		name: 'Tournament name',
		id: 'name',
		size: 240,
		getData: (t) => <Link to={tournament( getTournamentId(t) )}>{t.name}</Link>
	},
	{
		name: 'Organisation',
		id: 'organisation',
		size: 180,
    getData: (t) => t.host.name
	},
	{
		name: 'Date and time',
		id: 'startDate',
		size: 180,
    getData: (t) => {
      let date = moment(t.dateStart).tz(tz)
			return date.format('MMMM D, HH:mm z')
		}
	},
	{
		name: 'Players',
		id: 'playersCount',
		size: 48,
    getData: (t) => t.playersCount
	},
	{
		name: 'Status',
		id: 'status',
		size: 103,
		getData: (t) => `${t.registration === Registration.Closed ? 'Closed' : 'Open'} registration`
	},
	{
		name: 'Actions',
		id: 'actions',
		size: 240
	},
];

export const rows = [
	{
		tournamentName: 'International Worlds Open Quallifiers #4 ',
		organisation: 'Raketa Gaming',
		dateTime: 'August 21, 21:00 MSK',
		players: '127',
		status: 'Registration open'
	},
	{
		tournamentName: 'International Worlds Open Quallifiers #4 ',
		organisation: 'Raketa Gaming',
		dateTime: 'August 21, 21:00 MSK',
		players: '127',
		status: 'Registration open'
	},
	{
		tournamentName: 'International Worlds Open Quallifiers #4 ',
		organisation: 'Raketa Gaming',
		dateTime: 'August 21, 21:00 MSK',
		players: '127',
		status: 'Registration open'
	},
	{
		tournamentName: 'International Worlds Open Quallifiers #4 ',
		organisation: 'Raketa Gaming',
		dateTime: 'August 21, 21:00 MSK',
		players: '127',
		status: 'Registration open'
	},
	{
		tournamentName: 'International Worlds Open Quallifiers #4 ',
		organisation: 'Raketa Gaming',
		dateTime: 'August 21, 21:00 MSK',
		players: '127',
		status: 'Registration open'
	},
	{
		tournamentName: 'International Worlds Open Quallifiers #4 ',
		organisation: 'Raketa Gaming',
		dateTime: 'August 21, 21:00 MSK',
		players: '127',
		status: 'Registration open'
	},
];
