import React from 'react'
import { connect } from 'react-redux'
import LayoutDefault from '../layouts/default'
import { LOGIN } from '../../routes'
import { Button, Col, Input, Row, Table } from 'reactstrap'
import { Avatar } from '../../avatar'
import MaterialIcon from '@material/react-material-icon'
import { TournamentCard } from '../../tournament-card'
import moment from 'moment-timezone'
import {getTournamentId} from "../../../utils/tournament";

const tz = moment.tz.guess()

const mapState = ({user, tournamentsList}) => ({
  user,
  tournamentsList,
})
const mapDispatch = ({user, tournamentsList}) => ({
  loadSuperAdmin: user.loadSuperAdmin,
  setSuperAdmin: user.setSuperAdmin,
  search: user.search,
  resetSearch: user.resetSearch,
  loadTournaments: tournamentsList.load,
  getFeatured: tournamentsList.getFeatured,
  setFeatured: tournamentsList.setFeatured,
  resetTournaments: tournamentsList.reset,
})

class CSuperAdmin extends React.PureComponent {
  state = {
    userSearch: '',
    tournamentSearch: '',
    tournamentSlot: '1',
  }

  constructor(props) {
    super(props)
    props.loadSuperAdmin()
    props.getFeatured()
  }

  componentDidUpdate() {
    const {history, user} = this.props
    if (!user.identified()) {
      history.push(LOGIN)
    }
    if (!user.data.isSuperAdministrator) {
      history.push('/')
    }
  }

  onUserChange = (e) => {
    const value = e.target.value
    this.setState({userSearch: value})
    this.props.resetSearch()
    this.props.search(value)
  }

  onUserSelect = (e) => {
    this.setState({userSearch: ''})
    this.props.resetSearch()
    this.props.setSuperAdmin(e.target.dataset.userId)
  }

  onUserRemove = (e) => {
    this.props.setSuperAdmin(e.target.dataset.userId)
  }

  onTournamentChange = (e) => {
    const value = e.target.value
    const slot = e.target.dataset.slot
    this.setState({tournamentSearch: value, tournamentSlot: slot})
    this.props.resetTournaments()
    this.props.loadTournaments({name: value})
  }

  onTournamentSelect = (e) => {
    this.setState({tournamentSearch: ''})
    const slot = e.target.dataset.slot
    const tournamentId = e.target.dataset.tournamentId
    this.props.resetTournaments()
    this.props.setFeatured({slot, tournamentId})
  }

  onTournamentRemove = (e) => {
    const slot = e.target.dataset.slot
    this.props.setFeatured({slot, tournamentId: 0})
  }

  render() {
    const {data: user, superAdmin} = this.props.user
    const search = this.props.user.search.data
    const {userSearch} = this.state
    if (!user.id) return null

    return (
      <LayoutDefault className='super-admin'>
        <h2>Super administrators</h2>

        <Row className='mb-4 position-relative'>
          <Col md={5}>
            <label htmlFor='user__add'>Add user</label>
            <Input type='text' id='user__add' placeholder='Username'
                   value={userSearch}
                   onChange={this.onUserChange}/>
            {search.length > 0 && userSearch && (
              <div className='user-search__list'>
                {search.map(
                  u => <div className='user-search__list-item' key={u.id} data-user-id={u.id}
                            data-user-name={u.name}
                            onClick={this.onUserSelect}>
                    {u.name}
                  </div>)}
              </div>
            )}
          </Col>
        </Row>

        <Table className='super-admin__table'>
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {superAdmin.data && superAdmin.data.map((user, idx) => this.renderTr(user, idx))}
          </tbody>
        </Table>

        <h2>Featured tournaments</h2>
        {this.renderTournament('1')}
        {this.renderTournament('2')}
      </LayoutDefault>
    )
  }

  renderTr(user, idx) {
    return (
      <tr key={idx}>
        <td>{idx + 1}</td>
        <td>
          <Avatar className='super-admin__avatar' avatar={user.avatar} border={user.border}/>
          {user.name}
        </td>
        <td><a href={`mailto:${user.email}`}>{user.email}</a></td>
        <td>
          <MaterialIcon id={'mi-delete' + idx} icon='close' title='delete' data-user-id={user.id}
                        onClick={this.onUserRemove}/>
        </td>
      </tr>
    )
  }

  renderTournament(slot) {
    const {tournamentSearch, tournamentSlot} = this.state
    const {data, featured} = this.props.tournamentsList
    const current = featured.data.find(f => f.id === Number(slot))
    let date = current ? moment(current.dateUpdate).tz(tz) : null
    return (
      <div key={slot} className='super-admin__featured'>
        <Row className='position-relative'>
          <Col md={5}>
            <label htmlFor={`tournament__add-${slot}`}>Slot {slot}</label>
            <Input type='text' id={`tournament__add-${slot}`} placeholder='Tournament name'
                   value={tournamentSlot === slot ? tournamentSearch : ''}
                   data-slot={slot}
                   onChange={this.onTournamentChange}/>
            {data.length > 0 && tournamentSearch && tournamentSlot === slot && (
              <div className='user-search__list'>
                {data.map(
                  t => <div className='user-search__list-item' key={t.id}
                            data-tournament-id={getTournamentId(t)}
                            data-slot={slot}
                            onClick={this.onTournamentSelect}>
                    {t.name}
                  </div>)}
              </div>
            )}
          </Col>
        </Row>
        {current ? (
          <>
            <div className='super-admin__featured-info'>
              <Avatar className='super-admin__avatar' avatar={current.author.avatar} border={current.author.border}/>
              {current.author.name} {date.format('HH:mm, DD MMM YYYY')}{' '}
              <Button color='light' data-slot={slot} onClick={this.onTournamentRemove}>clear</Button>
            </div>
            <Row>
              {current.tournament ? (
                <TournamentCard data={current.tournament} featured={true}/>
              ) : (
                <Col md={6}>
                  <div className='super-admin__featured-empty'>empty</div>
                </Col>
              )}
            </Row>
          </>
        ) : (
          <Row>
            <Col md={6}>
              <div className='super-admin__featured-empty'>empty</div>
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

export const SuperAdmin = connect(mapState, mapDispatch)(CSuperAdmin)
