import React from 'react'
import PropTypes from 'prop-types'
import { Table, Row, Col, Badge, Tooltip } from 'reactstrap'
import { Match } from './match'
import { Rounds } from './rounds'
import MaterialIcon from '@material/react-material-icon'

export class Swiss extends React.PureComponent {
  static propTypes = {
    players: PropTypes.number.isRequired,
    showEmpty: PropTypes.bool,
    data: PropTypes.object,
    className: PropTypes.string,
    isEditable: PropTypes.bool,
    tournament: PropTypes.object,
  }

  static defaultProps = {
    data: {total: [], games: []},
    className: '',
    showEmpty: false,
    isEditable: false,
    tournament: {},
  }

  state = {
    activeRow: [],
    tooltips: {},
  }

  toggleTooltip = (e) => {
    const tooltips = this.state.tooltips
    this.setState({tooltips:{...tooltips, [e.target.id]: !tooltips[e.target.id]}})
  }

  onMatchesToggleClick = (e) => {
    const {activeRow} = this.state
    const value = e.currentTarget.attributes.value.value
    const index = activeRow.indexOf(value)
    let result = [...activeRow]
    if (index === -1) {
      result = [...activeRow, value]
    } else {
      result.splice(index, 1)
    }
    this.setState({activeRow: result})
  };

  render() {
    const {data:{total,games}, tournamentId, manualStartRounds, spectators, isEditable, roundsStarted, tournament} = this.props
    let gamesByRound = []
    let gamesByPlayer = {}
    if (games) {
      for (let i = 0; i < games.length; i++) {
        let round = games[i]
        if (!gamesByRound[i]) gamesByRound[i] = []
        for (let [points, group] of Object.entries(round)) {
          gamesByRound[i] = [...gamesByRound[i], ...group]
          for (let match of group) {
            for (let player of match.players) {
              if (player && player.id) {
                if (!gamesByPlayer[player.id]) gamesByPlayer[player.id] = []
                gamesByPlayer[player.id].push(match)
              }
            }
          }
        }
      }
    }


    return (
      <Row>
        <Col lg={4} md={6}>
          <div className='tournament-bracket__sidebar'>
            <Rounds games={gamesByRound}
                    tournamentId={tournamentId}
                    manualStartRounds={tournament.manualStartRounds}
                    spectators={tournament.spectators}
                    isEditable={tournament.isEditable}
                    roundsStarted={tournament.roundsStarted}
                    roundsReadyToStart={tournament.roundsReadyToStart}/>
          </div>
        </Col>
        <Col lg={8} md={6}>
          <div className='tournament-bracket'>
            <h3>Rankings</h3>
            <div className='swiss'>
              {this.renderTotal(total, gamesByPlayer)}
            </div>
          </div>
        </Col>
      </Row>
    )
  }

  renderTotal(total, matches) {
    if (!total) return null
    const {tooltips} = this.state
    const tr = []
    const trLeft = []
    for (let i = 0; i < total.length; i++) {
      if (!total[i] && !this.props.showEmpty) continue
      let m = total[i] && total[i].userId && matches[total[i].userId] ? matches[total[i].userId] : [];
      (total[i].eliminating ? trLeft : tr).push(this.renderTotalTr(total[i] || {}, i, m))
    }

    return (
      <div className='swiss__total'>
        <Table className='round-robin__table' responsive={true}>
          <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th><div id={`win`}>W</div></th>
            <th><div id={`draw`}>D</div></th>
            <th><div id={`lose`}>L</div></th>
            <th><div id={`byes`}>B</div></th>
            <th><div id={`score`}>+/-</div></th>
            <th><div id={`coefficient`}>MB</div></th>
            <th><div id={`points`}>Pts</div></th>
          </tr>
          </thead>
          <tbody>
          {tr}
          {trLeft.length === 0 ? null : [
            <tr key='left-competition'><td colSpan={10}><h3>Left competition</h3></td></tr>,
            trLeft
          ]}
          </tbody>
        </Table>
        <Tooltip placement='top' target={`win`} delay={100}
                 isOpen={tooltips[`win`]}
                 toggle={this.toggleTooltip}>
          Wins
        </Tooltip>
        <Tooltip placement='top' target={`draw`} delay={100}
                 isOpen={tooltips[`draw`]}
                 toggle={this.toggleTooltip}>
          Draws
        </Tooltip>
        <Tooltip placement='top' target={`lose`} delay={100}
                 isOpen={tooltips[`lose`]}
                 toggle={this.toggleTooltip}>
          Losses
        </Tooltip>
        <Tooltip placement='top' target={`byes`} delay={100}
                 isOpen={tooltips[`byes`]}
                 toggle={this.toggleTooltip}>
          Byes
        </Tooltip>
        <Tooltip placement='top' target={`coefficient`} delay={100}
                 isOpen={tooltips[`coefficient`]}
                 toggle={this.toggleTooltip}>
          Buchholz system<br/>
          The Median-Buchholz system is used to break ties in Swiss tournaments.
          The value displayed in the standings table is the sum of a player's opponents' scores, with the best and worst scores discarded
        </Tooltip>
        <Tooltip placement='top' target={`score`} delay={100}
                 isOpen={tooltips[`score`]}
                 toggle={this.toggleTooltip}>
          Difference between wins and losses
        </Tooltip>
        <Tooltip placement='top' target={`points`} delay={100}
                 isOpen={tooltips[`points`]}
                 toggle={this.toggleTooltip}>
          Points
        </Tooltip>
      </div>
    )
  }

  renderTotalTr(item, i, matches) {
    const {activeRow} = this.state
    let win = 0
    let lose = 0
    let draw = 0
    let byes = 0
    if (item.history) {
      for (let i = 0; i < item.history.length; i++) {
        let hItem = item.history[i]
        if (!hItem.complete) continue
        if (hItem.result === 1) {
          win++
        } else if (hItem.result === 0) {
          draw++
        } else if (hItem.result === 2) {
          byes++
        } else {
          lose++
        }
      }
    }
    let className = ''
    if (item.advancing) {
      className = 'round-robin__total-advancing'
    }
    if (item.eliminating) {
      className = 'round-robin__total-eliminating'
    }
    const result = [
      <tr key={i} className={className}>
        <td className='round-robin__table-number'>{i + 1}</td>
        <td className='round-robin__table-player'>{item.name || ''}</td>
        <td className='round-robin__table-w'>{win}</td>
        <td className='round-robin__table-d'>{draw}</td>
        <td className='round-robin__table-l'>{lose}</td>
        <td className='round-robin__table-l'>{byes}</td>
        <td className='round-robin__table-plus-minus'>{item.score || 0}</td>
        <td className='round-robin__table-tb'>{item.coefficient || 0}</td>
        <td className='round-robin__table-pts'>{item.points || 0}</td>
        <td className='round-robin__table-matches-toggle'>
          <div className='arrow-btn' value={item.userId} onClick={this.onMatchesToggleClick}>
            <MaterialIcon icon='keyboard_arrow_down'/>
          </div>
        </td>
      </tr>
    ]

    if (activeRow.includes(item.userId)) {
      result.push(
        <tr key={i+'matches'}>
          <td colSpan={10} className='round-robin__table-matches'>{this.renderMatches(matches)}</td>
        </tr>,
      )
    }

    return result
  }

  renderMatches(matches) {
    const result = []
    for (let match of matches) {
      result.push(<Match key={match.id} data={match} className='_round-robin'/>)
    }
    return result
  }
}
