import styled from 'styled-components'
import bg from '../../../assets/border/gold-b.png'
import { Table as OldTable } from 'reactstrap'
import { NavLink as OldNavLink } from 'react-router-dom'

export const OrganizingWrapper = styled.div`
	border: 12px solid;
  border-image-source: url(${bg});
  border-image-slice: 12;
  border-image-outset: 0;
  border-image-repeat: round;
	padding: 26px;
	background: #000000;
  width: 100%;
  position: relative;
	&:first-child {
		margin-right: 12px;
	}
`

export const OrganizationCard = styled.div`
	background: #000000;
	width: 365px;
	font-family:${'AhmetAltunHalisGRMedium'};
	position: relative;
	margin-left:6px
`
export const SingleTurnomentInfo = styled.div`
	width:100%;
	height:100%1;
	padding-left:19px;
	padding-right:27px;
	margin-top:19.5px;
`
export const InfoBlock = styled.div`
	width:100%;
	height:100%1;
	display:flex;
	flex-direction:row;
`
export const TimeBlock = styled.div`
	width:50%;
	height:100%1;
	display:flex;
	flex-direction:column;
`
export const UserBlock = styled.div`
	width:50%;
	height:100%;
`
export const TournamentName = styled.h1`
	color: #D7BE5E;
	font-size: 20px;
	line-height: 26px;
`
export const Calendar = styled.div`
	width:100%;
	height:50%;
	display:flex;
	align-items:center;
`
export const Clock = styled.div`
	width:100%;
	height:50%;
	display:flex;
	align-items:center;
`
export const XBlock = styled.div`
	width:100%;
	height:50%;
	display:flex;
	align-items:center;
`
export const Man = styled.div`
	width:100%;
	height:50%;
	display:flex;
	align-items:center;
`
export const TextInfo = styled.h2`
	font-size: 10px;
	line-height: 20px;
	color: #FFFFFF;
	margin:0;
	margin-left:12px;
	font-family:${'AhmetAltunHalisGRMedium'};
`
export const TextIn = styled.h2`
	font-size: 14px;
	line-height: 20px;
	color: #FFFFFF;
	margin:0;
	margin-left:21px;
	font-family:${'AhmetAltunHalisGRMedium'};
`
export const AvatarBlock = styled.div`
	width:100%;
	height:100%1;
	display:flex;
	flex-direction:row;
	align-items:center;
	margin-top:22px	
`
export const SplitUp = styled.div`
	width:100%,
	border: 2px solid ;
background:#292618;
height:3px;
	margin-top:35px;
	margin-bottom:24px;
`
export const EditPlayerBlock = styled.div`
	width:100%;
	height:100%1;
	display:flex;
	flex-direction:row;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom:30px;

`
export const CartContentBlock = styled.div`
	width:100%;
	height:100%1;
	display:flex; 
	flex-direction:row;
`
export const Td = styled.td`
	
`

export const Th = styled.th`
	color: #949494;
	border-top: none;
`

export const Tr = styled.tr``

export const Table = styled(OldTable)`
	th {
		border-bottom: 1px solid #292618 !important;
		padding: 0 12px 21px 0;
		font-size: 14px;
		color: #949494;
		font-weight: normal;
	}
	td {
		padding: 0 12px 0 0;
		height: 73px;
	    vertical-align: inherit;
	    font-size: 14px;
	    font-weight: normal;
        border-bottom: 1px solid #292618 !important;
	    :last-child {
	    	padding-right: 0;
	    	text-align: right;
	    	text-align: -webkit-right;
	    }
	}
	#tournamentName-row {
		color: #d7be5e;
		
		font-family: "HalisGR-Bold";
	}
	#organisation-row {
		color: #D7BE5E;
	}
`

export const DropDownActionsContainer = styled.div`
	width: 25px;
	height: 25px;
	border-radius: ${props => props.dropDown ? '2px 2px 0 0' : '2px'};
	background: #D7BE5E;
	position: relative;
`

export const DropDowImgContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`

export const DropDownContentActions = styled.div`
	width: 240px;
	min-height: 85px;
	background: #D7BE5E;
	border-radius: 2px 0 2px 2px;
	padding: 12px 14px;
	position: absolute;
	right: 0;
	z-index: 2;
`

export const ButtonContainer = styled.div`
	margin-bottom: 11px;
	> button {
		width: 100%;
		text-align: left;
		display: flex;
		padding: 0 0 0 8px;
		align-items: center;
	}
	&:last-child {
		margin-bottom: 0;
	}
`

export const ImgContainerButton = styled.div`
	display: flex;
	margin-right: 8px;
`

export const TextButton = styled.div`
	display: flex;
	margin-bottom: -4px;
`

export const ButtonWrapper = styled.div`
	display: flex;
	button:first-child {
		margin-right: 12px;
	}
`

export const NavLink = styled(OldNavLink)`
	padding-left: 33px;
    font-family: GWENT, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
    font-size: 18px;
    color: #fff;
    &.active {
      color: #D7BE5E;
    }
`

export const Nav = styled.div`
	margin-bottom: 21px;
`
