import React, { Component } from "react";
import DropZoneImages from './dropzone-images';
import { DropZoneText, DropZoneContainer } from './styles';
import "./_dropzone.scss";

class Dropzone extends Component {
	constructor(props) {
		super(props);
		this.state = { hightlight: false };
		this.fileInputRef = React.createRef();

		this.openFileDialog = this.openFileDialog.bind(this);
		this.onFilesAdded = this.onFilesAdded.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.onDrop = this.onDrop.bind(this);
	}

	openFileDialog() {
		if (this.props.disabled) return;
		this.fileInputRef.current.click();
	}

	onFilesAdded(evt) {
		if (this.props.disabled) return;
		const files = evt.target.files;
		if (this.props.onFilesAdded) {
			const array = this.fileListToArray(files);
			this.props.onFilesAdded(array);
		}
	}

	onDragOver(evt) {
		evt.preventDefault();

		if (this.props.disabled) return;

		this.setState({ hightlight: true });
	}

	onDragLeave() {
		this.setState({ hightlight: false });
	}

	onDrop(event) {
		event.preventDefault();

		if (this.props.disabled) return;

		const files = event.dataTransfer.files;
		if (this.props.onFilesAdded) {
			const array = this.fileListToArray(files);
			this.props.onFilesAdded(array);
		}
		this.setState({ hightlight: false });
	}

	fileListToArray(list) {
		const array = [];
		for (let i = 0; i < list.length; i++) {
			array.push(list.item(i));
		}
		return array;
	}

	render() {
		const { width, height } = this.props;
		const dropZoneTextHidden = width > 300;
		return (
			<div
				className={`dropzone ${this.state.hightlight ? "Highlight" : ""}`}
				onDragOver={this.onDragOver}
				onDragLeave={this.onDragLeave}
				onDrop={this.onDrop}
				onClick={this.openFileDialog}
				style={{ cursor: this.props.disabled ? "default" : "pointer", width, height }}
			>
				<input
					ref={this.fileInputRef}
					className="FileInput"
					type="file"
					onChange={this.onFilesAdded}
				/>
				<DropZoneContainer>
					<DropZoneImages/>
					{ dropZoneTextHidden && <DropZoneText>
						1140 X 285 px<br/>
						recommended size, png,<br/>
						jpg or gif formats
					</DropZoneText> }
				</DropZoneContainer>
			</div>
		);
	}
}

export default Dropzone;
