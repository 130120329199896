import styled from 'styled-components';

export const DropZoneText = styled.div`
	margin-left: 26px;
	font-size: 14px;
	color: #949494;
`;

export const DropZoneContainer = styled.div`
	display: flex;
	justify-content: center;
`;