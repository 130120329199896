import { ProgressState } from '../constants/progress-state'
import { get } from '../utils/fetch-api'

export const tournamentUserStatus = {
  state: {
    join: {
      state: ProgressState.NotStarted,
      error: 0,
    },
    deck: {
      state: ProgressState.NotStarted,
      error: 0,
    },
  },
  reducers: {
    startJoin: (state) => ({...state, join: {state: ProgressState.InProgress, error: 0}}),
    errorJoin: (state, error) => ({...state, join: {state: ProgressState.Complete, error}}),
    successJoin: (state) => ({...state, join: {state: ProgressState.Complete, error: 0}}),

    startDeck: (state) => ({...state, deck: {state: ProgressState.InProgress, error: 0}}),
    errorDeck: (state, error) => ({...state, deck: {state: ProgressState.Complete, error}}),
    errorDeckParticipant: (state, error) => ({...state, participantDeck: {state: ProgressState.Complete, error}}),
    errorDeckReset: (state) => ({...state, deck: {state: ProgressState.Complete, error: 0}}),
    errorDeckParticipantReset: (state) => ({...state, participantDeck: {state: ProgressState.Complete, error: 0}}),
    successDeck: (state) => ({...state, deck: {state: ProgressState.Complete, error: 0}}),
  },
  effects: dispatch => ({
    async join(id) {
      this.startJoin()
      const response = await get(`/api/tournament/${id}/join`)
      if (response.ok) {
        this.successJoin()
        dispatch.tournament.successLoad(response.body)
      } else {
        this.errorJoin(response.status)
        dispatch.tournament.errorLoadM({error: response.status, errorMessage: response.body})
      }
    },

    async deckUpload({tournamentId, deckId, link, participant}) {
      this.startDeck()
      let url = (deckId ? `/api/tournament/${tournamentId}/deck/edit/${deckId}` : `/api/tournament/${tournamentId}/deck/add`) + '?link=' + link;

      if(participant) {
        url += '&participant=' + participant;
      }

      const response = await get(url,
      )
      if (response.ok) {
        this.successDeck()
        dispatch.tournament.successLoad(response.body)
      } else {
        this.errorDeck(response.body.error)
        if(participant) {
          this.errorDeckParticipant(response.body.error)
        }
      }
    },
  }),
}
