import React from 'react'
import {Input, Table, Row, Col, Badge, Tooltip, Button} from 'reactstrap'
import { connect } from 'react-redux'
import { Avatar } from '../../avatar'
import MaterialIcon from '@material/react-material-icon'
import { Switch } from '../../switch'
import { onChange } from '../../../utils/on-change'
import { DeckFormat, ParticipantStatus, TournamentState, Bracket } from '../../../constants/tournament'
import DiscordIco from '../../ico/discord'
import EditIco from '../../ico/edit'
import MailIco from '../../ico/mail'
import {getTournamentId} from "../../../utils/tournament";
import {
  CardActions,
  CardContainer,
  CardImg,
  ImgContainer,
  PlayerBg,
  PlayerCardSelections,
  PlayerContainer
} from "./profile-card.styles";
import moment from "moment-timezone";
import isEqual from "lodash/isEqual";

const styleInput = {
  height: 26,
  width: '100%',
  maxWidth: '270px',
}

const mapStateToProps = ({tournament, user, tournamentUserStatus}) => ({
  tournament,
  user,
  tournamentUserStatus
})

const mapDispatchToProps = ({tournament, user, tournamentUserStatus}) => ({
  load: id => tournament.loadParticipants(id),
  search: user.search,
  resetSearch: user.resetSearch,
  addPlayer: tournament.addPlayer,
  removePlayer: tournament.removePlayer,
  changeStatus: tournament.changeStatus,
  addBlocked: tournament.addBlocked,
  removeBlocked: tournament.removeBlocked,
  deckApproved: tournament.deckApproved,
  checkIn: tournament.checkIn,
  loadNotifications: user.loadNotifications,
  deckUpload: tournamentUserStatus.deckUpload,
  errorDeckParticipantReset: tournamentUserStatus.errorDeckParticipantReset,
})

class CTournamentPageParticipants extends React.Component {
  state = {
    playerSearch: '',
    playerFilter: 0,
    deckFilter: 0,
    participantSearch: '',
    blockedSearch: '',
    discordTooltip: 0
  }

  componentDidUpdate(prevProps) {
    const tournament = this.props.tournament.data
    const deckStatus = this.props.tournamentUserStatus.participantDeck || null
    if (!isEqual(prevProps.tournament.data, tournament)) {
      this.setParticipantDecks(this.state.toggleEditDecks)
    }
    if (deckStatus && deckStatus.error) {
      this.setParticipantDecks(this.state.toggleEditDecks)
      this.props.errorDeckParticipantReset()
    }
  }

  setParticipantDecks(id) {
    this.props.tournament.data.participants.map(
        (participant) => {
          if(participant.id === id) {
            let participantClone = {...participant};
            let decks = [];
            for (let i = 0; i < this.props.tournament.data.picksCount; i++) {
              decks.push(participant.decks[i] || {})
            }
            participantClone.decks = decks;
            this.setState({participant: participantClone})
          }
        }
    )
  }

  onChange = onChange(result => {
    this.setState({[result.name]: result.value})
  })

  onFilterClick = (e) => {
    this.setState({[e.target.attributes.name.value]: parseInt(e.target.attributes.value.value)})
  }

  onParticipantChange = (e) => {
    const value = e.target.value
    this.setState({participantSearch: value})
    this.props.resetSearch()
    this.props.search(value)
  }

  onParticipantSelect = (e) => {
    this.setState({participantSearch: ''})
    this.props.resetSearch()
    const tournamentId = getTournamentId(this.props.tournament.data);
    if (tournamentId && e.target.dataset.userId) {
      this.props.addPlayer({id: tournamentId, playerId: e.target.dataset.userId})
      setTimeout(() => this.props.loadNotifications(), 500)
    }
  }

  onParticipantRemove = (e) => {
    const tournamentId = getTournamentId(this.props.tournament.data);
    if (tournamentId && e.target.dataset.userId) {
      this.props.removePlayer({id: tournamentId, playerId: e.target.dataset.userId})
    }
  }

  onBlockedChange = (e) => {
    const value = e.target.value
    this.setState({blockedSearch: value})
    this.props.resetSearch()
    this.props.search(value)
  }

  onBlockedSelect = (e) => {
    this.setState({blockedSearch: ''})
    this.props.resetSearch()
    const tournamentId = getTournamentId(this.props.tournament.data);
    if (tournamentId && e.target.dataset.userId) {
      this.props.addBlocked({id: tournamentId, playerId: e.target.dataset.userId})
    }
  }

  onBlockedRemove = (e) => {
    console.log({...e})
    const tournamentId = getTournamentId(this.props.tournament.data);
    if (tournamentId && e.target.dataset.userId) {
      this.props.removeBlocked({id: tournamentId, playerId: e.target.dataset.userId})
    }
  }

  onDeckApproveClick = (e) => {
    const tournamentId = getTournamentId(this.props.tournament.data);
    if (tournamentId && e.target.userid) {
      this.props.deckApproved({tournamentId: tournamentId, userId: e.target.userid})
    }
  }

  onCheckInClick = (e) => {
    const tournamentId = getTournamentId(this.props.tournament.data);
    if (tournamentId && e.target.userid) {
      this.props.checkIn({tournamentId: tournamentId, userId: e.target.userid})
    }
  }

  onChangeStatusClick = (e) => {
    const tournamentId = getTournamentId(this.props.tournament.data);
    if (tournamentId && e.target.dataset.userId) {
      this.props.changeStatus({id: tournamentId, playerId: e.target.dataset.userId})
    }
  }

  discordTooltipToggle = (e) => {
    this.setState({discordTooltip: e.type === 'mouseover' ? e.target.dataset.userId : 0})
  }

  participantToggleDecks = (e) => {
    if(this.state.toggleEditDecks === e.currentTarget.dataset.userId) {
      this.setState({toggleEditDecks: 0})
      this.setState({participant: null})
      this.setState({participant: {decks: []}})
    } else {
      this.setState({toggleEditDecks: e.currentTarget.dataset.userId})
      this.setParticipantDecks(e.currentTarget.dataset.userId)
    }
  }

  onDeckUploadChange = (e) => {
    const idx = Number(e.target.dataset.deckidx)
    const decks = [...this.state.participant.decks]
    decks[idx].link = e.target.value
    let participant = this.state.participant;
    participant.decks = decks;
    this.setState({participant: participant})
  }

  onDeckUploadClick = (e) => {
    const link = e.target.dataset.decklink
    const deckId = e.target.dataset.deckid
    const participant = e.target.dataset.participant
    const tournamentId = getTournamentId(this.props.tournament.data);
    this.props.deckUpload({link, deckId, tournamentId: tournamentId, participant})
  }

  render() {
    const {
      state: tournamentState,
      isEditable,
      blocked = [],
      pickAndBans,
      participants = [],
      picksCount,
      tournamentCheckIn,
      playersLimit,
      playersCount,
    } = this.props.tournament.data
    const search = this.props.user.search.data
    const {playerFilter, deckFilter, participantSearch, blockedSearch} = this.state
    const plrsCount = {
      all: participants.length,
      checkedIn: participants.filter(p => p.checkedIn).length,
      submited: participants.filter(p => p.decks.length >= picksCount).length,
      unverified: participants.filter(p => !p.deckApproved).length,
    }
    const isMaxPlayers = playersLimit > 0 && playersCount >= playersLimit

    return (
      <div className='container'>
        <div className='tournament-participants'>
          <Row className='mb-4'>
            {tournamentCheckIn && (
              <Col md={6} className='mb-3'>
                <h3>Players</h3>
                <Badge color={playerFilter === 0 ? 'primary' : 'secondary'} pill
                       className='badge-filter'
                       onClick={this.onFilterClick}
                       value={0}
                       name='playerFilter'>
                  All {plrsCount.all}
                </Badge>
                <Badge color={playerFilter === 1 ? 'primary' : 'secondary'} pill
                       className='badge-filter'
                       onClick={this.onFilterClick}
                       value={1}
                       name='playerFilter'>
                  Checked-in {plrsCount.checkedIn}
                </Badge>
                <Badge color={playerFilter === 2 ? 'primary' : 'secondary'} pill
                       className='badge-filter'
                       onClick={this.onFilterClick}
                       value={2}
                       name='playerFilter'>
                  Not checked-in {plrsCount.all - plrsCount.checkedIn}
                </Badge>
              </Col>
            )}
            {pickAndBans && isEditable && (
              <Col md={6}>
                <h3>Decks</h3>
                <Badge color={deckFilter === 0 ? 'primary' : 'secondary'} pill
                       className='badge-filter'
                       onClick={this.onFilterClick}
                       value={0}
                       name='deckFilter'>
                  All {plrsCount.all}
                </Badge>
                <Badge color={deckFilter === 1 ? 'primary' : 'secondary'} pill
                       className='badge-filter'
                       onClick={this.onFilterClick}
                       value={1}
                       name='deckFilter'>
                  Submited {plrsCount.submited}
                </Badge>
                <Badge color={deckFilter === 2 ? 'primary' : 'secondary'} pill
                       className='badge-filter'
                       onClick={this.onFilterClick}
                       value={2}
                       name='deckFilter'>
                  Unsubmited {plrsCount.all - plrsCount.submited}
                </Badge>
                <Badge color={deckFilter === 3 ? 'primary' : 'secondary'} pill
                       className='badge-filter'
                       onClick={this.onFilterClick}
                       value={3}
                       name='deckFilter'>
                  Unverified {plrsCount.unverified}
                </Badge>
              </Col>
            )}
          </Row>
          {tournamentState === TournamentState.Publish && isEditable && (
            <Row className='mb-4 position-relative'>
              <Col md={5}>
                <label htmlFor='participants__add'>Add player</label>
                <Input type='text' id='participants__add'
                       placeholder={isMaxPlayers ? `Maximum ${playersLimit} players` : 'Username'}
                       disabled={isMaxPlayers}
                       value={participantSearch}
                       onChange={this.onParticipantChange}
                       autoComplete="off"/>
                {search.length > 0 && participantSearch ? (
                  <div className='user-search__list'>
                    {search.map(
                      u => <div className='user-search__list-item' key={u.id} data-user-id={u.id}
                                data-user-name={u.name}
                                onClick={this.onParticipantSelect}>
                        {u.name}
                      </div>)}
                  </div>
                ) : null}
              </Col>
            </Row>
          )}

          <Row className='mb-2 position-relative'>
            <Col md={6}>
              <div className='participant__search-wrap'>
                <label htmlFor='participants__add'>Search player</label>
                <Input type='text' id='participants__search' placeholder='Username'
                       name='playerSearch'
                       value={this.state.playerSearch}
                       onChange={this.onChange.string}/>
              </div>
            </Col>

            <Col md={6}>
              {isEditable && (
                <>
                  {tournamentState === TournamentState.Publish && (
                    <>
                      <h3>Blocked players</h3>
                      <label htmlFor='participants__add'>Block player</label>
                      <Input type='text' id='participants__block' placeholder='Username'
                             autoComplete='off'
                             value={blockedSearch}
                             onChange={this.onBlockedChange}/>
                      {search.length > 0 && blockedSearch && (
                        <div className='user-search__list'>
                          {search.map(
                            u => <div className='user-search__list-item' key={u.id} data-user-id={u.id}
                                      data-user-name={u.name}
                                      onClick={this.onBlockedSelect}>
                              {u.name}
                            </div>)}
                        </div>
                      )}
                    </>
                  )}

                  <div className='tournament-participants__blocked mb-4'>
                    {blocked.map((blockedUser, idx) => (
                      <div key={idx} className='tournament-participants__blocked-item'>
                        <Avatar className='tournament-participants__blocked-avatar' avatar={blockedUser.avatar}
                                border={blockedUser.border}/>
                        {blockedUser.name}
                        {isEditable && (
                          <MaterialIcon icon='close' data-user-id={blockedUser.id} onClick={this.onBlockedRemove}/>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Col>
          </Row>

          {isEditable ? this.renderTableAdmin() : this.renderTablePlayer()}
        </div>
      </div>
    )
  }

  renderTableAdmin() {
    const {
      pickAndBans,
      participants,
      picksCount,
      tournamentCheckIn,
    } = this.props.tournament.data
    const {playerFilter, deckFilter, playerSearch} = this.state
    let filtered = participants.sort((a, b) => a.status - b.status)
    if (playerFilter) filtered = filtered.filter(p => p.checkedIn === (playerFilter === 1))
    if (deckFilter) filtered = filtered.filter(p => {
      if (deckFilter === 1) return p.decks.length >= picksCount
      if (deckFilter === 2) return p.decks.length < picksCount
      if (deckFilter === 3) return !p.deckApproved
      return true

    })
    if (playerSearch) {
      let regex = new RegExp(playerSearch)
      filtered = filtered.filter(p => regex.test(p.name))
    }

    return (
      <Table className='tournament-participants__table' responsive={true}>
        <thead>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>Status</th>
          {tournamentCheckIn && (
            <th>Checked-in</th>
          )}
          {pickAndBans && (
            <th>Decks</th>
          )}
          {pickAndBans && (
            <th>Verified</th>
          )}
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {filtered.map((item, i) => this.renderTrAdmin(item, i))}
        </tbody>
      </Table>
    )
  }

  getPlayerStatus(status) {
    switch (status) {
      case ParticipantStatus.PLAYING:
        return 'participate'
      case ParticipantStatus.TECHNICAL_LOSE:
      case ParticipantStatus.TECHNICAL_LOSE_ON_START:
        return 'technical loss'
      case ParticipantStatus.LOSE:
        return 'lose'
      case ParticipantStatus.WIN:
        return 'win'
      default:
        return ''
    }
  }

  renderTrAdmin(item, i) {
    const {state: tournamentState, pickAndBans, tournamentCheckIn, bracketType} = this.props.tournament.data
    const {toggleEditDecks} = this.state
    return (
      <React.Fragment key={i}>
        <tr key={i}>
          <td>{i + 1}</td>
          <td>
            <Avatar className='participant-avatar' avatar={item.avatar} border={item.border}/>
            {item.name}
          </td>
          <td>{this.getPlayerStatus(item.status)}</td>
          {tournamentCheckIn && (
            <td>
              <Switch value={item.checkedIn} userid={item.id} onChange={this.onCheckInClick}/>
              {item.checkedIn && ' Done'}
            </td>
          )}
          {pickAndBans && (
            <td>
              {item.decks && item.decks.filter((deck) => deck.id).map(deck => (
                <a href={deck.link} key={deck.id} target='_blank' title={deck.name}
                   className={`participant-leader leader-ability _${deck.leaderId}`}/>
              ))}
            </td>
          )}
          {pickAndBans && (
            <td>
              <Switch value={item.deckApproved} userid={item.id} onChange={this.onDeckApproveClick}/>
              {`${item.deckApproved ? ' Done' : ''}`}
            </td>
          )}
          <td>
            {pickAndBans && (
              <div className='participant-toggle-decks' data-user-id={item.id} onClick={this.participantToggleDecks} title='Edit Participant Decks'>
                <EditIco/>
              </div>
            )}
            {item.email && (
              <a className='participant-contact' href={'mailto:' + item.email}><MailIco/></a>
            )}
            {item.discord && (
              <div className='participant-contact' id={`discord-${item.id}`}>
                <DiscordIco data-user-id={item.id}/>
                <Tooltip placement='top'
                         isOpen={this.state.discordTooltip === item.id}
                         target={`discord-${item.id}`}
                         autohide={false}
                         toggle={this.discordTooltipToggle}>
                  {item.discord}
                </Tooltip>
              </div>
            )}
            <MaterialIcon id={'mi-delete' + i} icon='close' title='delete' data-user-id={item.id}
                          disabled={item.status === ParticipantStatus.TECHNICAL_LOSE}
                          onClick={item.status === ParticipantStatus.TECHNICAL_LOSE ? null : this.onParticipantRemove}/>
            <MaterialIcon id={'mi-block' + i} icon='not_interested' title='block' data-user-id={item.id}
                          onClick={this.onBlockedSelect}/>
            {(bracketType === Bracket.RoundRobin || bracketType === Bracket.Swiss) &&
            item.status === ParticipantStatus.TECHNICAL_LOSE && (
              <MaterialIcon id={'mi-status' + i} icon='thumb_up' title='change status' data-user-id={item.id}
                            onClick={this.onChangeStatusClick}/>
            )}
          </td>
        </tr>
        {pickAndBans && toggleEditDecks === item.id && (
            <tr><td colSpan="6">{this.renderParticipantDecksUpload()}</td></tr>
        ) }
      </React.Fragment>
    )
  }

  renderParticipantDecksUpload() {
    const user = this.state.participant || null;
    return (
        <PlayerBg>
          <PlayerContainer>
            <PlayerCardSelections>
              {user && user.decks && user.decks.map((deck, idx) => (
                <CardContainer key={idx}>
                  <ImgContainer>
                    <CardImg className={`leader-ability _${deck.leaderId || 0}`}/>
                  </ImgContainer>
                  <CardActions>
                    <Input placeholder='link to deck' style={styleInput}
                           data-deckidx={idx}
                           value={deck.link || ''}
                           onChange={this.onDeckUploadChange}/>
                    <Button color='primary' style={{width: 84}}
                            data-deckid={deck.id}
                            data-decklink={deck.link}
                            data-participant={user.id}
                            onClick={this.onDeckUploadClick}>Save</Button>
                  </CardActions>
                </CardContainer>
              ))}
            </PlayerCardSelections>
          </PlayerContainer>
        </PlayerBg>
    )
  }

  renderTablePlayer() {
    const {
      pickAndBans,
      participants,
      picksCount,
      tournamentCheckIn,
      deckFormat,
      state: tournamentState
    } = this.props.tournament.data
    const {playerFilter, deckFilter, playerSearch} = this.state
    let filtered = participants
    if (playerFilter) filtered = filtered.filter(p => p.checkedIn === (playerFilter === 1))
    if (deckFilter) filtered = filtered.filter(p => {
      if (deckFilter === 1) return p.decks.length >= picksCount
      if (deckFilter === 2) return p.decks.length < picksCount
      if (deckFilter === 3) return !p.deckApproved
      return true

    })
    if (playerSearch) {
      let regex = new RegExp(playerSearch)
      filtered = filtered.filter(p => regex.test(p.name))
    }

    return (
      <Table className='tournament-participants__table' responsive={true}>
        <thead>
        <tr>
          <th>#</th>
          <th>Player</th>
          {tournamentCheckIn && (
            <th>Checked-in</th>
          )}
          {pickAndBans && deckFormat === DeckFormat.Open
          && (tournamentState === TournamentState.Start || tournamentState === TournamentState.Archive || tournamentState === TournamentState.PreStart)
          && (
            <th>Decks</th>
          )}
        </tr>
        </thead>
        <tbody>
        {filtered.map((item, i) => this.renderTrPlayer(item, i))}
        </tbody>
      </Table>
    )
  }

  renderTrPlayer(item, i) {
    const {pickAndBans, tournamentCheckIn, deckFormat, state: tournamentState} = this.props.tournament.data
    return (
      <React.Fragment>
        <tr key={i}>
          <td>{i + 1}</td>
          <td>
            <Avatar className='participant-avatar' avatar={item.avatar} border={item.border}/>
            {item.name}
          </td>
          {tournamentCheckIn && (
            <td>
              <Switch value={item.checkedIn} userid={item.id}/>
              {item.checkedIn && ' Done'}
            </td>
          )}
          {pickAndBans && deckFormat === DeckFormat.Open
          && (tournamentState === TournamentState.Start || tournamentState === TournamentState.Archive || tournamentState === TournamentState.PreStart)
          && (
            <td>
              {item.decks && item.decks.map(deck => (
                <a href={deck.link} key={deck.id} target='_blank' title={deck.name}
                   className={`participant-leader leader-ability _${deck.leaderId}`}/>
              ))}
            </td>
          )}
        </tr>
      </React.Fragment>
    )
  }

}

export const TournamentPageParticipants = connect(mapStateToProps, mapDispatchToProps)(CTournamentPageParticipants)
