import React, { Component } from 'react'
import button from '../../../assets/organisation_images/Gold.png'
import vector from '../../../assets/images/Vector.png'
import man from '../../../assets/images/man.png'
import owner from '../../../assets/organisation_images/org status tag.png'
import but from '../../../assets/organisation_images/but.png'
import but2 from '../../../assets/organisation_images/but2.png'
import { connect } from 'react-redux'
import LayoutDefault from '../layouts/default'

import { Link } from 'react-router-dom'
import { ORGANIZATION_CREATE, organization, organizationEdit } from '../../routes'
import { MyTournamentsMenu } from '../organizing/menu'
import { OrgAvatar } from '../../avatar/org-avatar'
import { Button } from 'reactstrap'
import {ProfileMenu} from "../profile-settings/menu";

class Index extends Component {
  constructor(props) {
    super(props)
    props.loadMy()
  }

  onLeaveClick = (e) => {
    console.log(e.target.dataset.orgId)
    this.props.leave(e.target.dataset.orgId)
  }

  renderOrg(org, idx) {
    const {data} = this.props.user
    const isOwner = org.owner.id === data.id
    return (
      <div className='organisations-page__card' key={idx}>
        <div className='organisations-page__card-info'>
          <OrgAvatar className='organisations-page__card-avatar' src={org.avatar}/>
          <Link to={organization(org.id)} className='organisations-page__card-data'>
            <div className='organisations-page__card-name'>
              {org.name}
            </div>
            <div className='organisations-page__card-count'>
              <img src={man} alt=''/>
              <p>
                {org.membersCount} members
              </p>
            </div>
            <div className='organisations-page__card-count'>
              <img src={vector} alt=''/>
              <p>
                {org.tournamentsCount} hosted tournaments
              </p>
            </div>
          </Link>
        </div>
        <div className='organisations-page__card-line'/>
        <div className='organisations-page__card-buttons'>
          <div>
            <img src={isOwner ? owner : but} alt=''/>
          </div>
          <div>
            {!isOwner && (
              <Button color='secondary' data-org-id={org.id} onClick={this.onLeaveClick}>
                Leave
              </Button>
            )}
            {isOwner && (
              <Link to={organizationEdit(org.id)} className='btn btn-primary'>Edit</Link>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {my} = this.props.organization
    return (
      <LayoutDefault>
        <ProfileMenu/>
        <div className='organisations-page'>
          <div className='organisations-page__info'>
            <h2 className='organisations-page__info-title'>WHAT ARE ORGANISATIONS?</h2>
            <p className='organisations-page__info-text'>
              Organizations is a way to manage multiple tournaments under one
              brand with a team of reliable organizers.
              All admins added to the organization will get access and rights to all tournaments hosted by
              this organization. Remember, with great power comes great responsibility, so choose your admins
              wisely, the ones you are really trust.
            </p>
            <Link to={ORGANIZATION_CREATE} className='btn btn-primary w-100'>
              Create new organization
            </Link>
          </div>
          {my.map((org, idx) => this.renderOrg(org, idx))}
        </div>
      </LayoutDefault>
    )
  }
}

const mapState = ({organization, user}) => ({
  organization,
  user,
})
const mapDispatch = ({organization}) => ({
  loadMy: organization.loadMy,
  leave: organization.leave,

})
export const Organizations = connect(mapState, mapDispatch)(Index)
