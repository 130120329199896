import React from 'react'
import { Avatar } from '../../avatar'
import {
  PlayerContainer,
  PlayerText,
  BlockTitle,
  PlayerBg,
  PlayerCardSelections,
  CardContainer,
  CardImg,
  CardActions,
  ImgContainer,
  Button,
  WarningContainer,
  WarningText,
  WrapperUser,
  ButtonContainer,
  PickAndBansContainer,
  PickAndBansAdd,
  Card,
  ContainerImgAdd,
  ContainerImgCard,
  PlayerApprovedContainer,
  PlayerApprovedCard,
  PlayerApprovedImg,
  PlayerApprovedText,
} from './profile-card.styles'
import Input from './../../atoms/input'
import { Col } from 'reactstrap'
import card from './../../../assets/leaders/small_122105.png'
import card1 from './../../../assets/leaders/small_131101.png'
import card2 from './../../../assets/leaders/small_200055.png'
import WarningImg from './../../ico/warning'
import add from './../../ico/add.svg'
import { Link } from 'react-router-dom'
import Modal from '../../atoms/modal'
import { Match } from './brackets/match'

const styleInput = {
  height: 26,
  width: '100%',
  maxWidth: '270px',
}

const playerCardArray = [
  {
    id: 1,
    placeholder: 'Link to deck',
    color: 'primary',
    buttonText: 'save',
    img: null,
    value: '',
  },
  {
    id: 2,
    placeholder: 'Link to deck',
    color: 'primary',
    buttonText: 'save',
    img: null,
    value: '',
  },
  {
    id: 3,
    placeholder: 'Link to deck',
    color: 'secondary',
    buttonText: 'edit',
    img: card,
    value: 'Crach ain Crach deck #3',
  },
  {
    id: 4,
    placeholder: 'Link to deck',
    color: 'secondary',
    buttonText: 'edit',
    img: card,
    value: 'Crach ain Crach deck #3',
  }, {
    id: 5,
    placeholder: 'Link to deck',
    color: 'secondary',
    buttonText: 'edit',
    img: card,
    value: 'Crach ain Crach deck #3',
  },
]
const cardApprovedOrganizer = [
  {
    id: 1,
    placeholder: 'Link to deck',
    color: 'light',
    buttonText: 'save',
    img: card,
    value: 'Crach ain Crach deck #3',
    disabled: true,
  },
  {
    id: 2,
    placeholder: 'Link to deck',
    color: 'light',
    buttonText: 'save',
    img: card1,
    value: 'Crach ain Crach deck #3',
    disabled: true,
  },
  {
    id: 3,
    placeholder: 'Link to deck',
    color: 'light',
    buttonText: 'edit',
    img: card2,
    value: 'Crach ain Crach deck #3',
    disabled: true,
  },
  {
    id: 4,
    placeholder: 'Link to deck',
    color: 'light',
    buttonText: 'edit',
    img: card,
    value: 'Crach ain Crach deck #3',
    disabled: true,
  }, {
    id: 5,
    placeholder: 'Link to deck',
    color: 'light',
    buttonText: 'edit',
    img: card1,
    value: 'Crach ain Crach deck #3',
    disabled: true,
  },
]

class ProfileCard extends React.Component {
  state = {
    pickAndBansModal: false,
    pickAndBansId: null,
    pickAndBans: {
      pickandbans1: card1,
      pickandbans2: card2,
      pickandbans3: add,
      pickandbans4: add,
    },
  }

  handlerOpenPickAndBans = (event) => {
    const id = event.currentTarget.id
    this.setState({pickAndBansModal: true, pickAndBansId: id})
  }

  handlerPickAndBans = (event, img) => {
    const {pickAndBansId} = this.state
    this.setState((prevState) => {
      return {
        ...prevState,
        pickAndBans: {
          ...prevState.pickAndBans,
          [pickAndBansId]: img,
        },
        pickAndBansModal: false,
      }
    })
  }

  render() {
    return (
      <div className='container'>
        <BlockTitle>Player joined tournament</BlockTitle>
        {this.renderPlayerJoined()}
        <BlockTitle>Player joined tournament</BlockTitle>
        {this.renderPikCard()}
        <BlockTitle>Player joined tournament</BlockTitle>
        {this.renderApprovedOrganizer()}
        <BlockTitle>Player joined tournament with picks and bans</BlockTitle>
        {this.renderPlayerJoinedPickAndBans()}
        <BlockTitle>Player joined tournament with picks and bans, his decks were approved</BlockTitle>
        {this.renderPlayerApproved()}
        <BlockTitle>Tournament has started. First opponent is found.</BlockTitle>
        {this.renderTournamentStarted()}
      </div>
    )
  }

  renderPlayerJoined = () => {
    return (
      <PlayerBg>
        <PlayerContainer>
          <div className='match__lobby-player'>
            <Avatar className='match__lobby-avatar'/>
            <div className='match__lobby-player-info'>
              <div className='match__lobby-player-name'>Twentysymbolsusername</div>
              <div className='match__lobby-player-online _active'>Joined the lobby</div>
            </div>
          </div>
          <PlayerText>Thanks for joining! Come back for check-in, it will start in 1 hour before the
            tournament.</PlayerText>
        </PlayerContainer>
      </PlayerBg>
    )
  }

  renderPikCard = () => {
    return (
      <PlayerBg>
        <PlayerContainer>
          <div className='match__lobby-player'>
            <Avatar className='match__lobby-avatar'/>
            <div className='match__lobby-player-info'>
              <div className='match__lobby-player-name'>Twentysymbolsusername</div>
              <div className='match__lobby-player-online _active'>Joined the lobby</div>
            </div>
          </div>
          <PlayerText>Thanks for joining! Come back for check-in, it will start in 1 hour before the
            tournament. </PlayerText>
          <Col lg={8}>
            <PlayerCardSelections>
              {playerCardArray.map(el => (
                <CardContainer key={el.id}>
                  <ImgContainer>
                    <CardImg src={el.img}/>
                  </ImgContainer>
                  <CardActions>
                    <Input placeholdr={el.placeholder} style={styleInput} value={el.value}/>
                    <Button color={el.color} style={{width: 84}}
                            onClick={this.handlerModalClose}>{el.buttonText}</Button>
                  </CardActions>
                </CardContainer>
              ))}
            </PlayerCardSelections>
          </Col>
          <Col lg={7}>
            <WarningContainer>
              <WarningImg/>
              <WarningText>Upload 5 deckslist links from <Link to='#'>playgwent.com/decks.</Link> <br/>
                You can change and edit them any time up to 21:00 MSK, November 11.
              </WarningText>
            </WarningContainer>
          </Col>
        </PlayerContainer>
      </PlayerBg>
    )
  }

  renderApprovedOrganizer = () => {
    return (
      <PlayerBg>
        <PlayerContainer>
          <WrapperUser>
            <div className='match__lobby-player'>
              <Avatar className='match__lobby-avatar'/>
              <div className='match__lobby-player-info'>
                <div className='match__lobby-player-name'>Twentysymbolsusername</div>
                <div className='match__lobby-player-online _active'>Joined the lobby</div>
              </div>
            </div>
            <ButtonContainer>
              <Button color='primary' onClick={this.handlerModalClose} style={{width: 147}}>hide decks</Button>
              <Button color='light' onClick={this.handlerModalClose} style={{width: 147}}>check-in</Button>
            </ButtonContainer>
          </WrapperUser>
          <PlayerText>Good news! Your decklists were approved by the organizer. Come back for check-in, it will start in
            1 hour before the tournament.</PlayerText>
          <Col lg={8}>
            <PlayerCardSelections>
              {cardApprovedOrganizer.map(el => (
                <CardContainer key={el.id}>
                  <ImgContainer>
                    <CardImg src={el.img}/>
                  </ImgContainer>
                  <CardActions>
                    <Input placeholdr={el.placeholder} style={styleInput} value={el.value} disabled={el.disabled}/>
                    <Button color={el.color} style={{width: 84}} disabled={el.disabled}
                            onClick={this.handlerModalClose}>{el.buttonText}</Button>
                  </CardActions>
                </CardContainer>
              ))}
            </PlayerCardSelections>
          </Col>
          <PlayerText>You can’t edit decks after approving. Please, contact to the organizer if you want to change
            them.</PlayerText>
        </PlayerContainer>
      </PlayerBg>
    )
  }

  renderPlayerJoinedPickAndBans = () => {
    const {pickAndBansModal} = this.state
    const cardArray = new Array(20).fill({src: card2, id: 'card', active: [4, 8]})
    const pickAndBansImg = Object.keys(this.state.pickAndBans)
    return (
      <PlayerBg>
        <PlayerContainer>
          <div className='match__lobby-player'>
            <Avatar className='match__lobby-avatar'/>
            <div className='match__lobby-player-info'>
              <div className='match__lobby-player-name'>Twentysymbolsusername</div>
              <div className='match__lobby-player-online _active'>Joined the lobby</div>
            </div>
          </div>
          <PlayerText>Thanks for joining! Please, submit the leaderlists you are going to play in the
            tournament:</PlayerText>
          <Col lg={6}>
            <PickAndBansContainer>
              {pickAndBansImg.map((el, key) =>
                <ContainerImgAdd active={this.state.pickAndBans[el] !== add}
                                 hover={this.state.pickAndBans[el] !== add}
                                 id={el}
                                 key={key}
                                 onClick={this.handlerOpenPickAndBans}>
                  <PickAndBansAdd paddingActive={this.state.pickAndBans[el] !== add}
                                  src={this.state.pickAndBans[el] !== add ? this.state.pickAndBans[el] : add}/>
                </ContainerImgAdd>,
              )}
            </PickAndBansContainer>
          </Col>
          <Col lg={7}>
            <WarningContainer>
              <WarningImg/>
              <WarningText>
                Select 5 leaders you are going to play at the tournament. <br/>
                You can change and edit them any time up to 21:00 MSK, November 11.
              </WarningText>
            </WarningContainer>
          </Col>
        </PlayerContainer>
        <Modal open={pickAndBansModal}
               width={420}
               height={400}>
          <PickAndBansContainer>
            {cardArray.map((el, key) =>
              <ContainerImgCard active={el.active[key]} key={key} id={`${el.id}${key}`}>
                <Card src={el.src}
                      active={el.active[key]}
                      onClick={(event) => this.handlerPickAndBans(event, el.src)}/>
              </ContainerImgCard>,
            )}
          </PickAndBansContainer>
        </Modal>
      </PlayerBg>
    )
  }

  renderPlayerApproved = () => {
    return (
      <PlayerBg>
        <PlayerContainer>
          <WrapperUser>
            <div className='match__lobby-player'>
              <Avatar className='match__lobby-avatar'/>
              <div className='match__lobby-player-info'>
                <div className='match__lobby-player-name'>Twentysymbolsusername</div>
                <div className='match__lobby-player-online _active'>Joined the lobby</div>
              </div>
            </div>
            <ButtonContainer>
              <Button color='primary' onClick={this.handlerModalClose} style={{width: 147}}>hide decks</Button>
              <Button color='light' disabled={true} onClick={this.handlerModalClose}
                      style={{width: 147}}>check-in</Button>
            </ButtonContainer>
          </WrapperUser>
          <PlayerText>Good news! Your decklists were approved by the organizer. Come back for check-in, it will start in
            1 hour before the tournament.</PlayerText>
          <Col lg={6}>
            <PlayerApprovedContainer>
              <PlayerApprovedCard>
                <PlayerApprovedImg src={card}/>
                <PlayerApprovedText>Deck</PlayerApprovedText>
              </PlayerApprovedCard>
              <PlayerApprovedCard>
                <PlayerApprovedImg src={card1}/>
                <PlayerApprovedText>Deck</PlayerApprovedText>
              </PlayerApprovedCard>
              <PlayerApprovedCard>
                <PlayerApprovedImg src={card2}/>
                <PlayerApprovedText>Deck</PlayerApprovedText>
              </PlayerApprovedCard>
              <PlayerApprovedCard>
                <PlayerApprovedImg src={card}/>
                <PlayerApprovedText>Deck</PlayerApprovedText>
              </PlayerApprovedCard>
              <PlayerApprovedCard>
                <PlayerApprovedImg src={card1}/>
                <PlayerApprovedText>Deck</PlayerApprovedText>
              </PlayerApprovedCard>
            </PlayerApprovedContainer>
          </Col>
          <PlayerText>You can’t edit decks after approving. Please, contact to the organizer if you want to change
            them.</PlayerText>
        </PlayerContainer>
      </PlayerBg>
    )
  }

  renderTournamentStarted = () => {
    const data = {
      className: '_single',
      data: {
        players: [
          {
            'id': '49676791641935433',
            'name': 'Kingkss',
            'win': true,
          },
          {
            'id': '49478181048874811',
            'name': 'stiG_G',
            'win': false,
          },
        ],
        id: 1,
        score: [1, 2],
        round: 0,
        complete: true,
      },
      seeding: null,
      bracket: 0,
      index: 0,
    }
    return (
      <PlayerBg>
        <PlayerContainer>
          <div className='tournament-user__info'>
            <Avatar className='tournament-user__avatar'/>
            <div className='mr-auto'>
              <div className='match__lobby-player-name'>YourUserName</div>
              <div>playing the tournament</div>
            </div>
            <div>
              <div className='tournament-user__ranking'>
                <div className='tournament-user__ranking-wdl'>
                  <div><span>W:</span>1</div>
                  <div><span>D:</span>0</div>
                  <div><span>L:</span>0</div>
                </div>
              </div>
            </div>
            <a href='#' className='btn btn-secondary'>Leave tournament</a>
          </div>
          <div className='tournament-user__matches'>
            <div className='tournament-user__matches-group'>
              <div className='tournament-user__matches-group-name'>Current match</div>
              <div className='tournament-user__matches-group-list'>
                <Match {...data} className='_round-robin'/>
              </div>
            </div>
            <div className='tournament-user__matches-group'>
              <div className='tournament-user__matches-group-name'>Next match</div>
              <div className='tournament-user__matches-group-list'>
                <Match {...data} className='_round-robin'/>
              </div>
            </div>
            <div className='tournament-user__matches-group'>
              <div className='tournament-user__matches-group-name'>Finished matches</div>
              <div className='tournament-user__matches-group-list'>
                <Match {...data} className='_round-robin'/>
                <Match {...data} className='_round-robin'/>
                <Match {...data} className='_round-robin'/>
                <Match {...data} className='_round-robin'/>
                <Match {...data} className='_round-robin'/>
                <Match {...data} className='_round-robin'/>
              </div>
            </div>
          </div>
          <PlayerText>Congratulations! You are the best! Well played and well deserved.</PlayerText>
        </PlayerContainer>
      </PlayerBg>
    )
  }
}

export default ProfileCard
