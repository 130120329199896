/**
 * @param e
 * @returns {{name: string, value: *}|null}
 */
function handleInput(e) {
  if (!e.target || (!e.target.name && !e.target.id)) return null
  let value = ''
  if (e.target.type === 'checkbox') value = e.target.checked
  else if (e.target.type === 'radio' && e.target.checked) value = e.target.value
  else value = e.target.value;

  return {name: e.target.name || e.target.id, value}
}

/**
 * @param e
 * @returns {{name: string, value: *}|null}
 */
function handleInputHtml(e) {
  if (!e.target || (!e.target.name && !e.target.id)) return null
  let value = ''
  if (e.target.type === 'checkbox') value = e.target.checked
  else if (e.target.type === 'radio' && e.target.checked) value = e.target.value
  else value = e.target.value;

  let SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  while (SCRIPT_REGEX.test(value)) {
    value = value.replace(SCRIPT_REGEX, "");
  }

  return {name: e.target.name || e.target.id, value}
}

/**
 * @param e
 * @returns {{name: string, value: *}|null}
 */
function handleInputEscape(e) {
  if (!e.target || (!e.target.name && !e.target.id)) return null
  let value = ''
  if (e.target.type === 'checkbox') value = e.target.checked
  else if (e.target.type === 'radio' && e.target.checked) value = e.target.value
  else value = e.target.value;

  let SCRIPT_REGEX = /(<([^>]+)>)/gi;
  while (SCRIPT_REGEX.test(value)) {
    value = value.replace(SCRIPT_REGEX, "");
  }

  return {name: e.target.name || e.target.id, value}
}

/**
 * @param e
 * @returns {{name: string, value: boolean}|null}
 */
function handleInputBool(e) {
  const result = handleInput(e)
  if (!result) return null

  return {name: result.name, value: !!result.value}
}

/**
 * @param e
 * @returns {{name: string, value: number}|null}
 */
function handleInputInt(e) {
  const result = handleInput(e)
  if (!result) return null
  const value = (result.value + '').replace(/[^-0-9]/g, '')

  return {name: result.name, value: !value ? 0 : parseInt(value)}
}

/**
 * @param e
 * @returns {{name: string, value: File[]}|null}
 */
export function handleInputFiles(e) {
  if (!e.target || (!e.target.name && !e.target.id) || !e.target.files) return null

  return {name: e.target.name || e.target.id, value: e.target.files}
}

export function onChange(changeFunc) {
  return {
    string: (e) => changeFunc(handleInputHtml(e)),
    escapeString: (e) => changeFunc(handleInputEscape(e)),
    bool: (e) => changeFunc(handleInputBool(e)),
    int: (e) => changeFunc(handleInputInt(e)),
  }
}
