import React from 'react'
import {Registration} from '../../constants/tournament'
import {Col, Row} from 'reactstrap'
import {connect} from 'react-redux'
import {ContainerFilter, FilterWrapper, FilterButton} from "./styles";
import DropDownContent from "../atoms/dropDown";
import Input from "../atoms/input";
import ToggleButton from "../atoms/toggle-button";
import VerticalImg from '../ico/vertical-view';
import GorizImg from '../ico/gori-view';
import {Label} from "../atoms/dropDown/styles";
import axios from "axios";
import _ from 'lodash'


const mapState = ({query, tournamentsList}) => ({
	query,
	tournamentsList
})
const mapDispatch = ({query: {setQuery}, tournamentsList: {getData}}) => ({
	setQuery,
	getData
})

class CTournamentSearch extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			aa: true,
			isOpen: true,
			search: '',
			viewToggle: false,
			filterData: [],
			type: '',
			filter: {
				published: false,
				drafts: false,
				deleted: false,
				archive: false,
			},
			actionsFilter: {
				all: true,
				admin: false,
				players: false,
			},
			problemsFilter: {
				unresolved: false,
				resolved: false
			},
		}

	}

	handlerInput = (name, value) => {
		this.setState({[name]: value})
	};

	handlerToggleButton = (event) => {
		console.log(event)
		const id = event.target.id;
		const isOpen = !this.state.isOpen;
		this.setState({
			[id]: !this.state[id],
			isOpen,
		});
		this.props.onChange(isOpen)
	};

	handlerFilter = async (event) => {
		const id = event.currentTarget.id;
		const {filterData} = this.props.tournamentsList;
		await this.setState((prevState) => {
			return {
				...prevState,
				filter: {
					...prevState.filter,
					[id]: !this.state.filter[id],
				}
			};
		});
		await this.setState({type: this.state.filter[id] === true ? '' : id});
		await this.getFilterData(this.state.type);
	//	this.props.getData(this.state.filterData);
		await this.setState({filterData})
		this.props.onChange(filterData)
	}

	handlerFilterActions = (event) => {
		const id = event.currentTarget.id;
		this.setState((prevState) => {
			return {
				...prevState,
				actionsFilter: {
					...prevState.actionsFilter,
					[id]: !this.state.actionsFilter[id],
				}
			}
		})
	};

	render() {
		const {
			time = true,
			registration = false,
			type = false,
			view = false,
			filter = false,
			organization = false,
			actionsFilter = false,
			problemsFilter = false,
			searchPlaceholder,
			searchLabel,
			bg = true
		} = this.props;
		const {search, viewToggle} = this.state;
		const searchPlaceholderText = searchPlaceholder ? searchPlaceholder : 'Search tournament by name or organization';
		const searchTitle = searchLabel ? searchLabel : 'Search';
		return (
			<>
				<ContainerFilter bg={bg}>
					<Row className={time ? 'sp' : ''}>
						{actionsFilter && <Col lg={5}>{this.renderFilterActions()}</Col>}
						{problemsFilter && <Col lg={3}>{this.renderFilterProblems()}</Col>}
						{view && <Col lg={1}>
							<ToggleButton
								width={64}
								id='viewToggle'
								onChange={this.handlerToggleButton}
								value={viewToggle}
								firstButton={<GorizImg viewToggle={viewToggle}/>}
								label='View'
								lastButtonStyle={{padding: '0'}}
								firstButtonStyle={{padding: '0'}}
								labelStyle={{marginBottom: '5px'}}
								lastButton={<VerticalImg viewToggle={viewToggle}/>}/>
						</Col>}
						{filter && <Col lg={6}>{this.renderFilter()}</Col>}
						{time && <Col lg={2}>
							<DropDownContent {...{
								toggleText: 'Upcoming',
								label: 'Time'
							}}/>
						</Col>
						}
						{registration && <Col lg={2}>
							<DropDownContent {...{
								toggleText: 'Registration',
								label: 'Any'
							}}/>
						</Col>
						}
						{type && <Col lg={2}>
							<DropDownContent {...{
								toggleText: 'Any',
								label: 'Type'
							}}/>
						</Col>}
						{organization && <Col lg={2}>
							<DropDownContent {...{
								toggleText: 'All',
								label: 'Organization'
							}}/>
						</Col>}
						<Col lg={{size: view ? 3 : 4, offset: view || actionsFilter ? 0 : 2}}>
							<Input {...{
								title: searchTitle,
								placeholder: searchPlaceholderText,
								icon: 'search',
								name: 'search',
								value: search,
								style: {fontStyle: 'italic'},
								onChange: this.handlerInput
							}}/>
						</Col>
					</Row>
				</ContainerFilter>
			</>
		)
	}

	getFilterData = (type) => {
		axios.post('/api/organization/filter', {type})
			.then((response) => {
				this.setState({filterData: response});
			})
			.catch(error => console.log(error,));
	};

	componentDidMount() {
		// this.getFilterData('')
	}

	renderFilter = () => {
		const {published, drafts, deleted, archive} = this.state.filter;
		return (
			<React.Fragment>
				<Label style={{marginBottom: '12px'}}>Filter by:</Label>
				<FilterWrapper>
					<FilterButton id='published' active={published} onClick={this.handlerFilter}>Published
						10</FilterButton>
					<FilterButton id='drafts' active={drafts} onClick={this.handlerFilter}>Drafts
						3</FilterButton>
					<FilterButton id='deleted' active={deleted} onClick={this.handlerFilter}>Deleted 0</FilterButton>
					<FilterButton id='archive' active={archive} onClick={this.handlerFilter}>Archive
						12</FilterButton>
				</FilterWrapper>
			</React.Fragment>
		)
	};

	renderFilterActions = () => {
		const {all, admin, players} = this.state.actionsFilter;
		return (
			<React.Fragment>
				<Label style={{marginBottom: '16px'}}>Actions</Label>
				<FilterWrapper>
					<FilterButton id='all' active={all} onClick={this.handlerFilterActions}>All 127</FilterButton>
					<FilterButton id='admin' active={admin} onClick={this.handlerFilterActions}>Admins 32</FilterButton>
					<FilterButton id='players' active={players} onClick={this.handlerFilterActions}>Players
						2054 </FilterButton>
				</FilterWrapper>
			</React.Fragment>
		)
	};

	renderFilterProblems = () => {
		const {unresolved, resolved} = this.state.problemsFilter;
		return (
			<React.Fragment>
				<Label style={{marginBottom: '16px'}}>PROBLEMS</Label>
				<FilterWrapper>
					<FilterButton id='unresolved' active={unresolved} onClick={this.handlerFilterActions}>Unresolved
						3</FilterButton>
					<FilterButton id='resolved' active={resolved} onClick={this.handlerFilterActions}>Resolved
						2</FilterButton>
				</FilterWrapper>
			</React.Fragment>
		)
	}
}

export const TournamentSearch = connect(mapState, mapDispatch)(CTournamentSearch)
