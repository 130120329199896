export const ABOUT = '/about';
export const LOGIN = '/login';
export const tournament = (id = null, page = null) =>
  `/tournament/${id || ':id'}${page ? '/' + page : ''}`
export const tournamentMatch = (id = null, matchId = null) =>
  `/tournament/${id || ':id'}/match/${matchId || ':matchId'}`;
export const TOURNAMENT_CREATE = `/tournament/organize`
export const tournamentEdit = (id = null) => `/tournament/${id || ':id'}/edit`;
export const organization = (id = null) => `/organization/${id || ':id'}`;
export const ORGANIZATION_CREATE = `/organization/new`;
export const organizationEdit = (id = null) => `/organization/${id || ':id'}/edit`;
export const organizationMembers = (id = null) => `/organization/${id || ':id'}/members`;
export const organizationBlock = (id = null) => `/organization/${id || ':id'}/block`;
export const PROFILE_SETTINGS = `/profile/settings`;
export const MY_TOURNAMENTS = `/my-tournaments`;
export const MY_TOURNAMENTS_ORGANIZING = `/my-tournaments/organizing`;
export const MY_ORGANISATION =`/my-organization`;
export const NEW_MEMBERS_ORGANISATION =`/tournaments/members`;
export const BLOCK_PLAYERS =`/tournaments/block/players`;
export const SUPER_ADMIN = `/super-admin`;


