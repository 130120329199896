import React from 'react'
import LayoutDefault from '../layouts/default'
import {
  OrganizingWrapper,
  Td,
  Th,
  Table,
  Tr,
} from './styles'
import { columns, rows } from './data'
import DropDownActions from './dropDownActions'
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Badge,
} from 'reactstrap'
import Modal from '../../atoms/modal'
import { ModalText, ModalButtonWrapper } from '../../atoms/modal/styles'
import {
  tournamentEdit,
} from '../../routes'
import { connect } from 'react-redux'
import { TournamentCard } from '../../tournament-card'
import { MyTournamentsMenu } from './menu'
import ToggleButton from '../../atoms/toggle-button'
import { TournamentState } from '../../../constants/tournament'
import Search from '../../ico/search'
import VerticalImg from '../../ico/vertical-view'
import GorizImg from '../../ico/gori-view'
import { onChange } from '../../../utils/on-change'
import {getTournamentId} from "../../../utils/tournament";

const mapState = ({user, tournament}) => ({
  user,

})
const mapDispatch = ({user, tournament}) => ({
  remove: tournament.remove,
  draft: tournament.draft,
  loadTournamentOrganizing: user.loadTournamentOrganizing,
})

class Organizing extends React.Component {
  constructor(props) {
    super(props)
    this.columnSizes = this.getColumnSizes(columns)
    this.state = {
      viewToggle: false,
      name: '',
      search: {
        state: TournamentState.Publish,
      },

      open: true,
      theRest: true,
      link: '',
      modalOpen: false,
      expanded: {
        idDropDown: null,
        dropDownOpen: false,
      },
    }
    props.loadTournamentOrganizing()
  }

  handlerToggleButton = (event) => {
    const id = event.target.id
    this.setState({
      [id]: !this.state[id],
    })
  }

  onChange = onChange(result => this.setState({[result.name]: result.value}))

  onStateFilterClick = (e) => {
    this.setState({
      search: {
        ...this.state.search,
        [e.target.attributes.name.value]: Number(e.target.attributes.value.value),
      },
    })
  }

  render() {
    const {viewToggle, name, search: {state}} = this.state
    const {tournamentsOrganizing: {data}} = this.props.user

    const dataByState = {
      [TournamentState.Publish]: data.filter(
        t => t.state === TournamentState.Publish || t.state === TournamentState.PreStart),
      [TournamentState.Start]: data.filter(t => t.state === TournamentState.Start),
      [TournamentState.Draft]: data.filter(t => t.state === TournamentState.Draft),
      [TournamentState.Archive]: data.filter(t => t.state === TournamentState.Archive),
    }
    let result = dataByState[state]
    if (name && name.length > 1) {
      const nameLower = name.toLowerCase().trim()
      result = result.filter(t => t.name.toLowerCase().includes(nameLower))
    }

    return (
      <LayoutDefault className='tournament-edit-page'>
        <MyTournamentsMenu/>
        <div className='tournament-search'>
          <Row>
            <Col md={1}>
              <ToggleButton
                width={64}
                id='viewToggle'
                onChange={this.handlerToggleButton}
                value={viewToggle}
                firstButton={<GorizImg viewToggle={viewToggle}/>}
                label='View'
                lastButtonStyle={{padding: '0'}}
                firstButtonStyle={{padding: '0'}}
                lastButton={<VerticalImg viewToggle={viewToggle}/>}/>
            </Col>

            <Col md={5}>
              <div><label>Filter by:</label></div>
              <Badge color={state === TournamentState.Publish ? 'primary' : 'secondary'} pill
                     className='badge-filter'
                     onClick={this.onStateFilterClick}
                     value={TournamentState.Publish}
                     name='state'>
                Published {dataByState[TournamentState.Publish].length}
              </Badge>
              <Badge color={state === TournamentState.Start ? 'primary' : 'secondary'} pill
                     className='badge-filter'
                     onClick={this.onStateFilterClick}
                     value={TournamentState.Start}
                     name='state'>
                Started {dataByState[TournamentState.Start].length}
              </Badge>
              <Badge color={state === TournamentState.Draft ? 'primary' : 'secondary'} pill
                     className='badge-filter'
                     onClick={this.onStateFilterClick}
                     value={TournamentState.Draft}
                     name='state'>
                Draft {dataByState[TournamentState.Draft].length}
              </Badge>
              <Badge color={state === TournamentState.Archive ? 'primary' : 'secondary'} pill
                     className='badge-filter'
                     onClick={this.onStateFilterClick}
                     value={TournamentState.Archive}
                     name='state'>
                Archive {dataByState[TournamentState.Archive].length}
              </Badge>

            </Col>

            <Col md={4} className='ml-auto'>
              <FormGroup>
                <Label for='name'>Search</Label>
                <InputGroup>
                  <Input type='text' id='name' value={name} placeholder='Search tournament by name'
                         onChange={this.onChange.string}/>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText><Search/></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>


          </Row>
        </div>
        {this.state.viewToggle ? this.renderTableSquare(result) :
          <OrganizingWrapper>
            {this.renderTable(result)}
            {this.renderModal()}
          </OrganizingWrapper>
        }

      </LayoutDefault>
    )
  }

  sizeWidth = (size) => ({
    width: `${size}px`,
  })

  getColumnSizes = (columns) =>
    columns
      .reduce((result, column) => {
        result[column.id] = column.size
        return result
      }, {})

  handlerDropDownActions = (id) => {
    this.setState({
      expanded: {
        idDropDown: id,
        dropDownOpen: !this.state.expanded.dropDownOpen,
      },
    })
  }

  onDraft = (e) => {
    this.setState({expanded: {...this.state.expanded, dropDownOpen: false}})
    this.props.draft(e.currentTarget.dataset.tournamentid)
  }

  onDelete = (e) => {
    this.setState({expanded: {...this.state.expanded, dropDownOpen: false}})
    this.props.remove(e.currentTarget.dataset.tournamentid)
  }

  onEdit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({expanded: {...this.state.expanded, dropDownOpen: false}})
    this.props.history.push(tournamentEdit(e.currentTarget.dataset.tournamentid))
  }

  handlerModalClose = () => {
    this.setState({modalOpen: false})
  }

  renderTable = (data) => {
    const {idDropDown, dropDownOpen} = this.state.expanded
    return (
      <Table borderless responsive>
        <colgroup>
          {columns
            .map((column, index) => (
              <col
                key={index}
                id={`${column.id}-col`}
                style={this.sizeWidth(this.columnSizes[column.id])}
              />
            ))}
        </colgroup>
        <thead>
        <Tr>
          {columns.map((column, key) => <Th key={key} id={`${column.id}-col`}>{column.name}</Th>)}
        </Tr>
        </thead>
        <tbody>
        {data.map((t) =>
          <tr key={t.id}>
            {columns.map((column, index) => {
              if (column.id === 'actions') {
                const tournamentId = getTournamentId(t);
                return <Td id={`${column.id}-row`} key={index}>
                  <DropDownActions dropDown={dropDownOpen}
                                   id={tournamentId}
                                   onClick={() => this.handlerDropDownActions(tournamentId)}
                                   idDropDown={idDropDown}
                                   showDraft={t.state === TournamentState.Publish ||
                                   t.state === TournamentState.Archive}
                                   showEdit={t.state !== TournamentState.Archive}
                                   showArchive={t.state === TournamentState.Start ||
                                   t.state === TournamentState.Publish}
                                   showDelete={t.state === TournamentState.Archive}
                                   onDraft={this.onDraft}
                                   onDelete={this.onDelete}
                                   onEdit={this.onEdit}/>
                </Td>
              }
              return <Td key={index} id={`${column.id}-row`}>{column.getData(t)}</Td>
            })}
          </tr>,
        )}
        </tbody>
      </Table>
    )
  }

  renderTableSquare = (data) => {
    const {idDropDown, dropDownOpen} = this.state.expanded
    return (
      <Row>
        {data.map(t => (
          <TournamentCard data={t} key={t.id}>
            <Button color='primary' onClick={this.onEdit} data-tournamentid={getTournamentId(t)}>Edit</Button>
          </TournamentCard>
        ))}
      </Row>
    )
    // return (
    // 	<CartContentBlock>
    // 		<OrganizationCard>
    // 			<img src={bgI} className='border__images' alt=""/>
    // 			<div><img width='100%' src={imag} alt="#"/></div>
    // 			<img src={siver} className='silver' alt=""/>
    // 			<SingleTurnomentInfo>
    // 				<TournamentName>International Worlds Open Quallifiers #4'</TournamentName>
    // 				<InfoBlock>
    // 					<TimeBlock>
    // 						<Calendar>
    // 							<img src={calendar} alt=""/>
    // 							<TextInfo>January 18</TextInfo>
    // 						</Calendar>
    // 						<Clock>
    // 							<img src={clock} alt=""/>
    // 							<TextInfo>14:00 MSK</TextInfo>
    // 						</Clock>
    // 					</TimeBlock>
    // 					<UserBlock>
    // 						<XBlock>
    // 							<img src={X} width='13px' alt=""/>
    // 							<TextInfo>Closed registration</TextInfo>
    // 						</XBlock>
    // 						<Man>
    // 							<img src={man} alt=""/>
    // 							<TextInfo>8 players joined</TextInfo>
    // 						</Man>
    // 					</UserBlock>
    // 				</InfoBlock>
    // 				<AvatarBlock>
    // 					<img src={avatar} alt=""/>
    // 					<TextIn>Host by <span className='text_st'> CD Project Red</span></TextIn>
    // 				</AvatarBlock>
    // 				<SplitUp></SplitUp>
    // 				<EditPlayerBlock>
    // 					<div>
    // 						<img className='game__button' src={edit} alt=""/>
    // 						<img style={{cursor: "pointer"}} src={players} alt=""/>
    // 					</div>
    // 					<div>
    // 						<DropDownActions
    // 							dropDown={dropDownOpen}
    // 							idDropDown={idDropDown}
    // 							convertDraft={this.handlerConvertDraft}
    // 							onClick={() => this.handlerDropDownActions()}/>
    // 					</div>
    // 				</EditPlayerBlock>
    // 			</SingleTurnomentInfo>
    // 		</OrganizationCard>
    // 		<OrganizationCard>
    // 			<img src={bgI} className='border__images' alt=""/>
    // 			<div><img width='100%' src={imag} alt="#"/></div>
    // 			<img src={siver} className='silver' alt=""/>
    // 			<SingleTurnomentInfo>
    // 				<TournamentName>International Worlds Open Quallifiers #4'</TournamentName>
    // 				<InfoBlock>
    // 					<TimeBlock>
    // 						<Calendar>
    // 							<img src={calendar} alt=""/>
    // 							<TextInfo>January 18</TextInfo>
    // 						</Calendar>
    // 						<Clock>
    // 							<img src={clock} alt=""/>
    // 							<TextInfo>14:00 MSK</TextInfo>
    // 						</Clock>
    // 					</TimeBlock>
    // 					<UserBlock>
    // 						<XBlock>
    // 							<img src={X} width='13px' alt=""/>
    // 							<TextInfo>Closed registration</TextInfo>
    // 						</XBlock>
    // 						<Man>
    // 							<img src={man} alt=""/>
    // 							<TextInfo>8 players joined</TextInfo>
    // 						</Man>
    // 					</UserBlock>
    // 				</InfoBlock>
    // 				<AvatarBlock>
    // 					<img src={avatar} alt=""/>
    // 					<TextIn>Host by <span className='text_st'> CD Project Red</span></TextIn>
    // 				</AvatarBlock>
    // 				<SplitUp></SplitUp>
    // 				<EditPlayerBlock>
    // 					<div>
    // 						<img className='game__button' src={edit} alt=""/>
    // 						<img style={{cursor: "pointer"}} src={players} alt=""/>
    // 					</div>
    // 					<div>
    // 						<DropDownActions/>
    // 					</div>
    // 				</EditPlayerBlock>
    // 			</SingleTurnomentInfo>
    // 		</OrganizationCard>
    // 		<OrganizationCard>
    // 			<img src={bgI} className='border__images' alt=""/>
    // 			<div><img width='100%' src={imag} alt="#"/></div>
    // 			<img src={siver} className='silver' alt=""/>
    // 			<SingleTurnomentInfo>
    // 				<TournamentName>International Worlds Open Quallifiers #4'</TournamentName>
    // 				<InfoBlock>
    // 					<TimeBlock>
    // 						<Calendar>
    // 							<img src={calendar} alt=""/>
    // 							<TextInfo>January 18</TextInfo>
    // 						</Calendar>
    // 						<Clock>
    // 							<img src={clock} alt=""/>
    // 							<TextInfo>14:00 MSK</TextInfo>
    // 						</Clock>
    // 					</TimeBlock>
    // 					<UserBlock>
    // 						<XBlock>
    // 							<img src={X} width='13px' alt=""/>
    // 							<TextInfo>Closed registration</TextInfo>
    // 						</XBlock>
    // 						<Man>
    // 							<img src={man} alt=""/>
    // 							<TextInfo>8 players joined</TextInfo>
    // 						</Man>
    // 					</UserBlock>
    // 				</InfoBlock>
    // 				<AvatarBlock>
    // 					<img src={avatar} alt=""/>
    // 					<TextIn>Host by <span className='text_st'> CD Project Red</span></TextIn>
    // 				</AvatarBlock>
    // 				<SplitUp></SplitUp>
    // 				<EditPlayerBlock>
    // 					<div>
    // 						<img className='game__button' src={edit} alt=""/>
    // 						<img style={{cursor: "pointer"}} src={players} alt=""/>
    // 					</div>
    // 					<div>
    // 						<DropDownActions/>
    // 					</div>
    // 				</EditPlayerBlock>
    // 			</SingleTurnomentInfo>
    // 		</OrganizationCard>
    // 	</CartContentBlock>
    // )
  }

  renderModal = () => {
    const {modalOpen} = this.state
    return (
      <Modal open={modalOpen} title='Convert to draft Odrin Cup #3'>
        <ModalText>24 players have already joined this tournament.
          We will remove these players from the list and send them
          automatic message that this tournament was converted to draft.
          Are you sure?
        </ModalText>
        <ModalButtonWrapper>
          <Button color='primary' onClick={this.handlerModalClose}>no, i change my mind</Button>
          <Button color='light' onClick={this.handlerModalClose}>yes, convert to draft</Button>
        </ModalButtonWrapper>
      </Modal>
    )
  }
}

const Container = connect(mapState, mapDispatch)(Organizing)
export default Container

