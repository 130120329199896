import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import { Badge, Button } from 'reactstrap'
import { Match } from './match'
import { connect } from 'react-redux'

const mapState = ({query}) => ({
  queryParams: query.params,
})

const mapDispatch = ({tournament, query}) => ({
  addMatchSpectator: tournament.addMatchSpectator,
  removeMatchSpectator: tournament.removeMatchSpectator,
  startRound: tournament.startRound,
  querySet: query.set,
})

class CRounds extends React.Component {
  static propTypes = {
    games: PropTypes.array,
    className: PropTypes.string,
    tournamentId: PropTypes.string,
    manualStartRounds: PropTypes.bool,
    isEditable: PropTypes.bool,
    spectators: PropTypes.array,
  }

  static defaultProps = {
    games: [],
    className: '',
    manualStartRounds: false,
    isEditable: false,
    spectators: [],
  }

  state = {
    round: 0,
    gamesFilter: 0,
    editMatchId: 0,
  }

  onRoundClick = (e) => {
    this.props.querySet({params: {round: Number(e.target.attributes.value.value) + 1}})
    this.setState({round: parseInt(e.target.attributes.value.value)})
  }
  onFilterClick = (e) => this.setState({gamesFilter: parseInt(e.target.attributes.value.value)})

  onStartRoundClick = () => {
    const {round} = this.props.queryParams
    const {tournamentId, startRound} = this.props
    startRound({tournamentId, round})
  }

  onMatchEditToggle = (e) => {
    const value = Number(e.currentTarget.dataset.matchId)
    this.setState({editMatchId: value === this.state.editMatchId ? 0 : value})
  }

  componentDidUpdate(prevProps) {
    const {games} = this.props
    if (games && !isEqual(prevProps.games, games)) {
      this.props.querySet({params: {round: games.length}})
      this.setState({round: games.length - 1})
    }
  }

  render() {
    const {games, tournamentId, spectators, isEditable, queryParams: {round = 1}} = this.props
    const {gamesFilter, editMatchId} = this.state
    const roundIndex = round - 1
    if (!games || games.length === 0) return null
    let roundsCount = games.length
    const buttons = []
    for (let i = 0; i < roundsCount; i++) {
      buttons.push(
        <div key={i}
             className={`tournament-bracket__round-btn ${i === roundIndex ? '_active' : ''}`}
             value={i}
             onClick={this.onRoundClick}>{i + 1}</div>,
      )
    }

    const all = games[roundIndex].length
    const finished = games[roundIndex].filter(m => m.complete).length
    let fMatches = games[roundIndex]
    if (gamesFilter !== 0) {
      fMatches = fMatches.filter(m => gamesFilter === 1 ? m.complete : !m.complete)
    }
    let assignedSpectators = []
    let assignedSpectatorsId = []
    for (let match of fMatches) {
      for (let spectator of match.spectators) {
        assignedSpectators.push({spectator, match: {id: match.id}})
        assignedSpectatorsId.push(spectator.id)
      }
    }
    let availableSpectators = spectators.filter(s => !assignedSpectatorsId.includes(s.id))
    const g = []
    for (let i = 0; i < fMatches.length; i++) {
      let game = fMatches[i]
      let editCurrentMatch = editMatchId === game.id
      g.push(
        <div key={i} className={`tournament-bracket__sidebar-match ${editCurrentMatch ? '_edit' : ''}`}>
          <div className='tournament-bracket__sidebar-match-number'>{i + 1}</div>
          <Match key={game.id} data={game} className='_round-robin'
                 tournamentId={tournamentId}
                 spectators={spectators}
                 availableSpectators={availableSpectators}
                 assignedSpectators={assignedSpectators}
                 isEdit={isEditable && editCurrentMatch}
                 isEditable={isEditable}
                 addMatchSpectator={this.props.addMatchSpectator}
                 removeMatchSpectator={this.props.removeMatchSpectator}
                 onMatchEditClick={this.onMatchEditToggle}/>
        </div>,
      )
    }

    return (
      <div>
        <h3>Rounds:</h3>
        {buttons}
        {isEditable && (
          <>
            {this.renderStartRound()}
            {/*<Spectators spectators={spectators} tournamentId={tournamentId}/>*/}
          </>
        )}
        <h3>Actions</h3>
        <div className='tournament-bracket__round-filter'>
          <Badge color={gamesFilter === 0 ? 'primary' : 'secondary'} pill
                 className='badge-filter'
                 onClick={this.onFilterClick}
                 value={0}>
            All {all}
          </Badge>
          <Badge color={gamesFilter === 1 ? 'primary' : 'secondary'} pill
                 className='badge-filter'
                 onClick={this.onFilterClick}
                 value={1}>
            Finished {finished}
          </Badge>
          <Badge color={gamesFilter === 2 ? 'primary' : 'secondary'} pill
                 className='badge-filter'
                 onClick={this.onFilterClick}
                 value={2}>
            Unfinished {all - finished}
          </Badge>
        </div>
        {g}
      </div>
    )
  }

  renderStartRound() {
    const {manualStartRounds, roundsStarted, roundsReadyToStart} = this.props
    const round = this.state.round || 0;
    if (!manualStartRounds) return null

    if (roundsStarted[round+1]) {
      return (
        <Button color='secondary' className='tournament-bracket__sidebar-start-round'>
          Round started
        </Button>
      )
    }

    if (roundsReadyToStart && roundsReadyToStart.find(i => i.round === round)) {
      return (
        <Button color='primary' className='tournament-bracket__sidebar-start-round' onClick={this.onStartRoundClick}>
          Start this round
        </Button>
      )
    }

    return null
  }
}

export const Rounds = connect(mapState, mapDispatch)(CRounds)
