import styled from "styled-components";
// import bgModal from "../../../assets/back/bg-modal.png";
import bgModal from "../../../assets/border/double.png";

export const ModalContainer = styled.div`
	width: ${ props => props.width ? `${props.width}px` : '468px' };
	min-height: ${ props => props.height ? `${props.height}px` : '154px' };
	border: 22px solid;
  border-image: url(${bgModal}) 22 round round;
	//background: url(${bgModal});
	//background-size: cover;
	position: ${props => props.fixed ? 'fixed' : 'absolute'};
	top: ${ props => props.top ? `${props.top}px` : '50%'};
	left: ${ props => props.left ? props.left : '50%'};
	transform: ${ props => props.top || props.left || props.right || props.bottom ? '' : 'translate(-50%, -50%)'};
	padding: 0.5rem;
	display: ${ props => props.open ? 'block' : 'none' };
	background: ${ props => props.opacity ? `rgba(0, 0, 0, ${props.opacity})` : '#000000' };
	z-index: 100;
`;

export const ModalTitle = styled.div`
	font-family: "HalisGR-Bold";
	font-size: 18px;
	margin-bottom: 8px;
`;

export const ModalText = styled.div`
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 19px;
`;

export const ModalButtonWrapper = styled.div`
	display: flex;
	button:first-child {
		margin-right: 12px;
	}
`;
