import React from 'react'
import { MY_TOURNAMENTS, MY_TOURNAMENTS_ORGANIZING } from '../../routes'
import { NavLink, Nav } from './styles'

export const MyTournamentsMenu = () => (
  <Nav>
    <NavLink exact to={MY_TOURNAMENTS}>playing</NavLink>
    <NavLink to={MY_TOURNAMENTS_ORGANIZING}>organizing</NavLink>
  </Nav>
)
