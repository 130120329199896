import React from 'react'
import LayoutDefault from '../layouts/default'
import { Section, TitleWrapper, CheckBoxWrapper, ProfileSettingsWrapper } from './styles'
import Title from '../../atoms/title'
import { FormContainer } from '../../global-styles'
import { Row, Col, Button, FormGroup, Label, Input } from 'reactstrap'
import NewInput from '../../atoms/input'
import CheckBox from '../../atoms/checkbox'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import moment from 'moment-timezone'
import {ProfileMenu} from "./menu";

const tz = moment.tz.guess()

const continents = [
  'Africa',
  'America',
  'Asia',
  'Atlantic',
  'Australia',
  'Europe',
  'Indian',
  'Pacific',
]
const zones = moment.tz.names()
const options = []
const optGroups = {}
let currentOptGroup = ''
for (let zone of zones) {
  let index = zone.indexOf('/')
  if (index === -1) continue
  let optGroup = zone.substr(0, index)
  if (!continents.includes(optGroup)) continue
  if (currentOptGroup !== optGroup) {
    optGroups[optGroup] = []
    currentOptGroup = optGroup
  }
  optGroups[optGroup].push({
    zone,
    label: zone.substr(index + 1),
    offset: moment.tz.zone(zone).utcOffset((new Date()).getTime()) / 60 * -1,
  })
}
for (let [key, value] of Object.entries(optGroups)) {
  options.push(
    <optgroup label={key} key={key}>
      {value.sort((a, b) => a.offset - b.offset).map(i => (
        <option value={i.zone} key={i.zone}>
          GMT {i.offset === 0 ? '' : (i.offset > 0 ? `+${i.offset}` : i.offset)} {i.label}
        </option>
      ))}
    </optgroup>,
  )
}

const mapState = ({user}) => ({
  user,
})
const mapDispatch = ({user}) => ({
  logout: user.logout,
  edit: user.edit,
})

class CProfileSettings extends React.Component {
  state = {
    user: {
      email: '',
      emailNews: false,
      fbLink: '',
      twitterLink: '',
      discord: '',
      timeZone: tz,
      notificationSettings: {
        ADMIN_COMPLAINT: true,
        ADMIN_TOURNAMENT_CHECK_IN: true,
        ADMIN_TOURNAMENT_START: true,
        PLAYER_MATCH_BANS: true,
        PLAYER_OPPONENT_IS_FOUND: true,
        PLAYER_TOURNAMENT_CHECK_IN: true,
        PLAYER_TOURNAMENT_START: true,
      },
    },
  }

  constructor(props) {
    super(props)
    if (props.user.data) {
      this.state.user = props.user.data
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.user.data, this.props.user.data)) {
      this.setState({user: this.props.user.data})
    }
  }

  handlerInput = (e) => {
    this.setState({user: {...this.state.user, [e.target.name]: e.target.value}})
  }

  handlerCheckBox = (event) => {
    const id = event.currentTarget.id
    this.setState({user: {...this.state.user, [id]: !this.state.user[id]}})
  }

  handlerNotificationCheckBox = (event) => {
    const id = event.currentTarget.id
    this.setState({
      user: {
        ...this.state.user,
        notificationSettings: {
          ...this.state.user.notificationSettings,
          [id]: !this.state.user.notificationSettings[id],
        },
      },
    })
  }

  onLogoutClick = () => this.props.logout()

  onSaveClick = () => this.props.edit(this.state.user)

  render() {
    return (
      <LayoutDefault className='tournament-edit-page'>
        <ProfileMenu/>
        <ProfileSettingsWrapper>
          {this.renderPublicContacts()}
          {this.renderSiteNotifications()}
        </ProfileSettingsWrapper>
      </LayoutDefault>
    )
  }

  renderPublicContacts = () => {
    const {email, emailNews, twitterLink, fbLink, discord, timeZone} = this.state.user

    return (
      <Section>
        <TitleWrapper>
          <Title text='Public contacts'/>
        </TitleWrapper>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <NewInput title='EMAIL' placeholder='@mail.com' icon='email' name='email' value={email}
                        onChange={this.handlerInput}/>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox name='emailNews'
                          id='emailNews'
                          onChange={this.handlerCheckBox}
                          checked={emailNews}
                          text='Email me news of tournaments I’ve joined.'/>
              </CheckBoxWrapper>
            </Col>
          </Row>
        </FormContainer>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <NewInput {...{
                title: 'FACEBOOK',
                placeholder: '@facebookname',
                icon: 'facebook',
                name: 'fbLink',
                value: fbLink,
                style: {fontStyle: 'italic'},
                onChange: this.handlerInput,
              }}/>
            </Col>
          </Row>
        </FormContainer>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <NewInput {...{
                title: 'TWITTER',
                placeholder: '@twittername',
                icon: 'twitter',
                name: 'twitterLink',
                value: twitterLink,
                style: {fontStyle: 'italic'},
                onChange: this.handlerInput,
              }}/>
            </Col>
          </Row>
        </FormContainer>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <NewInput {...{
                title: 'DISCORD',
                placeholder: 'name#0000 or invite link',
                icon: 'discord',
                name: 'discord',
                value: discord,
                style: {fontStyle: 'italic'},
                onChange: this.handlerInput,
              }}/>
            </Col>
          </Row>
        </FormContainer>
        <h3></h3>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <Label for='timeZone'>Change your time zone</Label>
                <Input type='select' id='timeZone' name='timeZone'
                       value={timeZone || tz}
                       onChange={this.handlerInput}>
                  {options}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </FormContainer>
        <Row className='mb-2'>
          <Col lg={6}>Current time {moment().tz(tz).format('HH:mm z')}</Col>
        </Row>
        <Row>
          <Col xs={6}><Button color='primary' onClick={this.onLogoutClick}>Logout</Button></Col>
          <Col xs={6} className='d-flex justify-content-end'>
            <Button color='primary' onClick={this.onSaveClick}>Save</Button>
          </Col>
        </Row>
      </Section>
    )
  }

  renderSiteNotifications = () => {
    const {notificationSettings = {}} = this.state.user
    const {
      PLAYER_TOURNAMENT_CHECK_IN,
      PLAYER_TOURNAMENT_START,
      PLAYER_OPPONENT_IS_FOUND,
      PLAYER_MATCH_BANS,
      ADMIN_TOURNAMENT_CHECK_IN,
      ADMIN_TOURNAMENT_START,
      ADMIN_COMPLAINT,
    } = notificationSettings
    return (
      <Section>
        <TitleWrapper>
          <Title text='Site notifications'/>
        </TitleWrapper>
        <h4>As player notify me:</h4>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  id: 'PLAYER_TOURNAMENT_CHECK_IN',
                  onChange: this.handlerNotificationCheckBox,
                  checked: PLAYER_TOURNAMENT_CHECK_IN,
                  text: 'When tournament’s check-in starts',
                }}/>
              </CheckBoxWrapper>
            </Col>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  id: 'PLAYER_TOURNAMENT_START',
                  onChange: this.handlerNotificationCheckBox,
                  checked: PLAYER_TOURNAMENT_START,
                  text: 'When tournament starts',
                }}/>
              </CheckBoxWrapper>
            </Col>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  id: 'PLAYER_OPPONENT_IS_FOUND',
                  onChange: this.handlerNotificationCheckBox,
                  checked: PLAYER_OPPONENT_IS_FOUND,
                  text: 'When my opponent is found',
                }}/>
              </CheckBoxWrapper>
            </Col>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  id: 'PLAYER_MATCH_BANS',
                  onChange: this.handlerNotificationCheckBox,
                  checked: PLAYER_MATCH_BANS,
                  text: 'About match bans',
                }}/>
              </CheckBoxWrapper>
            </Col>
          </Row>
        </FormContainer>
        <h4>As admin notify me:</h4>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  id: 'ADMIN_TOURNAMENT_CHECK_IN',
                  onChange: this.handlerNotificationCheckBox,
                  checked: ADMIN_TOURNAMENT_CHECK_IN,
                  text: 'When tournament’s check-in starts',
                }}/>
              </CheckBoxWrapper>
            </Col>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  id: 'ADMIN_TOURNAMENT_START',
                  onChange: this.handlerNotificationCheckBox,
                  checked: ADMIN_TOURNAMENT_START,
                  text: 'When tournament starts',
                }}/>
              </CheckBoxWrapper>
            </Col>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  id: 'ADMIN_COMPLAINT',
                  onChange: this.handlerNotificationCheckBox,
                  checked: ADMIN_COMPLAINT,
                  text: 'When a player reported problem',
                }}/>
              </CheckBoxWrapper>
            </Col>
          </Row>
        </FormContainer>
        {/*<h6>Overall</h6>
        <FormContainer>
          <Row>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  name: 'emailNews',
                  id: 'emailNews',
                  onChange: this.handlerCheckBox,
                  checked: emailNews,
                  text: 'Turn off sound',
                }}/>
              </CheckBoxWrapper>
            </Col>
            <Col lg={12}>
              <CheckBoxWrapper>
                <CheckBox {...{
                  name: 'emailNews',
                  id: 'emailNews',
                  onChange: this.handlerCheckBox,
                  checked: emailNews,
                  text: `Don’t show popups (you still be able  to check all notifications in the top menu)`,
                }}/>
              </CheckBoxWrapper>
            </Col>
          </Row>
        </FormContainer>
        <Row>
          <Col lg={6}/>
          <Col lg={{size: 3, offset: 3}}>
            <Button color='primary' onClick={this.onSubmit}>Save</Button>
          </Col>
        </Row>*/}
      </Section>
    )
  }
}

const ProfileSettings = connect(mapState, mapDispatch)(CProfileSettings)
export default ProfileSettings
