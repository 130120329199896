import React, { Component } from 'react'
import LayoutDefault from '../layouts/default'
import { TournamentCard } from '../../tournament-card'
import { connect } from 'react-redux'
import {
  Button,
  Row,
  Container,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import { onChange } from '../../../utils/on-change'
import Search from '../../ico/search'
import isEqual from 'lodash/isEqual'
import { MyTournamentsMenu } from './menu'
import { TournamentState, UserState } from '../../../constants/tournament'
import Modal from '../../atoms/modal'
import { ModalButtonWrapper, ModalText } from '../../atoms/modal/styles'

const mapState = ({user}) => ({
  user,
})
const mapDispatch = ({tournament, user}) => ({
  leave: tournament.leave,
  loadTournamentPlaying: user.loadTournamentPlaying,
})

class CPlayingPage extends Component {
  state = {
    search: {
      time: -1,
    },
    name: '',
    showModalLeave: false,
    tournamentId: null,
  }

  constructor(props) {
    super(props)
    props.loadTournamentPlaying()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.search, this.state.search)) {
      this.props.loadTournamentPlaying(this.state.search)
    }
  }

  onLeaveClick = () => {
    this.props.leave(this.state.tournamentId)
    this.onCloseModal()
  }

  onShowModalLeave = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({showModalLeave: true, tournamentId: Number(e.target.dataset.tournament)})
  }

  onCloseModal = () => {
    this.setState({showModalLeave: false})
  }

  onChangeSearch = onChange(result => this.setState({search: {...this.state.search, [result.name]: result.value}}))
  onChange = onChange(result => this.setState({[result.name]: result.value}))

  render() {
    const {search: {time}, name} = this.state
    const {tournamentsPlaying: {data = []}} = this.props.user
    let result = data
    if (name && name.length > 1) {
      const nameLower = name.toLowerCase().trim()
      result = result.filter(t => t.name.toLowerCase().includes(nameLower))
    }

    return (
      <LayoutDefault className='tournament-edit-page'>
        <MyTournamentsMenu/>
        <div className='tournament-search'>
          <Row>
            <Col lg={2}>
              <FormGroup>
                <Label for='time'>Time</Label>
                <Input type='select' id='time'
                       value={time}
                       onChange={this.onChangeSearch.int}>
                  <option value={-1}>Any</option>
                  <option value={0}>Upcoming</option>
                  <option value={1}>Started</option>
                </Input>
              </FormGroup>
            </Col>

            <Col lg={4} className='ml-auto'>
              <FormGroup>
                <Label for='name'>Search</Label>
                <InputGroup>
                  <Input type='text' id='name' value={name} placeholder='Search tournament by name'
                         onChange={this.onChange.string}/>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText><Search/></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>


          </Row>
        </div>
        <Row>
          {this.renderModalLeave()}
          {result.map(t => this.renderTournament(t))}
        </Row>
      </LayoutDefault>
    )
  }

  renderTournament(t) {
    return (
      <TournamentCard key={t.id} data={t}>
        {(t.state === TournamentState.Publish || t.state === TournamentState.Start || t.state === TournamentState.PreStart) &&
        t.userStatus.state !== UserState.TECHNICAL_LOSE && !t.isComplete && (
          <Button color='secondary' className='ml-auto' data-tournament={t.id} onClick={this.onShowModalLeave}>
            Leave
          </Button>
        )}
      </TournamentCard>
    )
  }

  renderModalLeave() {
    const {tournamentsPlaying: {data = []}} = this.props.user
    const tournament = data.find(t => t.id === this.state.tournamentId)
    return (
      <Modal open={this.state.showModalLeave} title={`Leave tournament "${tournament ? tournament.name : ''}"`} fixed={true}>
        <ModalText>Are you sure?</ModalText>
        <ModalButtonWrapper>
          <Button color='primary' onClick={this.onCloseModal}>no, i change my mind</Button>
          <Button color='light' data-force='1' onClick={this.onLeaveClick}>yes, leave tournament</Button>
        </ModalButtonWrapper>
      </Modal>
    )
  }

  /*renderTournaments(tournament) {
    return (
      <div key={tournament.id}>
        <div className='tournament__block_main'>
          <img className='border__ima' src={bacground} alt=''/>
          <div className='images_block'>
            <img width='100%' src={goldPhotto} alt=''/>
            <div className='main_edit_btn_header'>
              <img height='100%' src={greyRbutton} alt=''/>
              <button>starts in x day</button>
              <img height='100%' src={greyLbutton} alt=''/>
            </div>
          </div>
          <div className='info__title_user_block'>
            <div className='info_title'>
              <h2>{tournament.name}</h2>
            </div>
            <div className='info__user_block'>
              <div className='info__user_block_info'>
                <div className='user_block_chek'>
                  <div>
                    <img src={calendar} alt=''/>
                    <p>January 18</p>
                  </div>
                  <div>
                    <img src={clock} alt=''/>
                    <p>14:00 MSK</p>
                  </div>
                </div>
                <div className='user_block_chek'>
                  <div>
                    <img width='12px' height='12px' src={chekid} alt=''/>
                    <p>Closed registration</p>
                  </div>
                  <div>
                    <img src={man} alt=''/>
                    <p>8 players joined</p>
                  </div>
                </div>
              </div>
              <div className='user__avatar_block'>
                <img src={avatar} alt=''/>
                <p>Hosted by CD <span>Project Red</span></p>
              </div>
            </div>
            <div className='line_div'/>
            <div className='main_button__group'>
              <div className='main_left_btn'>
                <div className='main_edit_btn'>
                  <img src={leftButton} height='100%' alt=''/>
                  <button className='btnChecked'>
                    <img src={activ} alt=''/>
                    <span>checked-in</span>
                  </button>
                  <svg xmlns='http://www.w3.org/2000/svg' width='6' height='28' viewBox='0 0 6 27' fill='none'>
                    <path d='M0 26L5 26L5 17L1.5 13.5L5 11L5 1L2.18556e-06 1' stroke='#95E5BA'/>
                  </svg>
                </div>
                <div className='main_edit_btn_2'>
                  <img height='100%' src={greyRbutton} alt=''/>
                  <button>leave</button>
                  <img height='100%' src={greyLbutton} alt=''/>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }*/
}

export const PlayingPage = connect(mapState, mapDispatch)(CPlayingPage)
