import React from 'react'
import { SingleElimination } from './single-elemination'
import { DoubleElimination } from './double-elemination'
import { RoundRobin } from './round-robin'
import { Swiss } from './swiss'
import MaterialIcon from '@material/react-material-icon'
import { Button } from 'reactstrap'

export class TournamentPageBracketsPreview extends React.PureComponent {
  state = {
    showSeed: false,
    seedMatch: 0,
    seedPlayer: 1,
    seedBracket: 0,
  }

  constructor(props) {
    super(props)
    if (props.tournament.data.manualSeeding) {
      props.resetSeeding()
    }
  }

  onPlayerClick = (seedBracket, seedMatch, seedPlayer) => {
    this.setState({seedBracket, seedMatch, seedPlayer, showSeed: true})
  }

  onPlayerClear = (seedBracket, seedMatch, seedPlayer) => {
    const seeding = [...this.props.tournament.seeding]
    seeding[seedBracket][seedMatch][seedPlayer] = null
    if (!seeding[seedBracket][seedMatch][0] && !seeding[seedBracket][seedMatch][1]) {
      seeding[seedBracket].splice(seedMatch, 1)
    }
    this.props.setSeeding(seeding)
  }

  onPlayerSelect = (e) => {
    const player = this.props.tournament.data.participants.find(p => p.id === e.currentTarget.id)
    const seeding = [...this.props.tournament.seeding]
    const {seedMatch, seedPlayer, seedBracket} = this.state
    if (!seeding[seedBracket]) seeding[seedBracket] = []
    if (!seeding[seedBracket][seedMatch]) seeding[seedBracket][seedMatch] = []
    seeding[seedBracket][seedMatch][seedPlayer] = player
    this.setState({showSeed: false})
    this.props.setSeeding(seeding)
  }

  onPlayerUnSelect = (e) => {
    this.onPlayerClear(
      parseInt(e.currentTarget.dataset.bracket),
      parseInt(e.currentTarget.dataset.match),
      parseInt(e.currentTarget.dataset.player),
    )
  }

  render() {
    const {data} = this.props.tournament
    return [
      <div key='preview' className='tournament-bracket__preview'>
        <div className='tournament-bracket__preview-head'>Check preview and activate brackets</div>
        <p>
          This is the preview of how the brackets grid will looks like according to settings. <br/>
          Check it last time and if it’s fine – and start the tournament.
        </p>
        {data.playersMin > data.playersCount && (
          <>
            <hr/>
            <div className='tournament-bracket__preview-error'>
              Minimum {data.playersMin} players are required to start the tournament.
            </div>
          </>
        )}
        <hr/>
        {data.manualSeeding ? (
          <div className='tournament-bracket__preview-manual'>
            <div>
              <div className='tournament-bracket__preview-head'>Manual seeding</div>
              <p>You enabled manual seeding, so you can seed the players in the way you like.</p>
            </div>
            <Button color='danger' onClick={this.props.onSwitchSeeding}>Switch to automatic seeding</Button>
            {this.renderSeed()}
          </div>
        ) : null}
      </div>,
      this.renderBracket()
    ]
  }

  renderBracket() {
    const {data, seeding} = this.props.tournament
    switch (data.bracketType) {
      case 0:
        return <SingleElimination key='bracket' tournament={data}
                                  players={data.playersRealCount}
                                  third={data.bracketParams.third}
                                  matchFormat={data.bracketParams.matchFormat}
                                  finalMatchFormat={data.bracketParams.finalMatchFormat}
                                  seeding={data.manualSeeding ? seeding : null}
                                  onPlayerClick={this.onPlayerClick}
                                  onPlayerClear={this.onPlayerClear}/>
      case 1:
        return <DoubleElimination key='bracket' tournament={data}
                                  players={data.playersRealCount}
                                  matchFormat={data.bracketParams.matchFormat}
                                  finalMatchFormat={data.bracketParams.finalMatchFormat}
                                  seeding={data.manualSeeding ? seeding : null}
                                  onPlayerClick={this.onPlayerClick}
                                  onPlayerClear={this.onPlayerClear}/>
      case 2:
        return <RoundRobin key='bracket' players={data.playersCount}
                           groups={data.bracketParams.groupsCount}
                           playersInGroup={data.playersCountInGroup}
                           matchFormat={data.bracketParams.matchFormat}
                           seeding={data.manualSeeding ? seeding : null}
                           onPlayerClick={this.onPlayerClick}
                           onPlayerClear={this.onPlayerClear}/>;
      case 3:
        return <Swiss key='bracket' players={data.playersCount}
                      matchFormat={data.bracketParams.matchFormat}/>
      default:
        return null
    }
  }

  renderSeed() {
    const {showSeed, seedMatch, seedPlayer, seedBracket} = this.state
    if (!showSeed) return null
    const {data: {bracketType, participants}, seeding} = this.props.tournament
    const seededIds = []
    const seeded = []
    const notSeeded = []
    for (let i = 0; i < seeding.length; i++) {
      if (!seeding[i]) continue
      for (let k = 0; k < seeding[i].length; k++) {
        if (!seeding[i][k]) continue
        for (let j = 0; j < seeding[i][k].length; j++) {
          if (!seeding[i][k][j]) continue
          let pData = {...seeding[i][k][j]}
          pData.bracket = i
          pData.match = k
          pData.player = j
          seeded.push(pData)
          seededIds.push(pData.id)
        }
      }
    }

    for (let player of participants) {
      if (seededIds.includes(player.id)) continue
      notSeeded.push(player)
    }

    let bracket = '_single'
    if (bracketType === 1) bracket = '_double'
    else if (bracketType === 2) bracket = '_round-robin'

    return (
      <div className={`tournament-bracket__seed _m${seedMatch} _p${seedPlayer} _b${seedBracket} ${bracket}`}>
        <div className='tournament-bracket__seed-body'>
          <div className='tournament-bracket__seed-head'>Select a player to seed</div>
          <div className='tournament-bracket__seed-players'>
            <div className='tournament-bracket__seed-players-col'>
              <div className='tournament-bracket__seed-players-head _1'>Not seeded</div>
              {notSeeded.map(p => (
                <div className='tournament-bracket__seed-players-item' id={p.id} key={p.id}
                     onClick={this.onPlayerSelect}>
                  {p.name}
                </div>
              ))}
            </div>
            <div className='tournament-bracket__seed-players-col'>
              <div className='tournament-bracket__seed-players-head'>Seeded</div>
              {seeded.map(p => (
                <div className='tournament-bracket__seed-players-item' key={p.id}
                     data-bracket={p.bracket}
                     data-match={p.match}
                     data-player={p.player}
                     onClick={this.onPlayerUnSelect}>
                  {p.name}
                  <MaterialIcon icon='close'/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
