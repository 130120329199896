import React from 'react'
import PropTypes from 'prop-types'
import { Match } from './match'
import { Round } from './round'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Spectators } from './spectators'
import { connect } from 'react-redux'
import { getRoundSpectators } from '../../../../utils/spectators'
import { Button } from 'reactstrap'

const mapState = ({}) => ({})

const mapDispatch = ({tournament}) => ({
  addMatchSpectator: tournament.addMatchSpectator,
  removeMatchSpectator: tournament.removeMatchSpectator,
  startRound: tournament.startRound,
})

class CSingleElimination extends React.PureComponent {
  static propTypes = {
    players: PropTypes.number.isRequired,
    third: PropTypes.bool,
    data: PropTypes.object,
    className: PropTypes.string,
    hideContainer: PropTypes.bool,
    manualStartRounds: PropTypes.bool,
    isEditable: PropTypes.bool,
    spectators: PropTypes.array,
    tournament: PropTypes.object,
  }

  static defaultProps = {
    third: false,
    data: {winner: [], third: {}},
    className: '',
    hideContainer: false,
    manualStartRounds: false,
    isEditable: false,
    spectators: [],
    tournament: {},
  }

  state = {
    editMatchId: 0,
  }

  onMatchEditToggle = (e) => {
    const value = Number(e.currentTarget.dataset.matchId)
    this.setState({editMatchId: value === this.state.editMatchId ? 0 : value})
  }

  onStartRoundClick = (e) => {
    const {tournamentId, startRound} = this.props
    startRound({tournamentId, round: e.target.dataset.round, bracket: e.target.dataset.bracket})
  }

  render() {
    let {
      data,
      players,
      third,
      className,
      matchFormat,
      finalMatchFormat,
      seeding,
      onPlayerClick,
      onPlayerClear,
      hideContainer,
      tournamentId,
      spectators,
      isEditable,
      tournament
    } = this.props
    const {editMatchId} = this.state
    let matchCount = players / 2
    const roundCount = Math.log(players) / Math.log(2)
    const rounds = []
    for (let i = 0; i < roundCount; i++) {
      let matches = []
      let finalRound = i === roundCount - 1
      let firstRound = i === 0
      let roundSpectators = getRoundSpectators(spectators, data.winner && data.winner[i] ? data.winner[i] : [])
      for (let j = 0; j < matchCount; j++) {
        let key = i + '-' + j
        let currentMatch = data.winner && data.winner[i] && data.winner[i][j] ? data.winner[i][j] : {}
        let editCurrentMatch = currentMatch.id === editMatchId
        matches.push(
          <Match key={key} className='_single'
                 data={currentMatch}
                 seeding={seeding && firstRound ? (seeding[0][j] || {}) : null}
                 bracket={0}
                 index={j}
                 onPlayerClick={onPlayerClick}
                 onPlayerClear={onPlayerClear}
                 tournamentId={tournamentId}
                 spectators={spectators}
                 availableSpectators={roundSpectators.available}
                 assignedSpectators={roundSpectators.assigned}
                 isEdit={isEditable && editCurrentMatch}
                 isEditable={isEditable}
                 addMatchSpectator={this.props.addMatchSpectator}
                 removeMatchSpectator={this.props.removeMatchSpectator}
                 resetMatchHandler={this.props.resetMatchHandler}
                 showResetButton={true}
                 onMatchEditClick={this.onMatchEditToggle}/>,
        )
      }
      rounds.push(
        <Round key={i}
               className='_single'
               name={'Round ' + (i + 1)}
               index={i}
               matchFormat={finalRound ? finalMatchFormat : matchFormat}
               bracket={0}
               isEditable={isEditable}
               onStartRoundClick={this.onStartRoundClick}
               manualStartRounds={tournament.manualStartRounds}
               roundsStarted={tournament.roundsStarted}
               roundsReadyToStart={tournament.roundsReadyToStart}>
          {matches}
        </Round>,
      )
      matchCount /= 2
    }
    let bracketThird = null
    if (third && players > 2) {
      let lastRoundMatches = data.winner && data.winner[data.winner.length - 1] ? data.winner[data.winner.length - 1] : []
      lastRoundMatches = [...lastRoundMatches, data.third]
      let editLastMatch = data.third && data.third.id === editMatchId
      let roundSpectators = getRoundSpectators(spectators, lastRoundMatches)
      bracketThird = (
        <div className={'bracket__third _' + (roundCount - 1)}>
          <Round key='third' name='Third place match'
                 index={0}
                 matchFormat={matchFormat}
                 bracket={1}
                 isEditable={isEditable}
                 onStartRoundClick={this.onStartRoundClick}
                 manualStartRounds={tournament.manualStartRounds}
                 roundsStarted={tournament.roundsStarted}
                 roundsReadyToStart={tournament.roundsReadyToStart}>
            <Match data={data.third || {}}
                   tournamentId={tournamentId}
                   spectators={spectators}
                   availableSpectators={roundSpectators.available}
                   assignedSpectators={roundSpectators.assigned}
                   isEdit={isEditable && editLastMatch}
                   isEditable={isEditable}
                   addMatchSpectator={this.props.addMatchSpectator}
                   removeMatchSpectator={this.props.removeMatchSpectator}
                   resetMatchHandler={this.props.resetMatchHandler}
                   showResetButton={true}
                   onMatchEditClick={this.onMatchEditToggle}/>
          </Round>
        </div>
      )
    }

    let result = (
      <div className={`bracket-single _${players} ${third ? '_third ' : ''} ${editMatchId && !hideContainer ? '_edit ' : ''}${className}`}>
        <div className='bracket__winner'>{rounds}</div>
        {bracketThird}
      </div>
    )

    if (!hideContainer) {
      result = (
        <div className='tournament-bracket'>
          <PerfectScrollbar>
            {result}
          </PerfectScrollbar>
        </div>
      )
    }

    return result
  }
}

export const SingleElimination = connect(mapState, mapDispatch)(CSingleElimination)
