import React from 'react';

export default () => {
	return (
		<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.166 27.5001H23.8327V31.1667H20.166V27.5001ZM20.166 12.8334H23.8327V23.8334H20.166V12.8334ZM21.981 3.66675C11.861 3.66675 3.66602 11.8801 3.66602 22.0001C3.66602 32.1201 11.861 40.3334 21.981 40.3334C32.1193 40.3334 40.3327 32.1201 40.3327 22.0001C40.3327 11.8801 32.1193 3.66675 21.981 3.66675ZM21.9993 36.6667C13.896 36.6667 7.33268 30.1034 7.33268 22.0001C7.33268 13.8967 13.896 7.33341 21.9993 7.33341C30.1027 7.33341 36.666 13.8967 36.666 22.0001C36.666 30.1034 30.1027 36.6667 21.9993 36.6667Z" fill="#3890FF"/>
		</svg>
	)
}


