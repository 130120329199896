import React from 'react'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {EditorState, ContentState, convertToRaw} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import {handlePastedText} from './handlePaste'
import bold from '../../assets/input-icons/bold.png'
import letter from '../../assets/input-icons/letter-i.png'
import text from '../../assets/input-icons/text.svg'
import photo from '../../assets/input-icons/photo.png'
import video from '../../assets/input-icons/video-coll.png'
import link from '../../assets/input-icons/link.png'
export class WYSIWYG extends React.Component {
	state = {
		editorState: EditorState.createEmpty(),
	}

	constructor(props) {
		super(props)
		if (props.value) {
			const {contentBlocks, entityMap} = htmlToDraft(props.value)
			this.state.editorState = EditorState.createWithContent(
				ContentState.createFromBlockArray(contentBlocks, entityMap),
			)
		}
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState,
		})
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		this.props.onChange({
			target: {
				type: 'WYSIWYG',
				value: html,
				name: this.props.name || '',
				id: this.props.id || '',
			},
		})
	}

	onPaste = (text, html, editorState, onChange) => handlePastedText(text, text, editorState, onChange)

	render() {
		return (
			<Editor
				id={this.props.id || 'WYSIWYG'}
				placeholder={this.props.placeholder || ''}
				editorState={this.state.editorState}
				onEditorStateChange={this.onEditorStateChange}
				handlePastedText={this.onPaste}
				wrapperClassName='tournament-editor'
				editorClassName='tournament-editor__main'
				toolbarClassName='tournament-editor__toolbar'
				toolbar={{
					options: ['inline','textAlign', 'fontSize','image','embedded','link'],
					inline: {
						inDropdown: false,
						className: undefined,
						component: undefined,
						dropdownClassName: undefined,
						options: ['bold', 'italic'],
						bold: {icon: bold, className: 'demo-option-custom'},
						italic: {icon: letter, className: 'demo-option-custom'},
					},
					textAlign: {
						options: ['left', 'center', 'right']
					},
					fontSize: {
						icon: text,
						options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
						className: 'demo-option-custom',
						component: undefined,
						popupClassName: 'demo-option-custom',
					},
					image: {
						icon: photo,
						className: 'demo-option-custom_photo',
						component: undefined,
						popupClassName: undefined,
						urlEnabled: true,
						uploadEnabled: true,
						alignmentEnabled: true,
						uploadCallback: undefined,
						previewImage: true,
						inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
						alt: {present: true, mandatory: true},
						defaultSize: {
							height: 'auto',
							width: 'auto',
						},
					},
					embedded: {
						icon: video,
						className: 'demo-option-custom',
						component: undefined,
						popupClassName: 'demo-option-custom',
						embedCallback: undefined,
						defaultSize: {
							height: 'auto',
							width: 'auto',
						},
					},
					link: {
						inDropdown: false,
						className: undefined,
						component: undefined,
						popupClassName: undefined,
						dropdownClassName: undefined,
						showOpenOptionOnHover: true,
						defaultTargetOption: '_self',
						options: ['link',],
						link: { icon: link, className: 'demo-option-custom' },
						linkCallback: undefined
					},
				}}
			/>
		)
	}
}
