import React from 'react';

export default () => (
	<svg width="13" height="7" viewBox="0 0 13 7" fill="none">
		<line x1="1.02006" y1="0.479699" x2="6.85339" y2="6.31303" stroke="#131313"/>
		<line x1="6.14645" y1="6.31295" x2="11.9798" y2="0.479616" stroke="#131313"/>
	</svg>
)


