import React from 'react'
import PropTypes from 'prop-types'
import { SingleElimination } from './single-elemination'
import { Match } from './match'
import { Round } from './round'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Spectators } from './spectators'
import { getRoundSpectators } from '../../../../utils/spectators'
import { connect } from 'react-redux'

const mapState = ({}) => ({})

const mapDispatch = ({tournament}) => ({
  addMatchSpectator: tournament.addMatchSpectator,
  removeMatchSpectator: tournament.removeMatchSpectator,
  startRound: tournament.startRound,
})

export class CDoubleElimination extends React.PureComponent {
  static propTypes = {
    players: PropTypes.number.isRequired,
    data: PropTypes.object,
    className: PropTypes.string,
    manualStartRounds: PropTypes.bool,
    isEditable: PropTypes.bool,
    spectators: PropTypes.array,
    tournament: PropTypes.object,
  }

  static defaultProps = {
    data: {main: [], third: {}},
    className: '',
    manualStartRounds: false,
    isEditable: false,
    spectators: [],
    tournament: {},
  }

  state = {
    editMatchId: 0,
  }

  onMatchEditToggle = (e) => {
    const value = Number(e.currentTarget.dataset.matchId)
    this.setState({editMatchId: value === this.state.editMatchId ? 0 : value})
  }

  onStartRoundClick = (e) => {
    const {tournamentId, startRound} = this.props
    startRound({tournamentId, round: e.target.dataset.round, bracket: e.target.dataset.bracket})
  }

  render() {
    let {
      players,
      className,
      data,
      seeding,
      onPlayerClick,
      onPlayerClear,
      matchFormat,
      finalMatchFormat,
      tournamentId,
      spectators,
      isEditable,
      tournament
    } = this.props
    const {editMatchId} = this.state
    let matchCount = players / 4
    const roundCount = Math.log(players) / Math.log(2) - 1
    const rounds = []
    for (let i = 0; i < roundCount; i++) {
      let firstRound = i === 0
      for (let j = 0; j < 2; j++) {
        let roundN = i * 2 + j
        let matches = []
        let roundSpectators = getRoundSpectators(spectators, data.loser && data.loser[roundN] ? data.loser[roundN] : [])
        for (let k = 0; k < matchCount; k++) {
          let key = roundN + '-' + k
          let currentMatch = data.loser && data.loser[roundN] && data.loser[roundN][k] ? data.loser[roundN][k] : {}
          let editCurrentMatch = currentMatch.id === editMatchId
          matches.push(
            <Match key={key} className='_double'
                   index={j}
                   data={currentMatch}
                   tournamentId={tournamentId}
                   spectators={spectators}
                   availableSpectators={roundSpectators.available}
                   assignedSpectators={roundSpectators.assigned}
                   isEdit={isEditable && editCurrentMatch}
                   isEditable={isEditable}
                   addMatchSpectator={this.props.addMatchSpectator}
                   removeMatchSpectator={this.props.removeMatchSpectator}
                   resetMatchHandler={this.props.resetMatchHandler}
                   showResetButton={true}
                   onMatchEditClick={this.onMatchEditToggle}/>,
          )
        }

        rounds.push(
          <Round key={roundN} className='_double'
                 name={'Losers round ' + (roundN + 1)}
                 index={roundN}
                 matchFormat={matchFormat}
                 bracket={1}
                 isEditable={isEditable}
                 onStartRoundClick={this.onStartRoundClick}
                 manualStartRounds={tournament.manualStartRounds}
                 roundsStarted={tournament.roundsStarted}
                 roundsReadyToStart={tournament.roundsReadyToStart}>
            {matches}
            </Round>,
        )
      }
      matchCount /= 2
    }

    let lastRoundMatches = data.final ? [data.final] : [];
    let editLastMatch = data.final && data.final.id === editMatchId
    let roundSpectators = getRoundSpectators(spectators, lastRoundMatches)

    let final = null
    if (tournament.bracketParams.finalMatchesCount == null || tournament.bracketParams.finalMatchesCount > 0) {
      final = (
        <div className='bracket__final'>
          <Round key='final' name='Grand finals' index={roundCount + 1} matchFormat={finalMatchFormat}
                 bracket={2}
                 isEditable={isEditable}
                 onStartRoundClick={this.onStartRoundClick}
                 manualStartRounds={tournament.manualStartRounds}
                 roundsStarted={tournament.roundsStarted}
                 roundsReadyToStart={tournament.roundsReadyToStart}>
            <Match data={data.final && data.final.length > 0 ? data.final[0] : {}} className='_single _final'
                   tournamentId={tournamentId}
                   spectators={spectators}
                   availableSpectators={roundSpectators.available}
                   assignedSpectators={roundSpectators.assigned}
                   isEdit={isEditable && editLastMatch}
                   isEditable={isEditable}
                   addMatchSpectator={this.props.addMatchSpectator}
                   removeMatchSpectator={this.props.removeMatchSpectator}
                   resetMatchHandler={this.props.resetMatchHandler}
                   showResetButton={true}
                   onMatchEditClick={this.onMatchEditToggle}/>
          </Round>
          {tournament.bracketParams.finalMatchesCount > 1 && (
            <Round key='final2' name='Grand finals' index={roundCount + 1} matchFormat={finalMatchFormat}
                   bracket={2}
                   isEditable={isEditable}
                   onStartRoundClick={this.onStartRoundClick}
                   manualStartRounds={tournament.manualStartRounds}
                   roundsStarted={tournament.roundsStarted}
                   roundsReadyToStart={tournament.roundsReadyToStart}>
              <Match data={data.final && data.final.length > 1 ? data.final[1] : {}} className='_single _final _final2'
                     tournamentId={tournamentId}
                     spectators={spectators}
                     availableSpectators={roundSpectators.available}
                     assignedSpectators={roundSpectators.assigned}
                     isEdit={isEditable && editLastMatch}
                     isEditable={isEditable}
                     addMatchSpectator={this.props.addMatchSpectator}
                     removeMatchSpectator={this.props.removeMatchSpectator}
                     resetMatchHandler={this.props.resetMatchHandler}
                     showResetButton={true}
                     onMatchEditClick={this.onMatchEditToggle}/>
            </Round>
          )}
        </div>
      )
    }

    return (
      <div className='tournament-bracket'>
        {/*<div className='tournament-bracket__header'>
          {isEditable && <Spectators spectators={spectators} tournamentId={tournamentId} isPopup={true}/>}
        </div>*/}
        <PerfectScrollbar>
          <div className={`bracket-double _${players} ${className}`}>
            <div className='bracket-double__brackets'>
              <SingleElimination hideContainer={true} {...this.props}/>
              <div className={`bracket__loser ${editMatchId ? '_edit' : ''}`}>{rounds}</div>
            </div>
            {final}
          </div>
        </PerfectScrollbar>
      </div>
    )
  }
}

export const DoubleElimination = connect(mapState, mapDispatch)(CDoubleElimination)
