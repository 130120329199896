import React from 'react'
import { Button } from 'reactstrap'

export class Round extends React.PureComponent {
  render() {
    const {name, index, matchFormat = 3, className = '', children} = this.props
    return (
      <div className={`bracket__round ${className} _${index}`}>
        <div className='bracket__round-name'>{name}</div>
        <div className='bracket__round-format'>Best of {matchFormat}</div>
        {this.renderStartRound()}
        <div className='bracket__round-games'>
          {children}
        </div>
      </div>
    )
  }

  renderStartRound() {
    const {
      manualStartRounds,
      roundsStarted,
      index,
      bracket,
      roundsReadyToStart,
      onStartRoundClick,
      isEditable
    } = this.props
    if (!manualStartRounds || !roundsStarted || !isEditable) return null
    let round = index
    if (bracket === 2) round = 0

    if (roundsStarted[round] && roundsStarted[round].find(i => i.bracket === bracket)) {
      return (
        <div className='bracket__round-start'>
          <Button color='secondary'>
            Round started
          </Button>
        </div>
      )
    }

    if (roundsReadyToStart && roundsReadyToStart.find(i => i.round === round && i.bracket === bracket)) {
      return (
        <div className='bracket__round-start'>
          <Button color='primary' data-round={round} data-bracket={bracket} onClick={onStartRoundClick}>
            Start this round
          </Button>
        </div>
      )
    }

    return <div className='bracket__round-start'/>
  }
}

