import React from 'react'
import { LEADERS } from '../../../../constants/cards'
import Modal from '../../../atoms/modal'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { WYSIWYG } from '../../../wysiwyg'
import { onChange } from '../../../../utils/on-change'
import { Avatar } from '../../../avatar'
import { tournamentMatch } from '../../../routes'
import { Link } from 'react-router-dom'

export class ProblemModal extends React.PureComponent {
  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const {problem} = this.props
    if (!problem) return null
    const p1 = problem.match.players[0] ? problem.match.players[0].name : '-'
    const p2 = problem.match.players[1] ? problem.match.players[1].name : '-'
    return (
      <Modal open={true}
             height={320}
             width={500}
             title={`Player problem`}
             opacity='0.87'>

        <div className='match__lobby-player'>
          <Avatar className='match__lobby-avatar' avatar={problem.user.avatar} border={problem.user.border}/>
          <div className='match__lobby-player-info'>
            <div className='match__lobby-player-name'>{problem.user.name}</div>
            <Link to={tournamentMatch(problem.tournament, problem.match.id)}>match</Link>
            ({p1} vs {p2})
          </div>
        </div>
        <ProblemWrapper dangerouslySetInnerHTML={{__html: problem.message}}/>

        <ButtonWrapper>
          <Button color='primary' onClick={this.onClose}>close</Button>
        </ButtonWrapper>
      </Modal>
    )
  }
}

const ButtonWrapper = styled.div`
	margin-top: 24px;
	> button {
		width: 100%;
	}
`

const ProblemWrapper = styled.div`
  margin-top: 24px;
`
