import React from 'react'
import PropTypes from 'prop-types'
import { Table, Row, Col, Badge, Tooltip } from 'reactstrap'
import { Match } from './match'
import { MatchPlayer } from './match-player'
import MaterialIcon from '@material/react-material-icon'
import { Rounds } from './rounds'

const GROUPS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']

export class RoundRobin extends React.Component {
  static propTypes = {
    players: PropTypes.number.isRequired,
    groups: PropTypes.number.isRequired,
    playersInGroup: PropTypes.number.isRequired,
    showEmpty: PropTypes.bool,
    data: PropTypes.object,
    className: PropTypes.string,
    tournamentId: PropTypes.string,
    isEditable: PropTypes.bool,
    tournament: PropTypes.object,
  }

  static defaultProps = {
    data: {total: [], games: []},
    className: '',
    showEmpty: false,
    isEditable: false,
    tournament: {},
  }

  state = {
    activeRow: [],
    tooltips: {},
  }

  get playersInGroup() {
    const {players, groups} = this.props
    let count = players
    if (groups === 0) {
      return 0
    }
    while (count % groups !== 0) {
      count++
    }

    return count / groups
  }

  toggleTooltip = (e) => {
    const tooltips = this.state.tooltips
    this.setState({tooltips:{...tooltips, [e.target.id]: !tooltips[e.target.id]}})
  }

  onMatchesToggleClick = (e) => {
    const {activeRow} = this.state
    const value = e.currentTarget.attributes.value.value
    const index = activeRow.indexOf(value)
    let result = [...activeRow]
    if (index === -1) {
      result = [...activeRow, value]
    } else {
      result.splice(index, 1)
    }
    this.setState({activeRow: result})
  }

  render() {
    const {groups, data, seeding, tournamentId, manualStartRounds, spectators, isEditable, roundsStarted, tournament} = this.props;
    if (seeding) return this.renderSeeding();
    let gamesByRound = [];
    let gamesByPlayer = {};
    if (data.games) {
      for (let group of data.games) {
        for (let i = 0; i < group.length; i++) {
          let round = group[i];
          if (!gamesByRound[i]) gamesByRound[i] = [];
          gamesByRound[i] = [...gamesByRound[i], ...round];
          for (let match of round) {
            for (let player of match.players) {
              if (player && player.id) {
                if (!gamesByPlayer[player.id]) gamesByPlayer[player.id] = [];
                gamesByPlayer[player.id].push(match)
              }
            }
          }
        }
      }
    }

    const total = [];
    for (let i = 0; i < groups; i++) {
      total.push(this.renderTotal(data.total ? (data.total[i] || []) : [], i, gamesByPlayer))
    }
    return (
      <Row>
        <Col lg={4} md={6}>
          <div className='tournament-bracket__sidebar'>
            <Rounds games={gamesByRound}
                    tournamentId={tournamentId}
                    manualStartRounds={tournament.manualStartRounds}
                    spectators={tournament.spectators}
                    isEditable={tournament.isEditable}
                    roundsStarted={tournament.roundsStarted}
                    roundsReadyToStart={tournament.roundsReadyToStart}/>
          </div>
        </Col>
        <Col lg={8} md={6}>
          <div className='tournament-bracket'>
            <h3>Rankings</h3>
            {total}
          </div>
        </Col>
      </Row>
    )
  }

  renderSeeding() {
    const {groups} = this.props;
    const seed = [];
    for (let i = 0; i < groups; i++) {
      seed.push(this.renderSeedingGroup(i))
    }
    return (
      <div className='tournament-bracket'>
        <div className='round-robin__seeding'>
          {seed}
        </div>
      </div>
    )
  }

  renderSeedingGroup(group) {
    const {seeding} = this.props;
    const players = []
    if (seeding[group]) {
      for (let i = 0; i < seeding[group].length; i++) {
        for (let j = 0; j < seeding[group][i].length; j++) {
          let match = seeding[group][i];
          players.push(this.renderSeedingPlayer(match, group, i, j))
        }
      }
    }
    if (players.length < this.playersInGroup) {
      players.push(this.renderSeedingPlayer({}, group, players.length, 0))
    }
    return (
      <div key={group} className='round-robin__seeding-group'>
        <h5 className='round-robin__group-name'>Group {GROUPS[group]}</h5>
        {players}
      </div>
    )
  }

  renderSeedingPlayer(match, group, index, playerIndex) {
    const {onPlayerClick, onPlayerClear} = this.props
    return (
      <MatchPlayer key={group + '-' + index} className='_round-robin'
                   data={{}}
                   seeding={match}
                   bracket={group}
                   index={index}
                   playerIndex={playerIndex}
                   onPlayerClick={onPlayerClick}
                   onPlayerClear={onPlayerClear}/>
    )
  }

  renderTotal(total, group, matches) {
    const tr = []
    const count = this.playersInGroup
    const {tooltips} = this.state
    for (let i = 0; i < count; i++) {
      if (!total[i] && !this.props.showEmpty) continue
      let m = total[i] && total[i].id && matches[total[i].id] ? matches[total[i].id] : []
      tr.push(this.renderTotalTr(total[i] || {}, i, m))
    }
    return (
      <div key={group} className='round-robin__group'>
        <h5 className='round-robin__group-name'>Group {GROUPS[group]}</h5>
        <Table className='round-robin__table' responsive={true}>
          <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th><div id={`win-${group}`}>W</div></th>
            <th><div id={`draw-${group}`}>D</div></th>
            <th><div id={`lose-${group}`}>L</div></th>
            <th><div id={`score-${group}`}>+/-</div></th>
            <th><div id={`coefficient-${group}`}>CB</div></th>
            <th><div id={`points-${group}`}>Pts</div></th>
            <th/>
          </tr>
          </thead>
          <tbody>
          {tr}
          </tbody>
        </Table>
        <Tooltip placement='top' target={`win-${group}`} delay={100}
                 isOpen={tooltips[`win-${group}`]}
                 toggle={this.toggleTooltip}>
          Wins
        </Tooltip>
        <Tooltip placement='top' target={`draw-${group}`} delay={100}
                 isOpen={tooltips[`draw-${group}`]}
                 toggle={this.toggleTooltip}>
          Draws
        </Tooltip>
        <Tooltip placement='top' target={`lose-${group}`} delay={100}
                 isOpen={tooltips[`lose-${group}`]}
                 toggle={this.toggleTooltip}>
          Losses
        </Tooltip>
        <Tooltip placement='top' target={`coefficient-${group}`} delay={100}
                 isOpen={tooltips[`coefficient-${group}`]}
                 toggle={this.toggleTooltip}>
          Sonneborn–Berger score<br/>
          It is computed by summing the conventional score of each defeated opponent,
          and half the conventional score of each drawn opponent.
        </Tooltip>
        <Tooltip placement='top' target={`score-${group}`} delay={100}
                 isOpen={tooltips[`score-${group}`]}
                 toggle={this.toggleTooltip}>
          Difference between wins and losses
        </Tooltip>
        <Tooltip placement='top' target={`points-${group}`} delay={100}
                 isOpen={tooltips[`points-${group}`]}
                 toggle={this.toggleTooltip}>
          Points
        </Tooltip>
      </div>
    )
  }

  renderTotalTr(item, i, matches) {
    const {activeRow} = this.state
    let win = 0
    let lose = 0
    let draw = 0
    if (item.history) {
      for (let i = 0; i < item.history.length; i++) {
        let hItem = item.history[i]
        if (!hItem.complete) continue
        if (hItem.result === 1) {
          win++
        } else if (hItem.result === 0) {
          draw++
        } else {
          lose++
        }
      }
    }

    const result = [
      <tr key={i} className={item.advancing ? 'round-robin__total-advancing' : ''}>
        <td className='round-robin__table-number'>{i + 1}</td>
        <td className='round-robin__table-player'>{item.name || ''}</td>
        <td className='round-robin__table-w'>{win}</td>
        <td className='round-robin__table-d'>{draw}</td>
        <td className='round-robin__table-l'>{lose}</td>
        <td className='round-robin__table-plus-minus'>{item.score || 0}</td>
        <td className='round-robin__table-tb'>{item.cb || 0}</td>
        <td className='round-robin__table-pts'>{item.points || 0}</td>
        <td className='round-robin__table-matches-toggle'>
          <div className='arrow-btn' value={item.id} onClick={this.onMatchesToggleClick}>
            <MaterialIcon icon='keyboard_arrow_down'/>
          </div>
        </td>
      </tr>,
    ]
    if (activeRow.includes(item.id)) {
      result.push(
        <tr key={i+'matches'}>
          <td colSpan={9} className='round-robin__table-matches'>{this.renderMatches(matches)}</td>
        </tr>,
      )
    }

    return result
  }

  renderMatches(matches) {
    const result = []
    for (let match of matches) {
      result.push(<Match key={match.id} data={match} className='_round-robin' tournamentId={this.props.tournamentId}/>)
    }
    return result
  }
}
