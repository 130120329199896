import React from 'react';

export default () => {
	return (
		<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.71114 4.43016L9.75449 5.15048L9.03194 5.06228C6.40184 4.72417 4.10411 3.57754 2.15321 1.65178L1.19944 0.696247L0.953773 1.40187C0.433533 2.97482 0.765908 4.63597 1.84974 5.7532C2.42779 6.37062 2.29773 6.45882 1.3006 6.09131C0.953773 5.97371 0.6503 5.88551 0.621397 5.92961C0.52024 6.03251 0.867066 7.37025 1.14164 7.89947C1.51737 8.63449 2.28327 9.35481 3.12144 9.78113L3.82954 10.1192L2.99138 10.1339C2.18212 10.1339 2.15321 10.1486 2.23992 10.4573C2.52894 11.4129 3.67058 12.4272 4.94228 12.8682L5.83825 13.1769L5.05789 13.6473C3.9018 14.3236 2.54339 14.7058 1.18499 14.7352C0.534691 14.7499 0 14.8087 0 14.8528C0 14.9998 1.76303 15.823 2.78906 16.1464C5.86715 17.102 9.52328 16.6903 12.269 15.0586C14.2199 13.8973 16.1708 11.5893 17.0812 9.35481C17.5725 8.16408 18.0639 5.98841 18.0639 4.94468C18.0639 4.26846 18.1072 4.18025 18.9165 3.37173C19.3934 2.90131 19.8414 2.3868 19.9281 2.23979C20.0726 1.96049 20.0581 1.96049 19.3211 2.21039C18.0928 2.65141 17.9194 2.59261 18.5263 1.93108C18.9743 1.46067 19.509 0.608044 19.509 0.358136C19.509 0.314035 19.2922 0.387537 19.0466 0.519841C18.7864 0.666846 18.2084 0.887353 17.7749 1.01966L16.9945 1.26956L16.2864 0.784449C15.8962 0.519841 15.3471 0.225832 15.058 0.137629C14.321 -0.068177 13.1939 -0.0387761 12.5291 0.196431C10.7227 0.857952 9.58108 2.5632 9.71114 4.43016Z" fill="#C4C4C4"/>
		</svg>
	)
}





