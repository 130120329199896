import { parse, stringify } from 'query-string'
import { history } from '../store/history'

export const query = {
  state: {
    location: {},
    params: {},
  },
  reducers: {
    change: (state, payload) => ({
      ...state,
      location: payload.location,
      params: parse(payload.location.search, {arrayFormat: 'bracket', parseNumbers: true}),
    }),
  },
  effects: {
    set({params, url = ''}, rootState) {
      let uri = (url || rootState.query.location.pathname) + '?' +
        stringify({...rootState.query.params, ...params}, {arrayFormat: 'bracket'})

      history.push(uri)
    },
  },
}
