import React from 'react'
import { TournamentSearch } from '../../tournament-search'
import { Row } from 'reactstrap'
import { connect } from 'react-redux'
import { TournamentCard } from '../../tournament-card'

const mapState = ({organization, tournamentsList}) => ({
  tournamentsList,
})
const mapDispatch = ({organization, tournamentsList}) => ({
  tournamentsLoad: tournamentsList.load,
})

class CTournaments extends React.PureComponent {
  constructor(props) {
    super(props)
    props.tournamentsLoad({organization: props.match.params.id})
  }

  render() {
    const {tournamentsList} = this.props;
    return (
      <div>
        {/*<TournamentSearch type={true} time={true} registration={true}/>*/}
        <Row>
          {tournamentsList.data.map(t => <TournamentCard key={t.id} data={t}/>)}
        </Row>
      </div>
    )
  }
}


export const Tournaments = connect(mapState, mapDispatch)(CTournaments)
