import { ProgressState } from '../constants/progress-state'
import { get } from '../utils/fetch-api'

export const pickAndBan = {
  state: {
    state: ProgressState.NotStarted,
    stateLoad: ProgressState.NotStarted,
    error: 0,
    data: {}
  },
  reducers: {
    start: (state) => ({...state, error: 0, state: ProgressState.InProgress, stateLoad: ProgressState.InProgress}),
    startLoad: (state) => ({...state, stateLoad: ProgressState.InProgress}),
    error: (state, error) => ({...state, error, state: ProgressState.Complete, stateLoad: ProgressState.Complete}),
    success: (state, data) => ({...state, data, error: 0, state: ProgressState.Complete, stateLoad: ProgressState.Complete}),
  },
  effects: dispatch => ({
    async get(matchId) {
      this.startLoad()
      const response = await get(`/api/match/${matchId}/ban/get`)
      if (response.ok) {
        this.success(response.body)
      } else {
        this.error(response.status)
      }
    },

    async ban({matchId, deckId}) {
      this.start()
      const response = await get(`/api/match/${matchId}/ban/edit/${deckId}`)
      if (response.ok) {
        this.success(response.body)
      } else {
        this.error(response.status)
      }
    }
  }),
}
