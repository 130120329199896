import React from 'react'
import memoize from 'lodash/memoize'

const getStyle = memoize(img => ({backgroundImage: `url(https://cdn-l-playgwent.cdprojektred.com/${img})`}))

export const Avatar = ({className = '', avatar = '', border = '', ...props}) => {
  if (avatar) {
    if (props.style) {
      props.state.backgroundImage = `url(https://cdn-l-playgwent.cdprojektred.com/avatars/${avatar}.jpg`
    } else {
      props.style = getStyle(`avatars/${avatar}.jpg`)
    }
  } else {
    className += ' _empty'
  }
  return (
    <div className={'avatar ' + className} {...props}>
      {border && avatar && (<div className='avatar__border' style={getStyle(`borders/${border}.png`)}/>)}
      {!avatar && (<div className='avatar__border _empty'/>)}
    </div>
  )
}
