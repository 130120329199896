import { ProgressState } from '../constants/progress-state'
import { get, post } from '../utils/fetch-api'

export const tMatch = {
  state: {
    current: {
      state: ProgressState.NotStarted,
      error: 0,
      data: {},
    },
    gameEdit: {
      state: ProgressState.NotStarted,
      error: 0
    },
  },
  reducers: {
    startLoad: (state) => ({...state, current: {data: {}, error: 0, state: ProgressState.InProgress}}),
    errorLoad: (state, error) => ({...state, current: {data: state.current.data, error, state: ProgressState.Complete}}),
    successLoad: (state, data) => ({...state, current: {data, error: 0, state: ProgressState.Complete}}),
    reset: (state) => ({...state, current: {data:{}, error: 0, state: ProgressState.NotStarted}}),

    startGameEdit: (state) => ({...state, gameEdit: {error: 0, state: ProgressState.InProgress}}),
    errorGameEdit: (state, error) => ({...state, gameEdit: { error, state: ProgressState.Complete}}),
  },
  effects: dispatch => ({
    async load({id, matchId, reload = false}, rootState) {
      if (!reload) {
        this.startLoad()
      }
      let url = `/api/tournament/${id}/match/${matchId}`
      if (!rootState.user.identified()) url += '?anonymous=1'
      const response = await get(url)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async gameEdit({tournamentId, matchId, game}) {
      this.startGameEdit()
      const response = await post(`/api/tournament/${tournamentId}/match/${matchId}/game-edit`, game)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorGameEdit(response.status)
      }
    },

    async reportProblem({tournamentId, matchId, message}) {
      const response = await post(`/api/tournament/${tournamentId}/complaint/add/${matchId}`, {message})
      if (response.ok) {
        // this.successLoad(response.body)
      } else {
        // this.errorLoad(response.status)
      }
    },

    async setPlayerGoingFirst({tournamentId, matchId, playerId}) {
      const response = await get(`/api/tournament/${tournamentId}/match/${matchId}/player-going-first/${playerId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorGameEdit(response.status)
      }
    },
  }),
}
