import React from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { Badge, Col, Input, Row, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { tournamentMatch } from '../../../routes'
import { MatchState } from '../../../../constants/match'
import { ProblemModal } from './ProblemModal'
import { LOGS_RELOAD_INTERVAL } from '../../../../constants/timers'

const tz = moment.tz.guess()

const mapStateToProps = ({tournament, user}) => ({
  tournament: tournament.id,
  logs: tournament.logs,
  user,
})
const mapDispatchToProps = ({tournament}) => ({
  loadLogs: tournament.loadLogs,
  problemResolved: tournament.problemResolved,
  unlockMatch: tournament.unlockMatch
})

class CTournamentPageLogs extends React.PureComponent {
  state = {
    type: -1,
    search: '',
    currentProblem: null,
    limit: 0
  }

  constructor(props) {
    super(props)
    props.loadLogs({id: props.match.params.id})
    this.refreshTimer = setInterval(this.refreshTimerTick, LOGS_RELOAD_INTERVAL)
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer)
  }

  refreshTimerTick = () => {
    this.props.loadLogs({id: this.props.match.params.id})
  }

  onTypeFilterClick = (e) => {
    this.setState({[e.target.attributes.name.value]: Number(e.target.attributes.value.value)})
  }

  onSearchChange = (e) => {
    const value = e.target.value
    this.setState({search: value})
  }

  onProblemClick = (e) => {
    const {data:{list = [], count = 0}} = this.props.logs
    const id = Number(e.target.dataset.logId)
    const log = list.find(l => l.id === id)
    if (log && log.complaint) {
      this.setState({currentProblem: log.complaint})
    }
  }

  onProblemClose = () => {
    this.setState({currentProblem: null})
  }

  onProblemResolved = (e) => {
    this.props.problemResolved({tournamentId: this.props.match.params.id, complaintId: e.target.dataset.complaintId})
  }

  render() {
    const {data:{list = [], count = 0}} = this.props.logs
    const {type, search} = this.state
    const admins = list.filter(l => l.type === 0)
    const players = list.filter(l => l.type === 1)
    const api = list.filter(l => l.type === 2)
    let result = list
    if (type !== -1) {
      if (type === 0) result = admins
      else if (type === 1) result = players
      else if (type === 2) result = api
    }
    if (search && search.length > 2) {
      const lowSearch = search.toLowerCase().trim()
      result = result.filter(log => {
        if (log.message.includes(lowSearch)) return true
        if (log.author && log.author.name.toLowerCase().includes(lowSearch)) return true
        if (log.user && log.user.name.toLowerCase().includes(lowSearch)) return true
      })
    }

    return (
      <div className='container'>
        <div className='tournament-logs'>
          <ProblemModal problem={this.state.currentProblem} onClose={this.onProblemClose}/>
          <Row className='tournament-logs__filter'>
            <Col md={4}>
              <h3>Actions</h3>
              <Badge color={type === -1 ? 'primary' : 'secondary'} pill
                     className='badge-filter'
                     onClick={this.onTypeFilterClick}
                     value={-1}
                     name='type'>
                All {list.length}
              </Badge>
              <Badge color={type === 0 ? 'primary' : 'secondary'} pill
                     className='badge-filter'
                     onClick={this.onTypeFilterClick}
                     value={0}
                     name='type'>
                Admins {admins.length}
              </Badge>
              <Badge color={type === 1 ? 'primary' : 'secondary'} pill
                     className='badge-filter'
                     onClick={this.onTypeFilterClick}
                     value={1}
                     name='type'>
                Players {players.length}
              </Badge>
              <Badge color={type === 2 ? 'primary' : 'secondary'} pill
                     className='badge-filter'
                     onClick={this.onTypeFilterClick}
                     value={2}
                     name='type'>
                API {api.length}
              </Badge>
            </Col>
            <Col md={4} className='ml-auto'>
              <label htmlFor='log-search'>Search by username or action</label>
              <Input type='text' id='log-search' placeholder='Username or action'
                     value={search}
                     onChange={this.onSearchChange}/>
            </Col>
          </Row>
          <div className='tournament-logs__hr'/>
          {result.map(log => this.renderLog(log))}
        </div>
      </div>
    )
  }

  renderLog(log) {
    let date = moment(log.dateCreate).tz(tz)
    return (
      <div className='tournament-logs__item' key={log.id} onClick={(e) => this.handleLogClick(e, log)}>
        <div className='tournament-logs__time'>{date.fromNow()}, {date.format('D MMM, HH:mm')}</div>
        <div className='tournament-logs__message'>{this.compile(log)}</div>
      </div>
    )
  }

  compile(log) {
    const elements = log.message.split('%')
    const result = []
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i]
      if (!element) continue
      if (element === 'AUTHOR') {
        result.push(<span key={i} className='tournament-logs__highlight'>{log.author.name}</span>)
        continue
      }
      if (element === 'USER') {
        result.push(<span key={i} className='tournament-logs__highlight'>{log.user.name}</span>)
        continue
      }
      if (element === 'MATCH') {
        result.push(this.compileMatch(log, i))
        continue
      }
      if (/GAME_RESULT=[-\d+]/.test(element)) {
        result.push(<span key={i}>{this.compileGameResult(log, element)}</span>)
        continue
      }
      if (/MATCH_STATE=[-\d+]/.test(element)) {
        result.push(<span key={i}>{this.compileMatchState(log, element)}</span>)
        continue
      }
      if (element === 'ERROR_MSG') {
        result.push(<pre key={i}>{log.errorMsg}</pre>)
        continue
      }
      if (element === 'PROBLEM') {
        result.push(this.compileProblem(log, i))
        continue
      }
      result.push(<span key={i} dangerouslySetInnerHTML={{__html: element}}/>)
    }

    return result
  }

  compileMatch(log, i) {
    if (!log.match) return 'match'
    const p1 = log.match.players[0].name || '-'
    const p2 = log.match.players[1].name || '-'
    return (
      <span key={i}>
        <Link to={tournamentMatch(this.props.match.params.id, log.match.id)}>match</Link> ({p1} vs {p2})
      </span>
    )
  }

  compileProblem(log, i) {
    if (!log.complaint) return <span key={i}>problem</span>
    let date = moment(log.complaint.dateResolve).tz(tz)
    return (
      <span key={i} className='tournament-logs__problem'>
        <span className='tournament-logs__highlight _click' data-log-id={log.id} onClick={this.onProblemClick}>
          problem
        </span>
        {!log.complaint.checked && (
          <Button color='primary' data-complaint-id={log.complaint.id} onClick={this.onProblemResolved}>
            mark as resolved
          </Button>
        )}
        {!!log.complaint.checked && (
          <span className='tournament-logs__problem-resolved'>Resolved by {log.complaint.moderator.name} {date.fromNow()}</span>
        )}
      </span>
    )
  }

  compileGameResult(log, result) {
    if (!log.match) return '?'
    const p1 = log.match.players[0].name || '-'
    const p2 = log.match.players[1].name || '-'
    if (result === 'GAME_RESULT=0') return 'draw'
    return result === 'GAME_RESULT=1' ? `${p1} win` : `${p2} win`
  }

  compileMatchState(log, result) {
    if (!log.match) return '?'
    if (result === `MATCH_STATE=${MatchState.STATE_PICK_AND_BAN}`) return 'pick and ban start'
    if (result === `MATCH_STATE=${MatchState.STATE_PICK_AND_BAN_FINISH}`) return 'pick and ban finish'
    if (result === `MATCH_STATE=${MatchState.STATE_CREATED}`) return 'created, waiting results'
    if (result === `MATCH_STATE=${MatchState.STATE_FINISHED}`) return 'finished'
    return ''
  }

  handleLogClick(e, log) {
    if (e.target.classList.contains('unlock')) {
      e.stopPropagation();
      e.preventDefault();
      this.props.unlockMatch({tournamentId: log.tournamentId, logId: log.id})
      setTimeout(() => this.refreshTimerTick(), 500)
    }
  }
}

export const TournamentPageLogs = connect(mapStateToProps, mapDispatchToProps)(CTournamentPageLogs)
