import React from 'react';
import styled from "styled-components";

const Title = (props) => {
	const { text, children, align, size, lineHeight } = props;
	return (
		<TitleStyle align={align} size={size} lineHeight={lineHeight}>{ text || children }</TitleStyle>
	)
};

export default Title;

const TitleStyle = styled.div`
    font-family: "GWENT", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";;
    font-size: ${ props => props.size ? props.size : 24}px;
    text-transform: uppercase;
    color: #d7be5e;
    text-align: ${ props => props.align };
    line-height: ${ props => props.lineHeight}px;
`;
