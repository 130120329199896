import React from 'react'

export class Switch extends React.PureComponent {

  onChange = () => {
    const {value, onChange, ...props} = this.props
    if (!onChange) return
    this.props.onChange({
      target: {
        type: 'Switch',
        value: !value,
        name: this.props.name || '',
        id: this.props.id || '',
        ...props
      },
    })
  }

  render() {
    const {value, onChange, ...props} = this.props
    return (
      <div className={`switch ${value ? '_active' : ''}`} onClick={this.onChange} {...props}/>
    )
  }
}
