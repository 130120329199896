import React from 'react'

export const OrgAvatar = ({className = '', src, ...props}) => {
  if (src) {
    return <img src={src} alt='org' className={className} {...props}/>
  } else {
    return (
      <div className={'avatar _empty ' + className} {...props}>
        <div className='avatar__border _empty'/>
      </div>
    )
  }
}
