import React from 'react'
import { connect } from 'react-redux'
import { TournamentPageBracketsPreview } from './preview'
import { TournamentPageBracketsActive } from './active'
import { TournamentState, Bracket } from '../../../../constants/tournament'
import { Spectators } from './spectators'
import Modal from "../../../atoms/modal";
import {ModalButtonWrapper, ModalText} from "../../../atoms/modal/styles";
import {Button} from "reactstrap";
import {getTournamentId} from "../../../../utils/tournament";

const mapStateToProps = ({tournament}) => ({
  tournament,
})
const mapDispatchToProps = ({tournament}) => ({
  load: tournament.loadGames,
  switchSeeding: tournament.switchSeeding,
  loadParticipants: tournament.loadParticipants,
  setSeeding: tournament.setSeeding,
  resetSeeding: tournament.resetSeeding,
  resetMatch: tournament.resetMatch,
})

class CTournamentPageBrackets extends React.PureComponent {
  state = {
    full: false,
  }

  load = () => this.props.load(this.props.match.params.id)
  switchSeeding = () => this.props.switchSeeding(this.props.match.params.id)
  loadParticipants = () => this.props.loadParticipants(this.props.match.params.id)
  toggleFullScreen = () => this.setState({full: !this.state.full})

  onMatchResetClick = () => {
    let tournamentId = getTournamentId(this.props.tournament.data)
    Promise.resolve(
        this.props.resetMatch({tournamentId: tournamentId, matchId: this.state.matchReset})
    ).then(() => {
      this.props.load(tournamentId)
      this.setState({matchReset: null})
      this.onCloseModalMatchReset()
    });
  }

  onShowModalMatchReset = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({showModalMatchReset: true})
    this.setState({matchReset: e.target.dataset.matchId})
  }

  onCloseModalMatchReset = () => {
    this.setState({showModalMatchReset: false})
    this.setState({matchReset: null})
  }

  render() {
    const {tournament, setSeeding, resetSeeding} = this.props
    if (!tournament) return null
    return (
      <div className={`tournament-bracket__container ${this.state.full ? '' : 'container'}`}>
        {this.renderHeader()}
        {tournament.data.isEditable &&
        (tournament.data.state === TournamentState.Publish || tournament.data.state === TournamentState.Draft) ?
          <TournamentPageBracketsPreview tournament={tournament}
                                         onSwitchSeeding={this.switchSeeding}
                                         loadParticipants={this.loadParticipants}
                                         setSeeding={setSeeding}
                                         resetSeeding={resetSeeding}/> :
          <TournamentPageBracketsActive tournament={tournament} load={this.load} resetMatchHandler={this.onShowModalMatchReset}/>
        }
        {this.renderModalMatchReset()}
      </div>
    )
  }

  renderHeader() {
    const {tournament} = this.props
    if ([Bracket.Single, Bracket.Double].indexOf(tournament.data.bracketType) === -1) return null
    return (
      <div className={`tournament-bracket__full-screen ${this.state.full ? '_active' : ''}`}
           onClick={this.toggleFullScreen}/>
    )
  }

  renderModalMatchReset() {
    return (
        <Modal open={this.state.showModalMatchReset} title={`Reset match`} fixed={true}>
          <ModalText>Are you sure?</ModalText>
          <ModalButtonWrapper>
            <Button color='primary' onClick={this.onCloseModalMatchReset}>no, i changed my mind</Button>
            <Button color='light' data-force='1' onClick={this.onMatchResetClick}>yes, reset match</Button>
          </ModalButtonWrapper>
        </Modal>
    )
  }
}

export const TournamentPageBrackets = connect(mapStateToProps, mapDispatchToProps)(CTournamentPageBrackets)
