import { ProgressState } from '../constants/progress-state'
import {del, get, post} from '../utils/fetch-api'
import {MY_TOURNAMENTS_ORGANIZING} from '../components/routes'
import {getTournamentId} from "../utils/tournament";

function state(data = {}, state = ProgressState.NotStarted, error = 0, other = {}) {
  return {
    data,
    state,
    error,
    errorMessage: null,
    ...other,
  }
}

const dState = {
  ...state({}),
  participants: state([]),
  games: state({}),
  logs: state({}),
  edit: {
    state: ProgressState.NotStarted,
    error: 0,
    errorMessage: null,
  },
  seeding: [[]],
  match: state({}),
}

export const tournament = {
  state: dState,
  reducers: {
    resetAll(state) {
      return {...state, ...dState}
    },
    startLoad(state) {
      return {...state, state: ProgressState.InProgress, error: 0}
    },
    errorLoad(state, error) {
      return {...state, data: state.data, state: ProgressState.Complete, error}
    },
    errorLoadM(state, {error, errorMessage}) {
      return {...state, data: state.data, state: ProgressState.Complete, error, errorMessage}
    },
    resetErrorLoad(state) {
      return {...state, data: state.data, state: ProgressState.Complete, error: 0, errorMessage: null}
    },
    successLoad(state, data) {
      return {...state, games: state.games, data, state: ProgressState.Complete, error: 0}
    },
    reset(state) {
      return {...state, state: ProgressState.NotStarted, error: 0}
    },

    startParticipants(state) {
      return {
        ...state,
        participants: {data: [], state: ProgressState.InProgress, error: 0},
      }
    },
    errorParticipants(state, error) {
      return {
        ...state,
        participants: {data: [], state: ProgressState.Complete, error: error},
      }
    },
    successParticipants(state, participants) {
      return {
        ...state,
        participants: {data: participants, state: ProgressState.Complete, error: 0},
      }
    },

    startGames: (state) => ({...state, games: {...state.games, state: ProgressState.InProgress, error: 0}}),
    errorGames: (state, error) => ({...state, games: {data: {}, state: ProgressState.Complete, error: error}}),
    successGames: (state, payload) => ({...state, games: {data: payload, state: ProgressState.Complete, error: 0}}),

    startLogs: (state) => ({...state, logs: {...state.logs, state: ProgressState.InProgress, error: 0}}),
    errorLogs: (state, error) => ({...state, logs: {data: {}, state: ProgressState.Complete, error: error}}),
    successLogs: (state, payload) => ({...state, logs: {data: payload, state: ProgressState.Complete, error: 0}}),

    startEdit(state) {
      return {
        ...state,
        edit: {state: ProgressState.InProgress, error: 0},
      }
    },
    errorEdit(state, error) {
      return {
        ...state,
        edit: {state: ProgressState.Complete, error: error},
      }
    },
    successEdit(state, data) {
      return {
        ...state,
        data,
        edit: {state: ProgressState.Complete, error: 0},
      }
    },
    resetEdit(state) {
      return {
        ...state,
        edit: {state: ProgressState.NotStarted, error: 0},
      }
    },
    setSeeding(state, seeding) {
      return {
        ...state,
        seeding,
      }
    },
    resetSeeding(state) {
      return {
        ...state,
        seeding: [[]],
      }
    },
    startMatch(state) {
      return {
        ...state,
        match: {data: {}, state: ProgressState.InProgress, error: 0},
      }
    },
    errorMatch(state, error) {
      return {
        ...state,
        match: {data: {}, state: ProgressState.Complete, error: error},
      }
    },
    successMatch(state, data) {
      return {
        ...state,
        match: {data, state: ProgressState.Complete, error: 0},
      }
    },
    resetMatch(state) {
      return {
        ...state,
        match: {data: {}, state: ProgressState.NotStarted, error: 0},
      }
    },

  },
  effects: {
    async load(id, rootState) {
      this.startLoad()
      const response = await get('/api/tournament/' + id)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },
    async reLoad(id, rootState) {
      let url = `/api/tournament/${id}`
      if (!rootState.user.identified()) url += '?anonymous=1'
      const response = await get(url)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },
    async loadParticipants(id) {
      this.startParticipants()
      const response = await get(`/api/tournament/${id}/participants`)
      if (response.ok) {
        this.successParticipants(response.body)
      } else {
        this.errorParticipants(response.status)
      }
    },
    async loadGames(id, rootState) {
      this.startGames()
      let url = `/api/tournament/${id}/games`
      if (!rootState.user.identified()) url += '?anonymous=1'
      const response = await get(url)
      if (response.ok) {
        this.successGames(response.body)
      } else {
        this.errorGames(response.status)
      }
    },
    async loadLogs({id, limit = 200, offset = 0}) {
      this.startLogs()
      let url = `/api/tournament/${id}/logs`
      if (limit) {
        url += `?limit=${limit}&offset=${offset}`
      }
      const response = await get(url)
      if (response.ok) {
        this.successLogs(response.body)
      } else {
        this.errorLogs(response.status)
      }
    },

    async edit(payload) {
      this.startEdit()
      let id = getTournamentId(payload);
      let url = id ? `/api/tournament/${id}/edit` : '/api/tournament/new'
      const response = await post(url, payload)
      if (response.ok) {
        this.successEdit(response.body)
      } else {
        this.errorEdit(response.status)
      }
    },

    async remove(id) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/remove`)
      if (response.ok) {
        document.location.href = MY_TOURNAMENTS_ORGANIZING
      } else {
        this.errorEdit(response.status)
      }
    },

    async draft(id) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/draft`)
      if (response.ok) {
        document.location.reload()
      } else {
        this.errorEdit(response.status)
      }
    },

    async publish(id) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/publish`)
      if (response.ok) {
        this.successEdit(response.body)
      } else {
        this.errorEdit(response.status)
      }
    },

    async start({id, force}, rootState) {
      this.startEdit()
      let uri = `/api/tournament/${id}/start`
      if (force) {
        uri += '?force=1'
      }
      const response = await post(uri, rootState.tournament.seeding)
      this.resetSeeding()
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        console.log({error: response.status, errorMessage: response.body})
        this.errorLoadM({error: response.status, errorMessage: response.body})
      }
    },

    async addPlayers(id) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/add-players`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async addPlayer({id, playerId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/add-player/${playerId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.body.error || response.status)
      }
    },

    async removePlayer({id, playerId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/remove-player/${playerId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async changeStatus({id, playerId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/change-status/${playerId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async addBlocked({id, playerId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/add-black/${playerId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async removeBlocked({id, playerId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/remove-black/${playerId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async addSpectator({id, userId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/add-spectator/${userId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async removeSpectator({id, userId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/remove-spectator/${userId}`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },



    async addMatchSpectator({tournamentId, matchId, userId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${tournamentId}/match/${matchId}/add-spectator/${userId}`)
      if (response.ok) {
        this.successGames(response.body)
      } else {
        this.errorGames(response.status)
      }
    },

    async removeMatchSpectator({tournamentId, matchId, userId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${tournamentId}/match/${matchId}/remove-spectator/${userId}`)
      if (response.ok) {
        this.successGames(response.body)
      } else {
        this.errorGames(response.status)
      }
    },

    async startRound({tournamentId, round, bracket}) {
      this.startEdit()
      let url = `/api/tournament/${tournamentId}/start-round/${round}`
      if (bracket) url += `/${bracket}`
      const response = await get(url)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.successLoad(response.status)
      }
    },

    async switchSeeding(id) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/switch-seeding`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async join(id) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/join`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async leave(id) {
      this.startEdit()
      const response = await get(`/api/tournament/${id}/leave`)
      if (response.ok) {
        document.location.reload()
      } else {
        this.errorLoad(response.status)
      }
    },

    async deckApproved({tournamentId, userId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${tournamentId}/deck/${userId}/approved`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async checkIn({tournamentId, userId}) {
      this.startEdit()
      let uri = `/api/tournament/${tournamentId}/check-in`
      if (userId) {
        uri += `/${userId}`
      }
      const response = await get(uri)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async problemResolved({tournamentId, complaintId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${tournamentId}/complaint/${complaintId}/check`)
      if (response.ok) {
        this.successLogs(response.body)
      } else {
        this.errorLogs(response.status)
      }
    },

    async resetMatch({tournamentId, matchId}) {
      this.startEdit()
      const response = await get(`/api/tournament/${tournamentId}/match/${matchId}/reset`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    },

    async unlockMatch({tournamentId, logId}) {
      this.startEdit()
      console.log(tournamentId, logId)
      const response = await del(`/api/tournament/${tournamentId}/match/${logId}/unlock`)
      if (response.ok) {
        this.successLoad(response.body)
      } else {
        this.errorLoad(response.status)
      }
    }

  },
}
